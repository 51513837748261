<template>
  <div class="page-wrap">
    <supplier-detail-header :supplier="supplier"></supplier-detail-header>

    <div class="main-page-column py-0">
      <div class="scroll-container pt-0">
        <router-view
          :supplier="supplier"
          :current-user="currentUser"
          :payment-terms="paymentTerms"
          :currencies="currencies"
          :auth-level="authLevel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SupplierDetailHeader from '@/modules/finance/components/core/SupplierDetailHeader.vue'
import SUPPLIER from '@/graphql/Supplier.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'SupplierDetailLayout',
  components: {
    SupplierDetailHeader
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('suppliers')
    },
    currentRoute() {
      return this.$route
    }
  },
  apollo: {
    supplier: {
      query: SUPPLIER,
      variables() {
        return {
          id: this.$route.params.supplierID
        }
      }
    },
    paymentTerms: PAYMENT_TERM_LIST,
    currencies: { query: CURRENCY_LIST, fetchPolicy: 'network-only' }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 112px;
}
</style>
