<template>
  <div class="page-header">
    <v-toolbar v-if="supplier" class="mb-0 b-radius" extended flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="pl-0">
            <v-row>
              <v-col cols="8" lg="9" xl="10">
                <h2 class="mb-0 text-truncate primary--text">
                  {{ supplier.name }}
                </h2>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:extension>
        <v-tabs
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          background-color="transparent"
          align-with-title
          center-active
          show-arrows
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            :to="{
              name: 'SupplierDetail',
              params: { supplierID: $route.params.supplierID }
            }"
            class="text-capitalize"
            exact
            >Details</v-tab
          >

          <v-tab
            class="text-capitalize"
            :to="{
              name: 'SupplierPurchaseOrders',
              params: { supplierID: $route.params.supplierID }
            }"
            >Purchase Orders</v-tab
          >

          <!-- <v-tab
            :to="{
              name: 'SupplierSettings',
              params: { supplierID: $route.params.supplierID }
            }"
            class="text-capitalize"
            >Settings</v-tab
          > -->
        </v-tabs>

        <slot name="controls"> </slot>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'SupplierDetailHeader',
  props: {
    supplier: { type: Object, default: () => {} }
  },
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style></style>
