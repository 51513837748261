<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col cols="12">
              <v-alert text color="info" border="left">
                Select any availble reports below. Please contact your
                administrator if you feel you are missing a report.
              </v-alert>
            </v-col>
            <v-col cols="12" sm="6">
              <v-expansion-panels v-model="panelLeft" flat multiple>
                <v-expansion-panel class="mb-1">
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Inventory Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none text-truncate"
                          :to="{ name: 'FinanceInboundStockReport' }"
                          >Inbound Stock Report</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="9"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of inbound stock in a given period
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- <v-expansion-panel class="mb-1" v-if="currentUser && currentUser.isSuperAdmin">
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Budget Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none text-truncate"
                          :to="{ name: 'FinanceReportBudgetList' }"
                          >Budget List</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="9"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of budgets by financial year
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel> -->

                <!-- <v-expansion-panel class="mb-1" v-if="currentUser && currentUser.isSuperAdmin">
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Project Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none text-truncate"
                          :to="{ name: 'FinanceReportProjectList' }"
                          >Project List</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="9"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of projects by financial year
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel> -->
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" sm="6">
             <v-expansion-panels v-model="panelRight" flat multiple>
              <v-expansion-panel class="mb-1" v-if="currentUser && MoReportAuthLevel">
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Manufacturing Order Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none text-truncate"
                          :to="{ name: 'FinanceReportManufacturingOrderPaymentList' }"
                          >Payment List</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="9"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of upcomming payments
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!--  <v-expansion-panel class="mb-1">
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Purchase Order Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none text-truncate"
                          :to="{ name: 'ProductionReportStyleList' }"
                          >Purchase Order List</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="9"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of purchase orders by financial year
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="mb-1">
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Supplier Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none  text-truncate"
                          :to="{ name: 'ProductionReportStyleList' }"
                          >Supplier List</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="9"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of suppliers
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel> -->
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinanceReportList',
  components: {},
  data: () => ({ panelLeft: [0, 1, 2], panelRight: [0, 1] }),
  computed: {
    userScopes() {
      return this.$store.getters.userScopes
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    MoReportAuthLevel() {
      return this.$store.getters.authLevel('finance_mo_reports')
    },
    budgetReportAuthLevel() {
      return this.$store.getters.authLevel('budget_reports')
    },
    projectReportAuthLevel() {
      return this.$store.getters.authLevel('project_reports')
    },
    supplierReportAuthLevel() {
      return this.$store.getters.authLevel('supplier_reports')
    },
    purchaseOrderReportAuthLevel() {
      return this.$store.getters.authLevel('purchase_order_reports')
    }
  },
  apollo: {},
  methods: {}
}
</script>

<style></style>
