<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
    transition="dialog-bottom-transition"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <div v-if="item" class="scroll-container pt-0">
          <v-form
            ref="budgetForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12" class="pb-0 px-0">
                <v-row>
                  <v-col v-if="errorMsg" cols="12" class="py-0">
                    <v-alert type="error" text border="left">
                      {{ errorMsg }}
                    </v-alert>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Financial Year</label>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-select
                          v-model="item.financialYear"
                          :items="financialYears"
                          :rules="requiredDepartmentRule"
                          outlined
                          hide-details="auto"
                          autocomplete
                          return-object
                          item-text="name"
                          :disabled="$apollo.loading || editMode"
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Department</label>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-select
                          v-model="item.department"
                          :items="departments"
                          :rules="requiredDepartmentRule"
                          outlined
                          autocomplete
                          hide-details="auto"
                          return-object
                          item-text="name"
                          :disabled="
                            $apollo.loading ||
                              (currentUser &&
                                currentUser.userRole !== 'director' &&
                                !currentUser.isSuperAdmin) ||
                              editMode
                          "
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Currency</label>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-select
                          v-model="item.currency"
                          :items="currencies"
                          :rules="requiredSelectRule"
                          outlined
                          hide-details="auto"
                          autocomplete
                          return-object
                          item-text="name"
                          :disabled="editMode"
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="pb-0">
                    <label>Description</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <vue-editor
                      id="edit-budget-description"
                      v-model="item.description"
                      :editor-options="editorConfig"
                      placeholder="A short description, which describes what the budget is for."
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          rounded
          :disabled="processing"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn color="primary" rounded :loading="processing" @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DEPARTMENT_LIST from '@/graphql/Departments.gql'
import FINANCIAL_YEAR_LIST from '@/graphql/FinancialYears.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import BUDGETS from '@/graphql/Budgets.gql'
import CREATE_BUDGET from '@/graphql/BudgetCreate.gql'
import UPDATE_BUDGET from '@/graphql/BudgetUpdate.gql'

export default {
  name: 'BudgetEdit',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    errorMsg: '',
    processing: false,
    disabled: false,
    money: {
      decimal: ',',
      thousands: '.',
      // prefix: '€ ',
      precision: 2,
      masked: false /* doesn't work with directive */
    },

    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    startDate: false,
    endDate: false,
    requiredDepartmentRule: [
      v => !!v.id || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [
      v => !!v.id || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    dateRule: [v => !!v || 'Date is required']
  }),
  apollo: {
    departments: DEPARTMENT_LIST,
    financialYears: FINANCIAL_YEAR_LIST,
    currencies: CURRENCY_LIST
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Budget' : 'New Budget'
    }
    // defaultCurrency () {
    //   return this._.find(this.currencies, function (item) {
    //     return (item.defaultCurrency = true)
    //   })
    // }
  },
  watch: {
    dialog(val) {
      this.errorMsg = ''
      if (this.$refs.budgetForm && val) {
        this.$refs.budgetForm.resetValidation()
      }
    }
  },
  methods: {
    close(success, id) {
      this.errorMsg = ''
      this.processing = false
      this.$emit('close-edit-budget-dialog', success, id)
    },
    save() {
      this.errorMsg = ''
      // Return the price to an int

      if (this.editMode) {
        if (this.$refs.budgetForm.validate()) {
          this.processing = true
          // Make a copy to make sure the new object is not the observable object
          let item = this._.cloneDeep(this.item)

          // Format the money props
          delete item.currency.__typename
          delete item.currency.updatedAt
          delete item.currency.createdAt

          let data = {
            id: item.id,
            title: item.title,
            description: item.description,
            departmentID: item.department.id,
            financialYearID: item.financialYear.id,
            currencyID: item.currency.id
          }

          this.$apollo
            .mutate({
              mutation: UPDATE_BUDGET,
              variables: {
                input: data
              }
            })
            .then(result => {
              this.close(true, result.data.updateBudget.id)
            })
            .catch(() => {
              this.errorMsg =
                'A general server error occured. Please try again later'
              this.processing = false
            })
        }
      } else {
        if (this.$refs.budgetForm.validate()) {
          this.processing = true
          // Get the base currency
          var defaultCurrency = this._.find(this.currencies, function(item) {
            return item.defaultCurrency === true
          })

          delete defaultCurrency.__typename
          delete defaultCurrency.updatedAt
          delete defaultCurrency.createdAt

          // New Item
          let data = {
            title:
              this.item.department.name +
              ' Budget ' +
              this.item.financialYear.name,
            description: this.item.description,
            departmentID: this.item.department.id,
            financialYearID: this.item.financialYear.id,
            currencyID: this.item.currency.id
          }

          this.$apollo
            .mutate({
              mutation: CREATE_BUDGET,
              variables: {
                input: data
              },
              update: (store, { data: { createBudget } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: BUDGETS
                })
                // Add our requirement from the mutation to the end
                data.budgets.unshift(createBudget)
                // Write our data back to the cache.
                store.writeQuery({ query: BUDGETS, data })
              }
            })
            .then(result => {
              this.close(true, result.data.createBudget.id)
            })
            .catch(error => {
              if (
                error.message ===
                'GraphQL error: A budget for selected department and year already exists'
              ) {
                this.processing = false
                return (this.errorMsg =
                  'A budget for selected department and year already exists')
              }

              this.processing = false
              this.errorMsg =
                'A general server error occured. Please try again later'
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-budget-description {
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }

  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
