<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="supplierForm"
          v-model="valid"
          lazy-validation
          @submit.prevent
        >
          <v-row id="scroll-target" justify="center">
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.name"
                    autocomplete="off"
                    label="Name"
                    hide-details="auto"
                    outlined
                    :rules="requiredRule"
                    dense
                    autofocus
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.supplierCode"
                    autocomplete="off"
                    label="Supplier Code"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="item.companyRegistration"
                        autocomplete="off"
                        label="Company Registration"
                        hide-details="auto"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="item.vatRegistration"
                        autocomplete="off"
                        label="VAT ID"
                        hide-details="auto"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="item.paymentTerm"
                        :items="paymentTerms"
                        :rules="requiredSelectRule"
                        outlined
                        autocomplete
                        return-object
                        item-text="name"
                        hide-details
                        dense
                        label="Payment Term"
                      ></v-select>
                    </v-col>

                    <v-col>
                      <v-select
                        v-model="item.currency"
                        :items="currencies"
                        :rules="requiredSelectRule"
                        outlined
                        autocomplete
                        return-object
                        item-text="name"
                        hide-details
                        dense
                        label="Currency"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.bankAccount"
                        autocomplete="off"
                        label="Bank Account"
                        hide-details="auto"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="item.swift"
                        autocomplete="off"
                        label="Swift / BIC"
                        hide-details="auto"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.email"
                    autocomplete="off"
                    label="Email"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.phone"
                    autocomplete="off"
                    label="Phone"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="12" md="6">
                  <v-text-field
                    v-model="item.website"
                    autocomplete="off"
                    label="Website"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="item && item.address" no-gutters class="pb-3">
                <v-col>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="item.address.line1"
                        autocomplete="off"
                        label="Line 1"
                        hide-details="auto"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="item.address.line2"
                        autocomplete="off"
                        label="Line 2"
                        hide-details="auto"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="item.address.line3"
                        autocomplete="off"
                        label="Line 3"
                        hide-details="auto"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="item.address.postCode"
                        autocomplete="off"
                        label="Postcode"
                        hide-details="auto"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="item.address.city"
                        autocomplete="off"
                        label="City"
                        hide-details="auto"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        v-model="item.address.country"
                        autocomplete="off"
                        :items="countryList"
                        label="Country"
                        hide-details="auto"
                        outlined
                        dense
                        item-text="name"
                        item-value="code"
                        auto-select-first
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          rounded
          :disabled="$apollo.loading"
          @click.stop="close(false)"
          >Cancel</v-btn
        >
        <v-btn color="primary" rounded :loading="$apollo.loading" @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import SUPPLIERS from '@/graphql/Suppliers.gql'
// import CREATE_SUPPLIER from '@/graphql/SupplierCreate.gql'
// import UPDATE_SUPPLIER from '@/graphql/SupplierUpdate.gql'
import country from 'country-list'

export default {
  name: 'SupplierEdit',
  components: {},
  props: {
    editedItem: { type: Object, default: null },
    editMode: Boolean,
    open: Boolean,
    paymentTerms: { type: Array, default: () => [] },
    currencies: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    stateList: ['active', 'suspended', 'inactive'],
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    dateRule: [v => !!v || 'Date is required']
  }),
  apollo: {},
  computed: {
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    },
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },

    formTitle() {
      return this.editMode ? 'Edit Supplier' : 'New Supplier'
    }
  },
  watch: {
    open(val) {
      // Set default payment term
      if (val && !this.item.paymentTerm && this.paymentTerms) {
        var defaultTerm = this._.find(
          this.paymentTerms,
          item => item.defaultPaymentTerm
        )
        this.item.paymentTerm = defaultTerm
      }

      // Set default currency
      if (val && !this.item.currency && this.currencies) {
        let defaultCurrency = this._.find(
          this.currencies,
          item => item.defaultCurrency
        )
        this.item.currency = defaultCurrency
      }
    }
  },
  methods: {
    close(success, id) {
      this.$refs.supplierForm.resetValidation()
      this.$emit('close-edit-supplier-dialog', success, id)
    },
    save() {
      if (this.editMode) {
        if (this.$refs.supplierForm.validate()) {
          // Update Item
          let data = {
            id: this.editedItem.id,
            supplierCode: this.editedItem.supplierCode,
            name: this.editedItem.name,
            email: this.editedItem.email,
            phone: this.editedItem.phone,
            website: this.editedItem.website,
            companyRegistration: this.editedItem.companyRegistration,
            vatRegistration: this.editedItem.vatRegistration,
            bankAccount: this.editedItem.bankAccount,
            swift: this.editedItem.swift,
            address: {
              id:
                this.editedItem.address && this.editedItem.address.id
                  ? this.editedItem.address.id
                  : null,
              line1:
                this.editedItem.address && this.editedItem.address.line1
                  ? this.editedItem.address.line1
                  : null,
              line2:
                this.editedItem.address && this.editedItem.address.line2
                  ? this.editedItem.address.line2
                  : null,
              line3:
                this.editedItem.address && this.editedItem.address.line3
                  ? this.editedItem.address.line3
                  : null,
              city:
                this.editedItem.address && this.editedItem.address.city
                  ? this.editedItem.address.city
                  : null,
              postCode:
                this.editedItem.address && this.editedItem.address.postCode
                  ? this.editedItem.address.postCode
                  : null,
              country:
                this.editedItem.address && this.editedItem.address.country
                  ? this.editedItem.address.country
                  : null
            },
            currencyID: this.editedItem.currency
              ? this.editedItem.currency.id
              : null,
            paymentTermID: this.editedItem.paymentTerm
              ? this.editedItem.paymentTerm.id
              : null
          }

          this.$emit('save-supplier', data)

          // this.$apollo
          //   .mutate({
          //     mutation: UPDATE_SUPPLIER,
          //     variables: {
          //       input: data
          //     }
          //   })
          //   .then(result => {
          //     this.close(true, result.data.updateSupplier)
          //   })
        }
      } else {
        // New Item
        if (this.$refs.supplierForm.validate()) {
          let data = {
            supplierCode: this.editedItem.supplierCode,
            name: this.editedItem.name,
            email: this.editedItem.email,
            phone: this.editedItem.phone,
            website: this.editedItem.website,
            companyRegistration: this.editedItem.companyRegistration,
            vatRegistration: this.editedItem.vatRegistration,
            bankAccount: this.editedItem.bankAccount,
            swift: this.editedItem.swift,
            address: {
              id:
                this.editedItem.address && this.editedItem.address.id
                  ? this.editedItem.address.id
                  : null,
              line1:
                this.editedItem.address && this.editedItem.address.line1
                  ? this.editedItem.address.line1
                  : null,
              line2:
                this.editedItem.address && this.editedItem.address.line2
                  ? this.editedItem.address.line2
                  : null,
              line3:
                this.editedItem.address && this.editedItem.address.line3
                  ? this.editedItem.address.line3
                  : null,
              city:
                this.editedItem.address && this.editedItem.address.city
                  ? this.editedItem.address.city
                  : null,
              postCode:
                this.editedItem.address && this.editedItem.address.postCode
                  ? this.editedItem.address.postCode
                  : null,
              country:
                this.editedItem.address && this.editedItem.address.country
                  ? this.editedItem.address.country
                  : null
            },
            currencyID: this.editedItem.currency
              ? this.editedItem.currency.id
              : null,
            paymentTermID: this.editedItem.paymentTerm
              ? this.editedItem.paymentTerm.id
              : null
          }

          this.$emit('save-supplier', data)

          // this.$apollo
          //   .mutate({
          //     mutation: CREATE_SUPPLIER,
          //     variables: {
          //       input: data
          //     },
          //     update: (store, { data: { createSupplier } }) => {
          //       // Read the data from our cache for this query.
          //       const data = store.readQuery({
          //         query: SUPPLIERS
          //       })
          //       // Add our requirement from the mutation to the end
          //       data.suppliers.unshift(createSupplier)
          //       // Write our data back to the cache.
          //       store.writeQuery({ query: SUPPLIERS, data })
          //     }
          //   })
          //   .then(result => {
          //     this.close(true, result.data.createSupplier)
          //   })
          //   .catch(() => {
          //     this.$store.commit('showSnackbar', {
          //       active: true,
          //       message:
          //         'A general server error occured. Please try again later'
          //     })

          //     return this.close(false)
          //   })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-project-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
