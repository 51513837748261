<template>
  <v-dialog v-model="dialog" width="500" transition="dialog-bottom-transition">
    <v-card>
      <v-card-title>
        <span class="headline primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="budgetLineForm"
          v-model="valid"
          lazy-validation
          @submit.prevent
        >
          <v-row no-gutters>
            <v-col cols="12" class="pb-0">
              <label>Title</label>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="item.title"
                autocomplete="off"
                placeholder="Title"
                outlined
                :rules="requiredRule"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-3">
            <v-col cols="12" class="pb-0">
              <label>Description</label>
            </v-col>
            <v-col cols="12" class="py-0">
              <vue-editor
                id="edit-budget-description"
                v-model="item.description"
                :editor-options="editorConfig"
                placeholder="A short description, which describes what the budget is for."
                class="pb-3"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" lg="6" class="py-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label>Currency</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    v-model="item.currency"
                    :items="currencies"
                    :rules="requiredRule"
                    outlined
                    autocomplete
                    return-object
                    item-text="name"
                    hide-details="auto"
                    disabled
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="py-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label>Price</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-currency-field
                    v-model="item.forecastAmount"
                    autocomplete="off"
                    outlined
                    :rules="requiredRule"
                    hide-details="auto"
                    dense
                  ></v-currency-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="px-5 pb-5">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          rounded
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn color="primary" rounded :loading="$apollo.loading" @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import BUDGET_CATEGORIES from '@/graphql/BudgetCategories.gql'
import BUDGET from '@/graphql/Budget.gql'
import CREATE_BUDGET_LINE from '@/graphql/BudgetLineCreate.gql'
import UPDATE_BUDGET_LINE from '@/graphql/BudgetLineUpdate.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'BudgetLineDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: null },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    oldAmount: 0,
    // money: {
    //   decimal: ',',
    //   thousands: '.',
    //   // prefix: '€ ',
    //   precision: 2,
    //   masked: false /* doesn't work with directive */
    // },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ]
  }),
  apollo: {
    currencies: CURRENCY_LIST
  },
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Budget Line' : 'New Budget Line'
    }
  },
  created() {
    const copy = JSON.parse(JSON.stringify(this.editedItem))
    this.oldAmount = copy.pricing.forecastAmount * 100
  },
  methods: {
    close(success) {
      // this.$refs.price.$el.getElementsByTagName('input')[0].value = 0
      this.$refs.budgetLineForm.reset()
      this.$emit('close-budget-line-dialog', success)
    },
    save() {
      var that = this

      // Set the reporting Currency and exchange rate
      var baseCurrency = this._.find(this.currencies, function(item) {
        return item.defaultCurrency === true
      })

      if (this.editMode) {
        // Make a copy to make sure the new object is not the observable object
        let item = this._.cloneDeep(this.item)

        // Update Item
        let data = {
          id: item.id,
          title: item.title,
          description: item.description,
          budgetID: item.budgetID,
          budgetCategoryID: item.budgetCategoryID,
          pricing: {
            currencyID: item.currency.id,
            baseCurrencyID: baseCurrency.id,
            exchangeRate: item.currency.exchangeRate,
            displayAmount: item.forecastAmount * 100 || 0 // Return the price to an int
          }
        }

        if (this.$refs.budgetLineForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_BUDGET_LINE,
              variables: {
                input: data
              }
            })
            .then(result => {
              this.$apollo.query({
                query: BUDGET,
                variables: {
                  id: result.data.updateBudgetLine.budgetID
                },
                fetchPolicy: 'network-only'
              })
              this.close(true)
            })
        }
      } else {
        let item = this._.cloneDeep(this.item)

        // Return the price to an int
        item.pricing.displayAmount = item.pricing.forecastAmount * 100

        // New Item
        let data = {
          title: item.title,
          description: item.description,
          budgetID: item.budgetID,
          budgetCategoryID: item.budgetCategoryID,
          pricing: {
            currencyID: item.currency.id,
            baseCurrencyID: baseCurrency.id,
            exchangeRate: item.currency.exchangeRate,
            displayAmount: Math.round(item.forecastAmount * 100) || 0 // Return the price to an int
          }
        }

        if (this.$refs.budgetLineForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_BUDGET_LINE,
              variables: {
                input: data
              }
              // update: (store, { data: { createBudgetLine } }) => {
              //   // Read the data from our cache for this query.
              //   const data = store.readQuery({
              //     query: BUDGET,
              //     variables: {
              //       id: createBudgetLine.budgetID
              //     }
              //   })

              //   // Update the price
              //   data.budget.reportingForecastAmount =
              //     data.budget.reportingForecastAmount +
              //     createBudgetLine.pricing.reportingForecastAmount

              //   const lineData = store.readQuery({
              //     query: BUDGET_CATEGORIES,
              //     variables: {
              //       budgetID: createBudgetLine.budgetID
              //     }
              //   })

              //   var idx = that._.findIndex(lineData.budgetCategories, function(
              //     item
              //   ) {
              //     return item.id === createBudgetLine.budgetCategoryID
              //   })

              //   if (!lineData.budgetCategories[idx].lineItems) {
              //     lineData.budgetCategories[idx].lineItems = []
              //   }

              //   lineData.budgetCategories[idx].lineItems.push(createBudgetLine)

              //   // Add our requirement from the mutation to the end
              //   // data.budgetCategory.lineItems.push(createBudgetLine)
              //   // Write our data back to the cache.
              //   store.writeQuery(
              //     {
              //       query: BUDGET_CATEGORIES,
              //       variables: {
              //         budgetID: createBudgetLine.budgetID
              //       },
              //       lineData
              //     },
              //     {
              //       query: BUDGET,
              //       variables: {
              //         budgetID: createBudgetLine.budgetID
              //       },
              //       data
              //     }
              //   )
              //   // store.writeQuery({ query: BUDGET_CATEGORIES, lineData })
              // }
            })
            .then(() => {
              that.close(true)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-budget-description {
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
