<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="400"
    >
      <!-- <template v-slot:activator="{ on }">

      </template>-->
      <v-card>
        <v-card-title class="primary--text">
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="currencyEditForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col v-if="editMode" class="pb-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :value="item.isoName"
                      label="ISO Name"
                      outlined
                      readonly
                      disabled
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :value="item.symbol"
                      label="Symbol"
                      outlined
                      readonly
                      disabled
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="!editMode" cols="12">
                <v-select
                  v-model="selectedCurrency"
                  :items="currencies"
                  :rules="requiredRule"
                  label="Currency"
                  max-height="auto"
                  autocomplete
                  required
                  return-object
                  outlined
                  dense
                  hide-details="auto"
                >
                  <template v-slot:selection="data">
                    {{ data.item.name + ' (' + data.item.code + ')' }}
                  </template>
                  <template v-slot:item="{ item }">
                    <span @click="search = null">
                      {{ item.name + ' (' + item.code + ')' }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-currency-field
                  v-model="item.exchangeRate"
                  locale="us-EN"
                  :decimal-length="4"
                  :default-value="0"
                  autocomplete="off"
                  label="Exchange Rate"
                  hide-details="auto"
                  outlined
                  dense
                  :rules="requiredRule"
                ></v-currency-field>
              </v-col>
              <v-col cols="12">
                <v-currency-field
                  v-model="item.productionExchangeRate"
                  locale="us-EN"
                  :decimal-length="4"
                  :default-value="0"
                  autocomplete="off"
                  label="Production Exchange Rate"
                  hide-details="auto"
                  outlined
                  dense
                  :rules="requiredRule"
                ></v-currency-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-checkbox
                  v-model="item.defaultCurrency"
                  :disabled="item.defaultCurrency && !disable"
                  color="primary"
                  label="Default Currency"
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text rounded @click="close(false)">Cancel</v-btn>
          <v-btn color="primary" rounded @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import CREATE_CURRENCY from '@/graphql/CurrencyCreate.gql'
import UPDATE_CURRENCY from '@/graphql/CurrencyUpdate.gql'

export default {
  name: 'CurrencyEdit',
  props: {
    editedItem: {
      type: Object,
      default: () => ({})
    },
    open: {
      type: Boolean
    },
    editMode: {
      type: Boolean
    }
  },
  data: () => ({
    disable: false,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    selectedCurrency: null,
    currencies: [
      {
        code: 'AFN',
        name: 'Afghan Afghani',
        namePlural: 'Afghan Afghanis',
        precision: 0,
        rounding: 0,
        symbol: 'Af',
        symbolNative: '؋'
      },
      {
        code: 'ALL',
        name: 'Albanian Lek',
        namePlural: 'Albanian lekë',
        precision: 0,
        rounding: 0,
        symbol: 'ALL',
        symbolNative: 'Lek'
      },
      {
        code: 'DZD',
        name: 'Algerian Dinar',
        namePlural: 'Algerian dinars',
        precision: 2,
        rounding: 0,
        symbol: 'DA',
        symbolNative: 'د.ج.‏'
      },
      {
        code: 'ARS',
        name: 'Argentine Peso',
        namePlural: 'Argentine pesos',
        precision: 2,
        rounding: 0,
        symbol: 'AR$',
        symbolNative: '$'
      },
      {
        code: 'AMD',
        name: 'Armenian Dram',
        namePlural: 'Armenian drams',
        precision: 0,
        rounding: 0,
        symbol: 'AMD',
        symbolNative: 'դր.'
      },
      {
        code: 'AUD',
        name: 'Australian Dollar',
        namePlural: 'Australian dollars',
        precision: 2,
        rounding: 0,
        symbol: 'AU$',
        symbolNative: '$'
      },
      {
        code: 'AZN',
        name: 'Azerbaijani Manat',
        namePlural: 'Azerbaijani manats',
        precision: 2,
        rounding: 0,
        symbol: 'man.',
        symbolNative: 'ман.'
      },
      {
        code: 'BHD',
        name: 'Bahraini Dinar',
        namePlural: 'Bahraini dinars',
        precision: 3,
        rounding: 0,
        symbol: 'BD',
        symbolNative: 'د.ب.‏'
      },
      {
        code: 'BDT',
        name: 'Bangladeshi Taka',
        namePlural: 'Bangladeshi takas',
        precision: 2,
        rounding: 0,
        symbol: 'Tk',
        symbolNative: '৳'
      },
      {
        code: 'BYR',
        name: 'Belarusian Ruble',
        namePlural: 'Belarusian rubles',
        precision: 0,
        rounding: 0,
        symbol: 'BYR',
        symbolNative: 'BYR'
      },
      {
        code: 'BZD',
        name: 'Belize Dollar',
        namePlural: 'Belize dollars',
        precision: 2,
        rounding: 0,
        symbol: 'BZ$',
        symbolNative: '$'
      },
      {
        code: 'BOB',
        name: 'Bolivian Boliviano',
        namePlural: 'Bolivian bolivianos',
        precision: 2,
        rounding: 0,
        symbol: 'Bs',
        symbolNative: 'Bs'
      },
      {
        code: 'BAM',
        name: 'Bosnia-Herzegovina Convertible Mark',
        namePlural: 'Bosnia-Herzegovina convertible marks',
        precision: 2,
        rounding: 0,
        symbol: 'KM',
        symbolNative: 'KM'
      },
      {
        code: 'BWP',
        name: 'Botswanan Pula',
        namePlural: 'Botswanan pulas',
        precision: 2,
        rounding: 0,
        symbol: 'BWP',
        symbolNative: 'P'
      },
      {
        code: 'BRL',
        name: 'Brazilian Real',
        namePlural: 'Brazilian reals',
        precision: 2,
        rounding: 0,
        symbol: 'R$',
        symbolNative: 'R$'
      },
      {
        code: 'GBP',
        name: 'British Pound Sterling',
        namePlural: 'British pounds sterling',
        precision: 2,
        rounding: 0,
        symbol: '£',
        symbolNative: '£'
      },
      {
        code: 'BND',
        name: 'Brunei Dollar',
        namePlural: 'Brunei dollars',
        precision: 2,
        rounding: 0,
        symbol: 'BN$',
        symbolNative: '$'
      },
      {
        code: 'BGN',
        name: 'Bulgarian Lev',
        namePlural: 'Bulgarian leva',
        precision: 2,
        rounding: 0,
        symbol: 'BGN',
        symbolNative: 'лв.'
      },
      {
        code: 'BIF',
        name: 'Burundian Franc',
        namePlural: 'Burundian francs',
        precision: 0,
        rounding: 0,
        symbol: 'FBu',
        symbolNative: 'FBu'
      },
      {
        code: 'XOF',
        name: 'CFA Franc BCEAO',
        namePlural: 'CFA francs BCEAO',
        precision: 0,
        rounding: 0,
        symbol: 'CFA',
        symbolNative: 'CFA'
      },
      {
        code: 'XAF',
        name: 'CFA Franc BEAC',
        namePlural: 'CFA francs BEAC',
        precision: 0,
        rounding: 0,
        symbol: 'FCFA',
        symbolNative: 'FCFA'
      },
      {
        code: 'KHR',
        name: 'Cambodian Riel',
        namePlural: 'Cambodian riels',
        precision: 2,
        rounding: 0,
        symbol: 'KHR',
        symbolNative: '៛'
      },
      {
        code: 'CAD',
        name: 'Canadian Dollar',
        namePlural: 'Canadian dollars',
        precision: 2,
        rounding: 0,
        symbol: 'CA$',
        symbolNative: '$'
      },
      {
        code: 'CVE',
        name: 'Cape Verdean Escudo',
        namePlural: 'Cape Verdean escudos',
        precision: 2,
        rounding: 0,
        symbol: 'CV$',
        symbolNative: 'CV$'
      },
      {
        code: 'CLP',
        name: 'Chilean Peso',
        namePlural: 'Chilean pesos',
        precision: 0,
        rounding: 0,
        symbol: 'CL$',
        symbolNative: '$'
      },
      {
        code: 'CNY',
        name: 'Chinese Yuan',
        namePlural: 'Chinese yuan',
        precision: 2,
        rounding: 0,
        symbol: 'CN¥',
        symbolNative: 'CN¥'
      },
      {
        code: 'COP',
        name: 'Colombian Peso',
        namePlural: 'Colombian pesos',
        precision: 0,
        rounding: 0,
        symbol: 'CO$',
        symbolNative: '$'
      },
      {
        code: 'KMF',
        name: 'Comorian Franc',
        namePlural: 'Comorian francs',
        precision: 0,
        rounding: 0,
        symbol: 'CF',
        symbolNative: 'FC'
      },
      {
        code: 'CDF',
        name: 'Congolese Franc',
        namePlural: 'Congolese francs',
        precision: 2,
        rounding: 0,
        symbol: 'CDF',
        symbolNative: 'FrCD'
      },
      {
        code: 'CRC',
        name: 'Costa Rican Colón',
        namePlural: 'Costa Rican colóns',
        precision: 0,
        rounding: 0,
        symbol: '₡',
        symbolNative: '₡'
      },
      {
        code: 'HRK',
        name: 'Croatian Kuna',
        namePlural: 'Croatian kunas',
        precision: 2,
        rounding: 0,
        symbol: 'kn',
        symbolNative: 'kn'
      },
      {
        code: 'CZK',
        name: 'Czech Republic Koruna',
        namePlural: 'Czech Republic korunas',
        precision: 2,
        rounding: 0,
        symbol: 'Kč',
        symbolNative: 'Kč'
      },
      {
        code: 'DKK',
        name: 'Danish Krone',
        namePlural: 'Danish kroner',
        precision: 2,
        rounding: 0,
        symbol: 'Dkr',
        symbolNative: 'kr'
      },
      {
        code: 'DJF',
        name: 'Djiboutian Franc',
        namePlural: 'Djiboutian francs',
        precision: 0,
        rounding: 0,
        symbol: 'Fdj',
        symbolNative: 'Fdj'
      },
      {
        code: 'DOP',
        name: 'Dominican Peso',
        namePlural: 'Dominican pesos',
        precision: 2,
        rounding: 0,
        symbol: 'RD$',
        symbolNative: 'RD$'
      },
      {
        code: 'EGP',
        name: 'Egyptian Pound',
        namePlural: 'Egyptian pounds',
        precision: 2,
        rounding: 0,
        symbol: 'EGP',
        symbolNative: 'ج.م.‏'
      },
      {
        code: 'ERN',
        name: 'Eritrean Nakfa',
        namePlural: 'Eritrean nakfas',
        precision: 2,
        rounding: 0,
        symbol: 'Nfk',
        symbolNative: 'Nfk'
      },
      {
        code: 'EEK',
        name: 'Estonian Kroon',
        namePlural: 'Estonian kroons',
        precision: 2,
        rounding: 0,
        symbol: 'Ekr',
        symbolNative: 'kr'
      },
      {
        code: 'ETB',
        name: 'Ethiopian Birr',
        namePlural: 'Ethiopian birrs',
        precision: 2,
        rounding: 0,
        symbol: 'Br',
        symbolNative: 'Br'
      },
      {
        code: 'EUR',
        name: 'Euro',
        namePlural: 'euros',
        precision: 2,
        rounding: 0,
        symbol: '€',
        symbolNative: '€'
      },
      {
        code: 'GEL',
        name: 'Georgian Lari',
        namePlural: 'Georgian laris',
        precision: 2,
        rounding: 0,
        symbol: 'GEL',
        symbolNative: 'GEL'
      },
      {
        code: 'GHS',
        name: 'Ghanaian Cedi',
        namePlural: 'Ghanaian cedis',
        precision: 2,
        rounding: 0,
        symbol: 'GH₵',
        symbolNative: 'GH₵'
      },
      {
        code: 'GTQ',
        name: 'Guatemalan Quetzal',
        namePlural: 'Guatemalan quetzals',
        precision: 2,
        rounding: 0,
        symbol: 'GTQ',
        symbolNative: 'Q'
      },
      {
        code: 'GNF',
        name: 'Guinean Franc',
        namePlural: 'Guinean francs',
        precision: 0,
        rounding: 0,
        symbol: 'FG',
        symbolNative: 'FG'
      },
      {
        code: 'HNL',
        name: 'Honduran Lempira',
        namePlural: 'Honduran lempiras',
        precision: 2,
        rounding: 0,
        symbol: 'HNL',
        symbolNative: 'L'
      },
      {
        code: 'HKD',
        name: 'Hong Kong Dollar',
        namePlural: 'Hong Kong dollars',
        precision: 2,
        rounding: 0,
        symbol: 'HK$',
        symbolNative: '$'
      },
      {
        code: 'HUF',
        name: 'Hungarian Forint',
        namePlural: 'Hungarian forints',
        precision: 0,
        rounding: 0,
        symbol: 'Ft',
        symbolNative: 'Ft'
      },
      {
        code: 'ISK',
        name: 'Icelandic Króna',
        namePlural: 'Icelandic krónur',
        precision: 0,
        rounding: 0,
        symbol: 'Ikr',
        symbolNative: 'kr'
      },
      {
        code: 'INR',
        name: 'Indian Rupee',
        namePlural: 'Indian rupees',
        precision: 2,
        rounding: 0,
        symbol: 'Rs',
        symbolNative: 'টকা'
      },
      {
        code: 'IDR',
        name: 'Indonesian Rupiah',
        namePlural: 'Indonesian rupiahs',
        precision: 0,
        rounding: 0,
        symbol: 'Rp',
        symbolNative: 'Rp'
      },
      {
        code: 'IRR',
        name: 'Iranian Rial',
        namePlural: 'Iranian rials',
        precision: 0,
        rounding: 0,
        symbol: 'IRR',
        symbolNative: '﷼'
      },
      {
        code: 'IQD',
        name: 'Iraqi Dinar',
        namePlural: 'Iraqi dinars',
        precision: 0,
        rounding: 0,
        symbol: 'IQD',
        symbolNative: 'د.ع.‏'
      },
      {
        code: 'ILS',
        name: 'Israeli New Sheqel',
        namePlural: 'Israeli new sheqels',
        precision: 2,
        rounding: 0,
        symbol: '₪',
        symbolNative: '₪'
      },
      {
        code: 'JMD',
        name: 'Jamaican Dollar',
        namePlural: 'Jamaican dollars',
        precision: 2,
        rounding: 0,
        symbol: 'J$',
        symbolNative: '$'
      },
      {
        code: 'JPY',
        name: 'Japanese Yen',
        namePlural: 'Japanese yen',
        precision: 0,
        rounding: 0,
        symbol: '¥',
        symbolNative: '￥'
      },
      {
        code: 'JOD',
        name: 'Jordanian Dinar',
        namePlural: 'Jordanian dinars',
        precision: 3,
        rounding: 0,
        symbol: 'JD',
        symbolNative: 'د.أ.‏'
      },
      {
        code: 'KZT',
        name: 'Kazakhstani Tenge',
        namePlural: 'Kazakhstani tenges',
        precision: 2,
        rounding: 0,
        symbol: 'KZT',
        symbolNative: 'тңг.'
      },
      {
        code: 'KES',
        name: 'Kenyan Shilling',
        namePlural: 'Kenyan shillings',
        precision: 2,
        rounding: 0,
        symbol: 'Ksh',
        symbolNative: 'Ksh'
      },
      {
        code: 'KWD',
        name: 'Kuwaiti Dinar',
        namePlural: 'Kuwaiti dinars',
        precision: 3,
        rounding: 0,
        symbol: 'KD',
        symbolNative: 'د.ك.‏'
      },
      {
        code: 'LVL',
        name: 'Latvian Lats',
        namePlural: 'Latvian lati',
        precision: 2,
        rounding: 0,
        symbol: 'Ls',
        symbolNative: 'Ls'
      },
      {
        code: 'LBP',
        name: 'Lebanese Pound',
        namePlural: 'Lebanese pounds',
        precision: 0,
        rounding: 0,
        symbol: 'LB£',
        symbolNative: 'ل.ل.‏'
      },
      {
        code: 'LYD',
        name: 'Libyan Dinar',
        namePlural: 'Libyan dinars',
        precision: 3,
        rounding: 0,
        symbol: 'LD',
        symbolNative: 'د.ل.‏'
      },
      {
        code: 'LTL',
        name: 'Lithuanian Litas',
        namePlural: 'Lithuanian litai',
        precision: 2,
        rounding: 0,
        symbol: 'Lt',
        symbolNative: 'Lt'
      },
      {
        code: 'MOP',
        name: 'Macanese Pataca',
        namePlural: 'Macanese patacas',
        precision: 2,
        rounding: 0,
        symbol: 'MOP$',
        symbolNative: 'MOP$'
      },
      {
        code: 'MKD',
        name: 'Macedonian Denar',
        namePlural: 'Macedonian denari',
        precision: 2,
        rounding: 0,
        symbol: 'MKD',
        symbolNative: 'MKD'
      },
      {
        code: 'MGA',
        name: 'Malagasy Ariary',
        namePlural: 'Malagasy Ariaries',
        precision: 0,
        rounding: 0,
        symbol: 'MGA',
        symbolNative: 'MGA'
      },
      {
        code: 'MYR',
        name: 'Malaysian Ringgit',
        namePlural: 'Malaysian ringgits',
        precision: 2,
        rounding: 0,
        symbol: 'RM',
        symbolNative: 'RM'
      },
      {
        code: 'MUR',
        name: 'Mauritian Rupee',
        namePlural: 'Mauritian rupees',
        precision: 0,
        rounding: 0,
        symbol: 'MURs',
        symbolNative: 'MURs'
      },
      {
        code: 'MXN',
        name: 'Mexican Peso',
        namePlural: 'Mexican pesos',
        precision: 2,
        rounding: 0,
        symbol: 'MX$',
        symbolNative: '$'
      },
      {
        code: 'MDL',
        name: 'Moldovan Leu',
        namePlural: 'Moldovan lei',
        precision: 2,
        rounding: 0,
        symbol: 'MDL',
        symbolNative: 'MDL'
      },
      {
        code: 'MAD',
        name: 'Moroccan Dirham',
        namePlural: 'Moroccan dirhams',
        precision: 2,
        rounding: 0,
        symbol: 'MAD',
        symbolNative: 'د.م.‏'
      },
      {
        code: 'MZN',
        name: 'Mozambican Metical',
        namePlural: 'Mozambican meticals',
        precision: 2,
        rounding: 0,
        symbol: 'MTn',
        symbolNative: 'MTn'
      },
      {
        code: 'MMK',
        name: 'Myanma Kyat',
        namePlural: 'Myanma kyats',
        precision: 0,
        rounding: 0,
        symbol: 'MMK',
        symbolNative: 'K'
      },
      {
        code: 'NAD',
        name: 'Namibian Dollar',
        namePlural: 'Namibian dollars',
        precision: 2,
        rounding: 0,
        symbol: 'N$',
        symbolNative: 'N$'
      },
      {
        code: 'NPR',
        name: 'Nepalese Rupee',
        namePlural: 'Nepalese rupees',
        precision: 2,
        rounding: 0,
        symbol: 'NPRs',
        symbolNative: 'नेरू'
      },
      {
        code: 'TWD',
        name: 'New Taiwan Dollar',
        namePlural: 'New Taiwan dollars',
        precision: 2,
        rounding: 0,
        symbol: 'NT$',
        symbolNative: 'NT$'
      },
      {
        code: 'NZD',
        name: 'New Zealand Dollar',
        namePlural: 'New Zealand dollars',
        precision: 2,
        rounding: 0,
        symbol: 'NZ$',
        symbolNative: '$'
      },
      {
        code: 'NIO',
        name: 'Nicaraguan Córdoba',
        namePlural: 'Nicaraguan córdobas',
        precision: 2,
        rounding: 0,
        symbol: 'C$',
        symbolNative: 'C$'
      },
      {
        code: 'NGN',
        name: 'Nigerian Naira',
        namePlural: 'Nigerian nairas',
        precision: 2,
        rounding: 0,
        symbol: '₦',
        symbolNative: '₦'
      },
      {
        code: 'NOK',
        name: 'Norwegian Krone',
        namePlural: 'Norwegian kroner',
        precision: 2,
        rounding: 0,
        symbol: 'Nkr',
        symbolNative: 'kr'
      },
      {
        code: 'OMR',
        name: 'Omani Rial',
        namePlural: 'Omani rials',
        precision: 3,
        rounding: 0,
        symbol: 'OMR',
        symbolNative: 'ر.ع.‏'
      },
      {
        code: 'PKR',
        name: 'Pakistani Rupee',
        namePlural: 'Pakistani rupees',
        precision: 0,
        rounding: 0,
        symbol: 'PKRs',
        symbolNative: '₨'
      },
      {
        code: 'PAB',
        name: 'Panamanian Balboa',
        namePlural: 'Panamanian balboas',
        precision: 2,
        rounding: 0,
        symbol: 'B/.',
        symbolNative: 'B/.'
      },
      {
        code: 'PYG',
        name: 'Paraguayan Guarani',
        namePlural: 'Paraguayan guaranis',
        precision: 0,
        rounding: 0,
        symbol: '₲',
        symbolNative: '₲'
      },
      {
        code: 'PEN',
        name: 'Peruvian Nuevo Sol',
        namePlural: 'Peruvian nuevos soles',
        precision: 2,
        rounding: 0,
        symbol: 'S/.',
        symbolNative: 'S/.'
      },
      {
        code: 'PHP',
        name: 'Philippine Peso',
        namePlural: 'Philippine pesos',
        precision: 2,
        rounding: 0,
        symbol: '₱',
        symbolNative: '₱'
      },
      {
        code: 'PLN',
        name: 'Polish Zloty',
        namePlural: 'Polish zlotys',
        precision: 2,
        rounding: 0,
        symbol: 'zł',
        symbolNative: 'zł'
      },
      {
        code: 'QAR',
        name: 'Qatari Rial',
        namePlural: 'Qatari rials',
        precision: 2,
        rounding: 0,
        symbol: 'QR',
        symbolNative: 'ر.ق.‏'
      },
      {
        code: 'RON',
        name: 'Romanian Leu',
        namePlural: 'Romanian lei',
        precision: 2,
        rounding: 0,
        symbol: 'RON',
        symbolNative: 'RON'
      },
      {
        code: 'RUB',
        name: 'Russian Ruble',
        namePlural: 'Russian rubles',
        precision: 2,
        rounding: 0,
        symbol: 'RUB',
        symbolNative: 'руб.'
      },
      {
        code: 'RWF',
        name: 'Rwandan Franc',
        namePlural: 'Rwandan francs',
        precision: 0,
        rounding: 0,
        symbol: 'RWF',
        symbolNative: 'FR'
      },
      {
        code: 'SAR',
        name: 'Saudi Riyal',
        namePlural: 'Saudi riyals',
        precision: 2,
        rounding: 0,
        symbol: 'SR',
        symbolNative: 'ر.س.‏'
      },
      {
        code: 'RSD',
        name: 'Serbian Dinar',
        namePlural: 'Serbian dinars',
        precision: 0,
        rounding: 0,
        symbol: 'din.',
        symbolNative: 'дин.'
      },
      {
        code: 'SGD',
        name: 'Singapore Dollar',
        namePlural: 'Singapore dollars',
        precision: 2,
        rounding: 0,
        symbol: 'S$',
        symbolNative: '$'
      },
      {
        code: 'SOS',
        name: 'Somali Shilling',
        namePlural: 'Somali shillings',
        precision: 0,
        rounding: 0,
        symbol: 'Ssh',
        symbolNative: 'Ssh'
      },
      {
        code: 'ZAR',
        name: 'South African Rand',
        namePlural: 'South African rand',
        precision: 2,
        rounding: 0,
        symbol: 'R',
        symbolNative: 'R'
      },
      {
        code: 'KRW',
        name: 'South Korean Won',
        namePlural: 'South Korean won',
        precision: 0,
        rounding: 0,
        symbol: '₩',
        symbolNative: '₩'
      },
      {
        code: 'LKR',
        name: 'Sri Lankan Rupee',
        namePlural: 'Sri Lankan rupees',
        precision: 2,
        rounding: 0,
        symbol: 'SLRs',
        symbolNative: 'SL Re'
      },
      {
        code: 'SDG',
        name: 'Sudanese Pound',
        namePlural: 'Sudanese pounds',
        precision: 2,
        rounding: 0,
        symbol: 'SDG',
        symbolNative: 'SDG'
      },
      {
        code: 'SEK',
        name: 'Swedish Krona',
        namePlural: 'Swedish kronor',
        precision: 2,
        rounding: 0,
        symbol: 'Skr',
        symbolNative: 'kr'
      },
      {
        code: 'CHF',
        name: 'Swiss Franc',
        namePlural: 'Swiss francs',
        precision: 2,
        rounding: 0.05,
        symbol: 'CHF',
        symbolNative: 'CHF'
      },
      {
        code: 'SYP',
        name: 'Syrian Pound',
        namePlural: 'Syrian pounds',
        precision: 0,
        rounding: 0,
        symbol: 'SY£',
        symbolNative: 'ل.س.‏'
      },
      {
        code: 'TZS',
        name: 'Tanzanian Shilling',
        namePlural: 'Tanzanian shillings',
        precision: 0,
        rounding: 0,
        symbol: 'TSh',
        symbolNative: 'TSh'
      },
      {
        code: 'THB',
        name: 'Thai Baht',
        namePlural: 'Thai baht',
        precision: 2,
        rounding: 0,
        symbol: '฿',
        symbolNative: '฿'
      },
      {
        code: 'TOP',
        name: 'Tongan Paʻanga',
        namePlural: 'Tongan paʻanga',
        precision: 2,
        rounding: 0,
        symbol: 'T$',
        symbolNative: 'T$'
      },
      {
        code: 'TTD',
        name: 'Trinidad and Tobago Dollar',
        namePlural: 'Trinidad and Tobago dollars',
        precision: 2,
        rounding: 0,
        symbol: 'TT$',
        symbolNative: '$'
      },
      {
        code: 'TND',
        name: 'Tunisian Dinar',
        namePlural: 'Tunisian dinars',
        precision: 3,
        rounding: 0,
        symbol: 'DT',
        symbolNative: 'د.ت.‏'
      },
      {
        code: 'TRY',
        name: 'Turkish Lira',
        namePlural: 'Turkish Lira',
        precision: 2,
        rounding: 0,
        symbol: 'TL',
        symbolNative: 'TL'
      },
      {
        code: 'USD',
        name: 'US Dollar',
        namePlural: 'US dollars',
        precision: 2,
        rounding: 0,
        symbol: '$',
        symbolNative: '$'
      },
      {
        code: 'UGX',
        name: 'Ugandan Shilling',
        namePlural: 'Ugandan shillings',
        precision: 0,
        rounding: 0,
        symbol: 'USh',
        symbolNative: 'USh'
      },
      {
        code: 'UAH',
        name: 'Ukrainian Hryvnia',
        namePlural: 'Ukrainian hryvnias',
        precision: 2,
        rounding: 0,
        symbol: '₴',
        symbolNative: '₴'
      },
      {
        code: 'AED',
        name: 'United Arab Emirates Dirham',
        namePlural: 'UAE dirhams',
        precision: 2,
        rounding: 0,
        symbol: 'AED',
        symbolNative: 'د.إ.‏'
      },
      {
        code: 'UYU',
        name: 'Uruguayan Peso',
        namePlural: 'Uruguayan pesos',
        precision: 2,
        rounding: 0,
        symbol: '$U',
        symbolNative: '$'
      },
      {
        code: 'UZS',
        name: 'Uzbekistan Som',
        namePlural: 'Uzbekistan som',
        precision: 0,
        rounding: 0,
        symbol: 'UZS',
        symbolNative: 'UZS'
      },
      {
        code: 'VEF',
        name: 'Venezuelan Bolívar',
        namePlural: 'Venezuelan bolívars',
        precision: 2,
        rounding: 0,
        symbol: 'Bs.F.',
        symbolNative: 'Bs.F.'
      },
      {
        code: 'VND',
        name: 'Vietnamese Dong',
        namePlural: 'Vietnamese dong',
        precision: 0,
        rounding: 0,
        symbol: '₫',
        symbolNative: '₫'
      },
      {
        code: 'YER',
        name: 'Yemeni Rial',
        namePlural: 'Yemeni rials',
        precision: 0,
        rounding: 0,
        symbol: 'YR',
        symbolNative: 'ر.ي.‏'
      },
      {
        code: 'ZMK',
        name: 'Zambian Kwacha',
        namePlural: 'Zambian kwachas',
        precision: 0,
        rounding: 0,
        symbol: 'ZK',
        symbolNative: 'ZK'
      }
    ]
  }),
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        // if (val) {
        //   return true
        // }
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Currency' : 'New Currency'
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  methods: {
    close(success) {
      // if (!this.editMode) {
      //   this.$refs.userForm.reset()
      // }
      this.$emit('close-edit-currency-dialog', success)
    },

    save() {
      if (this.editMode) {
        // Edit item
        let item = {
          id: this.item.id,
          name: this.item.name,
          isoName: this.item.isoName,
          symbol: this.item.symbol,
          defaultCurrency: this.item.defaultCurrency,
          exchangeRate: parseFloat(this.item.exchangeRate),
          productionExchangeRate: parseFloat(this.item.productionExchangeRate)
        }
        // validate
        if (this.$refs.currencyEditForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_CURRENCY,
              variables: {
                input: item
              },
              update: (store, { data: { updateCurrency } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: CURRENCY_LIST
                })

                if (updateCurrency.defaultCurrency) {
                  this._.forEach(data.currencies, function(item) {
                    if (item.id !== updateCurrency.id) {
                      item.defaultCurrency = false
                    }
                  })
                }

                // this._.find(data.currencies, function (item) {
                //   if (item.id === updateCurrency.id) {
                //     item = updateCurrency
                //   }
                // })

                // Write our data back to the cache.
                store.writeQuery({ query: CURRENCY_LIST, data })
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        // Save new item
        let item = {
          name: this.selectedCurrency.name,
          isoName: this.selectedCurrency.code,
          symbol: this.selectedCurrency.symbol,
          defaultCurrency: this.item.defaultCurrency,
          exchangeRate: parseFloat(this.item.exchangeRate),
          productionExchangeRate: parseFloat(this.item.productionExchangeRate)
        }
        // validate
        if (this.$refs.currencyEditForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_CURRENCY,
              variables: {
                input: item
              },
              update: (store, { data: { createCurrency } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: CURRENCY_LIST
                })
                // Add our requirement from the mutation to the end
                data.currencies.push(createCurrency)
                // Write our data back to the cache.
                store.writeQuery({ query: CURRENCY_LIST, data })
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      }
    }
    // disable () {
    //   const val = this.editedItem.defaultCurrency
    //   if (val) {
    //     return true
    //   }
    //   return false
    // }
  }
}
</script>

<style></style>
