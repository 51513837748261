<template>
  <v-data-table
    :headers="headers"
    :items="projects"
    :loading="loading"
    :hide-default-footer="!projects || projects.length === 0"
    :footer-props="null"
    :mobile-breakpoint="null"
    :items-per-page="-1"
    class="plain-table"
    @click:row="navToDetail"
  >
    <template v-slot:item.name="{ item }">
      <div
        style="white-space: nowrap"
        class="primary--text text-truncate body-1"
      >
        {{ item.name }}
      </div>
      <!-- <div class="grey--text darken-3 text-truncate mt-1">
                    {{ item.owner.firstName + ' ' + item.owner.lastName }}
                  </div> -->
    </template>
    <!-- <template v-slot:item.owner.firstName="{ item }">
          <div class="text-truncate">
            {{ item.owner.firstName + ' ' + item.owner.lastName }}
          </div>
        </template> -->
    <!-- <template v-slot:item.department.name="{ item }">
          <v-chip color="primary" small label outlined class="mr-3">{{
            item.department.name
          }}</v-chip>
        </template> -->
    <template v-slot:item.department="{ item }">
      <div class="py-2">
        <v-chip
          color="info lighten-2 font-weight-bold upperCaseSpacing"
          x-small
          >{{ item.department.name }}</v-chip
        >
        <div class="grey--text text--darken-1 body-2 text-truncate mt-1">
          {{ item.owner.firstName + ' ' + item.owner.lastName }}
        </div>
      </div>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip :color="stateColor(item.status)" x-small>{{
        item.status
      }}</v-chip>
    </template>
    <template v-slot:item.forecastAmount="{ item }">
      <div class="text-truncate">
        {{
          (item.forecastAmount / 100)
            | currency(item.currency.symbol, 2, {
              spaceBetweenAmountAndSymbol: true
            })
        }}
      </div>
    </template>
    <template v-slot:item.pendingAmount="{ item }">
      <div class="text-truncate">
        {{
          (item.pendingAmount / 100)
            | currency(item.currency.symbol, 2, {
              spaceBetweenAmountAndSymbol: true
            })
        }}
      </div>
    </template>
    <template v-slot:item.actualAmount="{ item }">
      <div class="text-truncate">
        {{
          (item.actualAmount / 100)
            | currency(item.currency.symbol, 2, {
              spaceBetweenAmountAndSymbol: true
            })
        }}
      </div>
    </template>
    <template v-slot:item.balanceAmount="{ item }">
      <div class="text-truncate info--text">
        {{
          (item.balanceAmount / 100)
            | currency(item.currency.symbol, 2, {
              spaceBetweenAmountAndSymbol: true
            })
        }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'ProjectAllListItems',
  components: {},
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Forecast',
          align: 'left',
          value: 'forecastAmount',
          sortable: false
        },
        {
          text: 'In Progress',
          align: 'left',
          value: 'pendingAmount',
          sortable: false
        },
        {
          text: 'Actual',
          align: 'left',
          value: 'actualAmount',
          sortable: false
        },
        {
          text: 'Balance',
          align: 'left',
          value: 'balanceAmount',
          sortable: false
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
          sortable: false
          // filter: this.stateFilterValue
        }
      ]
    },
    projects() {
      return this.data
    },
    totalCount() {
      return this.projects.totalCount
    },
    itemsPerPage: {
      get() {
        return this.first
      },
      set() {
        return this.first
      }
    }
  },
  watch: {
    listOptions(e) {
      // if (this.projects.totalCount) {
      //   return this.$emit('fetch-more-projects', e)
      // }
      return this.$emit('set-options', e)
    }
  },
  methods: {
    loadMore() {
      return this.$emit('fetch-more-projects')
    },
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    },
    navToDetail(item) {
      this.$router.push({
        name: 'ProjectDetail',
        params: { projectID: item.id }
      })
    }
  }
}
</script>

<style lang="scss">
.list-item {
  // border: 1px solid transparent;
  &:hover {
    border-color: var(--v-primary-base);
  }
}

.plain-table .v-input__slot {
  background: transparent !important;
}

.plain-table.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content) {
  cursor: pointer !important;
}
</style>
