<template>
  <div class="mt-10">
    <input
      ref="uploadBox"
      type="file"
      :multiple="multi"
      hidden
      @change="uploadFiles"
    />
    <v-btn :color="btnColor" small fab @click="selectFiles">
      <v-icon>{{ icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'UploadButton',
  props: {
    multi: {
      type: Boolean,
      default: false
    },
    btnColor: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: 'mdi-plus'
    }
  },
  data: () => ({}),
  methods: {
    selectFiles() {
      this.$refs.uploadBox.click()
    },
    uploadFiles(e) {
      this.$emit('selected-files', e.target.files)
    }
  }
}
</script>

<style></style>
