<template>
  <div class="page-header">
    <v-toolbar
      v-if="!$apollo.loading && budget"
      class="mb-0 b-radius"
      extended
      flat
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-row justify="start" align="start">
              <v-col cols="8">
                <v-row>
                  <v-col class="pb-1 pl-0">
                    <h2 class="mb-0 info--text">
                      <span>{{ budget.title }}</span>
                    </h2>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    v-if="budget && budget.owner && budget.owner.id"
                    cols="12"
                    class="py-0"
                  >
                    <v-row justify="start">
                      <avatar
                        :username="
                          budget.owner.firstName + ' ' + budget.owner.lastName
                        "
                        :size="25"
                        color="white"
                        style="user-select: none;"
                        class="mr-3 d-none d-sm-flex"
                      ></avatar>
                      <span
                        class="grey--text text--darken-2 body-2 d-none d-sm-flex mr-5 pt-1"
                        >{{
                          budget.owner.firstName + ' ' + budget.owner.lastName
                        }}</span
                      >
                      <v-chip
                        :color="stateColor(budget.status)"
                        x-small
                        class="mt-2"
                        >{{ budget.status }}</v-chip
                      >
                    </v-row>
                  </v-col>
                  <!-- <v-col cols="4" class="py-0">
                    <v-row justify="end">
                      <v-chip :color="stateColor(budget.status)" x-small>{{
                        budget.status
                      }}</v-chip>
                    </v-row>
                  </v-col> -->
                </v-row>
              </v-col>
              <v-row cols="4">
                <v-col cols="12">
                  <v-row justify="end">
                    <v-dialog
                      v-if="
                        budget &&
                          budget.owner &&
                          currentUser &&
                          (budget.status === 'draft' ||
                            budget.status === 'pendingchanges') &&
                          currentUser.id === budget.owner.id
                      "
                      v-model="dialog"
                      persistent
                      max-width="500"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          small
                          submit
                          rounded
                          class="mt-5 mr-3"
                          v-on="on"
                        >
                          <v-icon small left>mdi-upload-outline</v-icon>submit
                        </v-btn>
                      </template>
                      <v-card flat>
                        <v-card-title class="headline primary--text mb-3"
                          >Submit for Approval</v-card-title
                        >
                        <v-card-text class="pb-0">
                          <p>This will submit your budget for approval.</p>
                          <p>
                            Once submitted you will not be able to make any
                            changes.
                          </p>
                        </v-card-text>
                        <v-card-actions class="pa-5">
                          <div class="flex-grow-1" />
                          <v-btn
                            color="accent darken-1"
                            text
                            small
                            rounded
                            :disabled="processing"
                            @click="dialog = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="primary"
                            rounded
                            small
                            :disabled="processing"
                            @click="submit"
                            ><span
                              v-if="
                                budget && budget.status === 'pendingchanges'
                              "
                              >re-</span
                            >Submit</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <!-- Adjustment Dialog -->
                    <v-dialog
                      v-if="
                        budget &&
                          budget.owner &&
                          currentUser &&
                          (budget.status === 'approved' ||
                            budget.status === 'pendingadjustment') &&
                          budget.adjustmentAmount &&
                          budget.adjustmentAmount > 0 &&
                          currentUser.id === budget.owner.id
                      "
                      v-model="budgetAdjustmentDialog"
                      persistent
                      max-width="500"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          small
                          submit
                          rounded
                          class="mt-5 mr-3"
                          v-on="on"
                        >
                          <!-- <v-icon small left>mdi-upload-outline</v-icon> -->
                          submit adjustment
                        </v-btn>
                      </template>
                      <v-card flat>
                        <v-card-title class="headline primary--text mb-3"
                          >Adjustment Approval</v-card-title
                        >
                        <v-card-text class="pb-0">
                          <p>
                            This will submit your budget adjustment for
                            approval.
                          </p>
                          <p>
                            Once submitted you will not be able to make any
                            changes.
                          </p>
                        </v-card-text>
                        <v-card-actions class="pa-5">
                          <div class="flex-grow-1" />
                          <v-btn
                            color="accent darken-1"
                            text
                            small
                            rounded
                            :disabled="processing"
                            @click="budgetAdjustmentDialog = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="primary"
                            rounded
                            small
                            :disabled="processing"
                            @click="submitAdjustment"
                            ><span
                              v-if="
                                budget && budget.status === 'pendingchanges'
                              "
                              >re-</span
                            >Submit</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-if="
                        budget &&
                        currentUser &&
                        (
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.isAdmin ||
                          currentUser.isSuperAdmin
                        )
                      "
                      v-model="updateOwnerDialog"
                      persistent
                      max-width="450"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          small
                          submit
                          rounded
                          class="mt-5 ml-3 mr-3"
                          v-on="on"
                        >
                          change owner
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="headline primary--text mb-3"
                          >Transfer Ownership</v-card-title
                        >
                        <v-card-text>
                          <v-form
                            ref="changeOwnerForm"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent
                          >
                            <v-row justify="center">
                              <v-col cols="12" class="pb-0 px-0">
                                <v-row>
                                  <v-col v-if="errorMsg" cols="12" class="py-0">
                                    <v-alert type="error" text border="left">
                                      {{ errorMsg }}
                                    </v-alert>
                                  </v-col>

                                  <v-col cols="12" class="py-0">
                                    <v-select
                                      v-model="ownerEdit.owner"
                                      :items="companyUsers"
                                      :rules="requiredSelectRule"
                                      outlined
                                      label="New Owner"
                                      placeholder="Select New Owner"
                                      hide-details="auto"
                                      autocomplete
                                      return-object
                                      :disabled="$apollo.loading"
                                      dense
                                    >
                                    <template v-slot:selection="data">
                                      {{data.item.firstName + ' ' + data.item.lastName}}
                                      </template>
                                      <template v-slot:item="data">
                                        {{data.item.firstName + ' ' + data.item.lastName}}
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <div class="flex-grow-1"></div>
                          <v-btn
                            color="accent darken-1"
                            text
                            rounded
                            @click="close"
                            >Cancel</v-btn
                          >
                          <v-btn color="primary" small rounded @click="changeOwner"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:extension>
        <v-tabs
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          background-color="transparent"
          align-with-title
          center-active
          show-arrows
          color="info"
        >
          <!-- <v-tabs-slider color="info" /> -->

          <v-tab
            :to="{
              name: 'BudgetCosting',
              params: { budgetID: $route.params.budgetID }
            }"
            class="text-capitalize"
            >Cost Breakdown</v-tab
          >

          <!-- <v-tab
            :to="{
              name: 'BudgetDetail',
              params: { budgetID: $route.params.budgetID }
            }"
            class="text-capitalize"
            exact
            >General</v-tab
          > -->
          <v-tab
            :to="{
              name: 'BudgetDescription',
              params: { budgetID: $route.params.budgetID }
            }"
            class="text-capitalize"
            >Details</v-tab
          >

          <v-tab
            :to="{
              name: 'BudgetProjects',
              params: { budgetID: $route.params.budgetID }
            }"
            class="text-capitalize"
            >Projects</v-tab
          >

          <v-tab
            :to="{
              name: 'BudgetPurchaseOrders',
              params: { budgetID: $route.params.budgetID }
            }"
            class="text-capitalize"
            >Purchase Orders</v-tab
          >

          <v-tab
            :to="{
              name: 'BudgetAuth',
              params: { budgetID: $route.params.budgetID }
            }"
            class="text-capitalize"
            >Authorisation</v-tab
          >
          <!-- <v-tab class="text-capitalize">Purchase Orders</v-tab> -->
          <v-tab
            :to="{
              name: 'BudgetAssets',
              params: { budgetID: $route.params.budgetID }
            }"
            class="text-capitalize"
            >Assets</v-tab
          >

          <v-tab
            v-if="budget && budget.status === 'draft'"
            :to="{
              name: 'BudgetSettings',
              params: { budgetID: $route.params.budgetID }
            }"
            class="text-capitalize"
            >Settings</v-tab
          >
        </v-tabs>

        <slot name="controls" />
      </template>
    </v-toolbar>
  </div>
</template>

<script>
import Avatar from 'vue-avatar'
import BUDGET from '@/graphql/Budget.gql'
import SUBMIT_BUDGET from '@/graphql/BudgetSubmit.gql'
import SUBMIT_BUDGET_ADJUSTMENT from '@/graphql/BudgetAdjustmentSubmit.gql'
import REVIEW_BUDGET from '@/graphql/BudgetReview.gql'
import UPDATE_BUDGET_OWNER from '@/graphql/BudgetOwnerUpdate.gql'
import USER_LIST from '@/graphql/Users.gql'

export default {
  name: 'FinanceBudgetDetailHeader',
  components: { Avatar },
  props: {
    budget: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    valid: false,
    processing: false,
    approvalStates: ['approved', 'pendingchanges', 'rejected'],
    reviewItem: {
      status: 'approved',
      comments: null
    },
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    dialog: false,
    budgetAdjustmentDialog: false,
    approvalDialog: false,
    updateOwnerDialog: false,
    errorMsg: '',
    ownerEdit: {
      id: null,
      ownerID: null
    }
  }),
  apollo: {
    budget: {
      query: BUDGET,
      variables() {
        return {
          id: this.$route.params.budgetID
        }
      }
      // update (data) {
      //   return data.budget
      // }
    },
    users: USER_LIST
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    currentApprover() {
      var that = this
      if (
        that.budget &&
        (!that.budget.auditFlows || that.budget.auditFlows.length === 0)
      ) {
        return null
      } else {
        if (
          that.budget &&
          that.budget.status === 'review' &&
          that.currentUser
        ) {
          var approver = null

          that._.find(that.budget.auditFlows, function(item) {
            if (item.status === 'review') {
              approver = that._.find(item.auditors, function(auditor) {
                return auditor.status === 'review'
              })
            }
          })

          if (
            approver &&
            approver.userID === that.currentUser.id &&
            that.currentUser
          ) {
            return approver
          }
        }
        return null
      }
    },
    companyUsers() {
      return this._.filter(this.users, (item) => {
        return !item.isSuperAdmin
      })
    }
  },
  methods: {
    close() {
      this.updateOwnerDialog = false
      this.ownerEdit = {
        id: null,
        ownerID: null
      }
      this.$refs.changeOwnerForm.reset()
    },
    stateColor: item => {
      if (item === 'pendingchanges' || item === 'pendingadjustment') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review' || item === 'reviewadjustment') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-2 upperCaseSpacing'
      }
    },
    submit() {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: SUBMIT_BUDGET,
          variables: {
            id: this.budget.id
          },
          update: (store, { data: { submitBudget } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: BUDGET,
              variables: {
                id: this.budget.id
              }
            })

            // update the budget with the submitBudget return
            data.budget = submitBudget

            store.writeQuery({ query: BUDGET, data })
          }
        })
        .then(() => {
          this.dialog = false
          this.processing = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Budget Submitted'
          })
        })
        .catch(err => {
          this.processing = false
          if (err.graphQLErrors[0].message === 'budget already submitted') {
            this.dialog = false
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Budget already submitted'
            })
          } else {
            this.dialog = false
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'An error occured try again later'
            })
          }
        })
    },
    submitAdjustment() {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SUBMIT_BUDGET_ADJUSTMENT,
          variables: {
            id: this.budget.id
          },
          update: (store, { data: { submitBudgetAdjustment } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: BUDGET,
              variables: {
                id: this.budget.id
              }
            })

            // update the budget with the submitBudget return
            data.budget = submitBudgetAdjustment

            store.writeQuery({ query: BUDGET, data })
          }
        })
        .then(() => {
          this.budgetAdjustmentDialog = false
          this.processing = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Budget Adjustment Submitted'
          })
        })
        .catch(err => {
          this.processing = false
          if (err.graphQLErrors[0].message === 'budget already submitted') {
            this.budgetAdjustmentDialog = false
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Budget already submitted'
            })
          } else {
            this.budgetAdjustmentDialog = false
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'An error occured try again later'
            })
          }
        })
    },
    review() {
      var data = {
        budgetID: this.budget.id,
        status: this.reviewItem.status,
        comments: this.reviewItem.comments
      }
      if (this.$refs.reviewForm.validate()) {
        this.$apollo
          .mutate({
            mutation: REVIEW_BUDGET,
            variables: {
              input: data
            }
            // update: (store, { data: { reviewBudget } }) => {
            //   // Read the data from our cache for this query.
            //   const data = store.readQuery({
            //     query: BUDGET,
            //     variables: {
            //       id: this.budget.id
            //     }
            //   })

            //   // update the budget with the reviewBudget return
            //   data.budget = reviewBudget

            //   // Write our data back to the cache.
            //   store.writeQuery({ query: BUDGET, data })
            // }
          })
          .then(() => {
            this.dialog = false
            this.approvalDialog = false

            this.reviewItem = {
              status: 'approved',
              comments: null
            }

            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Budget Review Submitted'
            })
          })
          .catch(err => {
            this.dialog = false
            this.approvalDialog = false
            this.rejectionDialog = false
            if (
              err.graphQLErrors &&
              err.graphQLErrors[0].message === 'budget already reviewed'
            ) {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Budget already reviewed'
              })
            } else {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Something went wrong. Please try again later'
              })
            }
          })
      }
    },
    changeOwner() {
      if (this.$refs.changeOwnerForm.validate()) {
        let data = {
          id: this.$route.params.budgetID,
          ownerID: this.ownerEdit.owner?.id
        }

        this.$apollo
          .mutate({
            mutation: UPDATE_BUDGET_OWNER,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message:
                'Ownership Transfered'
            })
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message:
                'A general server error occured. Please try again later'
            })
          })
        }
    }
  }
}
</script>

<style lang="scss">
#edit-review-comments {
  border: 1px solid #ccc;
  border-radius: 5px;
  // height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
