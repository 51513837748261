<template>
  <div class="page-wrap">
    <finance-project-detail-header v-if="project" :project="project">
      <template v-slot:controls>
        <v-btn
          v-if="
            project &&
              project.owner &&
              currentUser &&
              (project.status === 'draft' ||
                project.status === 'pendingchanges') &&
              currentUser.id === project.owner.id
          "
          fab
          small
          absolute
          right
          bottom
          color="info darken-1"
          class="mt-5"
          @click="newCategory()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </finance-project-detail-header>
    <div class="main-page-column pt-0">
      <project-edit
        :open="dialog"
        :edited-item="editedItem"
        :edit-mode="edit"
        @close-edit-project-dialog="close"
      ></project-edit>
      <project-category-dialog
        :open="categoryDialog"
        :edited-item="editedCategoryItem"
        :edit-mode="edit"
        @close-project-category-dialog="close"
      ></project-category-dialog>
      <project-line-dialog
        v-if="project"
        :open="lineItemDialog"
        :edited-item="editedLineItem"
        :edit-mode="edit"
        :project="project"
        :budget-line="budgetLine"
        @close-project-line-dialog="close"
      ></project-line-dialog>
      <v-dialog v-model="detailDialog" persistent fullscreen scrollable>
        <v-card>
          <v-card-title class="mb-3">
            <v-row>
              <v-col>
                <div class="headline mb-5">Details</div>
                <div>
                  <div class="caption grey--text">
                    Line
                  </div>
                  <div class="text-body-1">{{ selectedLine.title }}</div>
                </div>
              </v-col>
              <v-col cols="1">
                <v-row justify="end">
                  <v-btn icon @click="closeDetailDialog"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <div class="text-h6 font-weight-regular mb-3 mt-5">
              Purchase Orders
            </div>
            <div>
              <purchase-order-all-list-items
                :data="purchaseOrders"
                :loading="processing"
              ></purchase-order-all-list-items>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="removeLineItemDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Item</v-card-title
          >
          <v-card-text>
            <p>Are you sure your want to delete this item.</p>
            <p>All deletions are final and can't be reversed.</p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="accent darken-1"
              text
              @click="removeLineItemDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="error" text @click="removeProjectLine">Remove</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="removeCategoryItemDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Category</v-card-title
          >
          <v-card-text>
            <p>Are you sure your want to delete this category.</p>
            <p>This will also delete all the line items.</p>
            <p>All deletions are final and can't be reversed.</p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="accent darken-1"
              text
              @click="removeCategoryItemDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="error" text @click="removeProjectCategory"
              >Remove</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="scroll-container pt-0">
        <!-- Summary boxes -->
        <v-row v-if="project">
          <v-col cols="12" class="pt-0">
            <v-row justify="space-between">
              <v-col
                v-if="
                  project &&
                    budgetLine &&
                    project.status !== 'approved' &&
                    project.status !== 'rejected' &&
                    project.status !== 'closed'
                "
                sm="12"
                md=""
                class="pb-0"
              >
                <v-card flat>
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Budget Balance</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0 pl-4">
                    <v-row no-gutters>
                      <v-col
                        class="pl-4 my-3"
                        :class="[
                          budgetLineBalance >= 0
                            ? 'success--text font-weight-bold'
                            : 'error--text font-weight-bold'
                        ]"
                        ><div class="text-h5">
                          {{
                            (budgetLineBalance / 100)
                              | currency(
                                budgetLine.pricing.currency.symbol,
                                2,
                                {
                                  spaceBetweenAmountAndSymbol: true
                                }
                              )
                          }}
                        </div></v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="" class="pb-0">
                <v-card flat>
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Forecast</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0 pl-4">
                    <v-row no-gutters>
                      <v-col v-if="project" class="pl-4 my-3">
                        <div class="text-h5">
                          {{
                            (project.forecastAmount / 100)
                              | currency(project.currency.symbol, 2, {
                                spaceBetweenAmountAndSymbol: true
                              })
                          }}
                        </div></v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="" class="pb-0">
                <v-card flat>
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >In Progress</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row no-gutters>
                      <v-col v-if="project" class="pl-4 my-3 text-h5">{{
                        (project.pendingAmount / 100)
                          | currency(project.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="" class="pb-0">
                <v-card flat>
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Actual</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row no-gutters>
                      <v-col v-if="project" class="pl-4 my-3 text-h5">{{
                        (project.actualAmount / 100)
                          | currency(project.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="" class="pb-0">
                <v-card flat>
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Balance</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row no-gutters>
                      <v-col
                        v-if="project"
                        class="pl-4 my-3 text-h5 info--text darken-1"
                        >{{
                          (project.balanceAmount / 100)
                            | currency(project.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- Categories -->
        <v-row v-if="project && projectCategories" justify="center">
          <v-col cols="12">
            <v-expansion-panels multiple flat>
              <v-expansion-panel
                v-for="item in projectCategories"
                :key="item.id"
                class="mb-1"
              >
                <v-expansion-panel-header v-slot="{ open }">
                  <v-row no-gutters>
                    <v-col cols="12" md="3">
                      <v-row no-gutters>
                        <div
                          class="text-body-1 text-truncate primary--text font-weight-medium pt-1 pb-1"
                        >
                          {{ item.name }}
                        </div>

                        <v-btn
                          v-if="
                            open &&
                              project &&
                              project.owner &&
                              currentUser &&
                              (project.status === 'draft' ||
                                project.status === 'pendingchanges') &&
                              currentUser.id === project.owner.id
                          "
                          left
                          small
                          icon
                          class="ml-3"
                          color="info"
                          @click.stop="editCategory(item)"
                        >
                          <v-icon small>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="
                            open &&
                              project &&
                              project.owner &&
                              currentUser &&
                              (project.status === 'draft' ||
                                project.status === 'pendingchanges') &&
                              currentUser.id === project.owner.id
                          "
                          small
                          text
                          rounded
                          color="grey"
                          class="text-lowercase"
                          @click.stop="openRemoveProjectCategoryDialog(item)"
                        >
                          delete
                        </v-btn>
                      </v-row>

                      <v-row
                        v-if="
                          open &&
                            project &&
                            project.owner &&
                            currentUser &&
                            (project.status === 'draft' ||
                              project.status === 'pendingchanges') &&
                            currentUser.id === project.owner.id
                        "
                        class="mt-5"
                      >
                        <v-btn
                          small
                          rounded
                          color="info"
                          class="mr-3 text-capitalize"
                          @click.stop="newProjectLine(item)"
                        >
                          <v-icon left>mdi-playlist-plus</v-icon>Add Line Item
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="8" class="text--secondary pt-1">
                      <v-fade-transition leave-absolute>
                        <v-row v-if="open">
                          <v-col class="py-0">
                            <v-card flat>
                              <v-card-text>
                                <div
                                  class="caption text-uppercase grey--text font-weight-medium"
                                >
                                  Forecast
                                </div>
                                <div class="text-h6 font-weight-medium">
                                  {{
                                    lineForecastAmount(item.lineItems || [])
                                      | currency(project.currency.symbol, 2, {
                                        spaceBetweenAmountAndSymbol: true
                                      })
                                  }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col class="py-0 hidden-sm-only">
                            <v-card flat>
                              <v-card-text>
                                <div
                                  class="caption text-uppercase grey--text font-weight-medium"
                                >
                                  In Progress
                                </div>
                                <div class="text-h6 font-weight-medium">
                                  {{
                                    linePendingAmount(item.lineItems || [])
                                      | currency(project.currency.symbol, 2, {
                                        spaceBetweenAmountAndSymbol: true
                                      })
                                  }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col class="py-0 hidden-sm-only">
                            <v-card flat>
                              <v-card-text>
                                <div
                                  class="caption text-uppercase grey--text font-weight-medium"
                                >
                                  Actual
                                </div>
                                <div class="text-h6 font-weight-medium">
                                  {{
                                    lineActualAmount(item.lineItems || [])
                                      | currency(project.currency.symbol, 2, {
                                        spaceBetweenAmountAndSymbol: true
                                      })
                                  }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col class="py-0">
                            <v-card flat>
                              <v-card-text>
                                <div
                                  class="caption text-uppercase grey--text font-weight-medium"
                                >
                                  Balance
                                </div>
                                <div class="text-h6 font-weight-medium">
                                  {{
                                    lineBalanceAmount(item.lineItems || [])
                                      | currency(project.currency.symbol, 2, {
                                        spaceBetweenAmountAndSymbol: true
                                      })
                                  }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="1" class="py-0 hidden-sm-only"
                            >&nbsp;</v-col
                          >
                        </v-row>
                        <v-row v-else class="pt-1">
                          <v-col class="py-0"
                            >Forecast:
                            {{
                              lineForecastAmount(item.lineItems || [])
                                | currency(project.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}</v-col
                          >
                          <v-col class="py-0 hidden-sm-only"
                            >In Progress:
                            {{
                              linePendingAmount(item.lineItems || [])
                                | currency(project.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}</v-col
                          >
                          <v-col class="py-0 hidden-sm-only"
                            >Actual:
                            {{
                              lineActualAmount(item.lineItems || [])
                                | currency(project.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}</v-col
                          >
                          <v-col class="py-0"
                            >Balance:
                            <span class="info--text">
                              {{
                                lineBalanceAmount(item.lineItems || [])
                                  | currency(project.currency.symbol, 2, {
                                    spaceBetweenAmountAndSymbol: true
                                  })
                              }}</span
                            ></v-col
                          >
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    v-if="item.lineItems && item.lineItems.length > 0"
                    class="pb-0 caption"
                    style="border-bottom: 1px solid #ccc"
                  >
                    <v-col
                      cols="3"
                      class="text-uppercase grey--text font-weight-medium pb-0 pt-6"
                      >Item</v-col
                    >
                    <v-col cols="8">
                      <v-row>
                        <v-col
                          class="text-uppercase grey--text font-weight-medium pb-0"
                          >Forecast</v-col
                        >
                        <v-col
                          class="text-uppercase grey--text font-weight-medium pb-0"
                          >In Progress</v-col
                        >
                        <v-col
                          class="text-uppercase grey--text font-weight-medium pb-0"
                          >Actual</v-col
                        >
                        <v-col
                          class="text-uppercase grey--text font-weight-medium pb-0"
                          >Balance</v-col
                        >
                        <v-col cols="1" class="pb-0 hidden-sm-only">
                          &nbsp;
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="1"></v-col>
                  </v-row>
                  <v-row
                    v-for="(line, idx) in item.lineItems"
                    :key="idx"
                    class="price-line-item"
                  >
                    <v-col cols="3" class="pb-0">{{ line.title }}</v-col>
                    <v-col cols="8" class="py-0">
                      <v-row>
                        <v-col>{{
                          (line.forecastAmount / 100)
                            | currency(line.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col>
                        <v-col>{{
                          (line.pendingAmount / 100)
                            | currency(line.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col>
                        <v-col>{{
                          (line.actualAmount / 100)
                            | currency(line.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col>
                        <v-col>{{
                          (line.balanceAmount / 100)
                            | currency(line.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col>
                        <v-col cols="1" class="hidden-sm-only">
                          <v-btn
                            v-show="line.forecastAmount !== line.balanceAmount"
                            text
                            small
                            color="info"
                            class="text-lowercase"
                            :loading="processing"
                            @click="viewLineItemDetails(line)"
                            >details</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1" class="pt-2 pb-0">
                      <v-row
                        v-if="
                          project &&
                            project.owner &&
                            currentUser &&
                            (project.status === 'draft' ||
                              project.status === 'pendingchanges') &&
                            currentUser.id === project.owner.id
                        "
                        justify="end"
                      >
                        <v-btn
                          fab
                          x-small
                          elevation="0"
                          class="mr-2"
                          @click="editProjectLine(line)"
                        >
                          <v-icon x-small color="info"
                            >mdi-pencil-outline</v-icon
                          >
                        </v-btn>
                        <v-btn
                          fab
                          x-small
                          elevation="0"
                          @click="openRemoveProjectLineDialog(line)"
                        >
                          <v-icon x-small color="error"
                            >mdi-trash-can-outline</v-icon
                          >
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import PROJECT from '@/graphql/Project.gql'
import PROJECT_CATEGORIES from '@/graphql/ProjectCategories.gql'
import BUDGET_LINE from '@/graphql/BudgetLine.gql'
import REMOVE_PROJECT_LINE from '@/graphql/ProjectLineRemove.gql'
import REMOVE_CATEGORY from '@/graphql/ProjectCategoryRemove.gql'
import PURCHASE_ORDERS_BY_LINE from '@/graphql/PurchaseOrdersByProjectLineID.gql'
import ProjectEdit from '@/modules/finance/components/core/ProjectEdit.vue'
import ProjectCategoryDialog from '@/modules/finance/components/core/ProjectCategoryDialog.vue'
import ProjectLineDialog from '@/modules/finance/components/core/ProjectLineDialog.vue'
import FinanceProjectDetailHeader from '@/modules/finance/components/core/FinanceProjectDetailHeader.vue'
import PurchaseOrderAllListItems from '@/modules/finance/components/core/PurchaseOrderAllListItems.vue'

export default {
  name: 'ProjectCosting',
  components: {
    FinanceProjectDetailHeader,
    ProjectEdit,
    ProjectCategoryDialog,
    ProjectLineDialog,
    PurchaseOrderAllListItems
  },
  data: () => ({
    // project: null,
    // budgetLine: null,
    // projectCategories: [],
    defaultItem: {
      title: '',
      description: '',
      department: {},
      financialYear: {},
      pricing: {}
    },
    editedItem: {
      title: '',
      description: '',
      department: {},
      financialYear: {},
      pricing: {}
    },
    defaultCategoryItem: {
      id: '',
      projectID: '',
      name: '',
      description: ''
    },
    editedCategoryItem: {
      id: '',
      projectID: '',
      name: '',
      description: ''
    },
    defaultLineItem: {
      projectID: '',
      projectCategoryID: '',
      title: '',
      description: '',
      pricing: {
        displayAmount: 0
      }
    },
    editedLineItem: {
      projectID: '',
      projectCategoryID: '',
      title: '',
      description: '',
      pricing: {
        displayAmount: 0
      }
    },
    deleteItem: '',
    edit: false,
    dialog: false,
    categoryDialog: false,
    lineItemDialog: false,
    removeLineItemDialog: false,
    removeCategoryItemDialog: false,
    detailDialog: false,
    purchaseOrders: [],
    selectedLine: {},
    processing: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    lineItemBalance() {
      var that = this
      var amount = 0
      if (this.projectCategories && this.projectCategories.length > 0) {
        that._.forEach(that.projectCategories, function(cat) {
          that._.forEach(cat.lineItems, function(line) {
            amount = amount + line.forecastAmount
          })
        })
      }
      return amount
    },
    budgetLineBalance() {
      if (this.budgetLine && this.budgetLine.pricing && this.project) {
        return this.budgetLine.pricing.balanceAmount
      }
      return 0
    }
  },
  apollo: {
    project: {
      query: PROJECT,
      variables() {
        return {
          id: this.$route.params.projectID
        }
      },
      fetchPolicy: 'network-only'
      // update (data) {
      //   return data.project
      // }
    },
    projectCategories: {
      query: PROJECT_CATEGORIES,
      variables() {
        return {
          projectID: this.$route.params.projectID
        }
      },
      fetchPolicy: 'network-only'
      // update (data) {
      //   return data.project
      // }
    },
    budgetLine: {
      query: BUDGET_LINE,
      variables() {
        return {
          id: this.project.budgetLine.id
        }
      },
      fetchPolicy: 'network-only',
      // Disable the query
      skip() {
        return !this.project
      }
    }
  },
  created() {
    // this.$apollo
    //   .query({
    //     query: PROJECT,
    //     variables: {
    //       id: this.$route.params.projectID
    //     }
    //   })
    //   .then(res => {
    //     this.project = res.data.project
    //     this.$apollo
    //       .query({
    //         query: PROJECT_CATEGORIES,
    //         variables: {
    //           projectID: res.data.project.id
    //         }
    //       })
    //       .then(res => {
    //         this.projectCategories = res.data.projectCategories
    //       })
    //     this.$apollo
    //       .query({
    //         query: BUDGET_LINE,
    //         variables: {
    //           id: res.data.project.budgetLine.id
    //         }
    //       })
    //       .then(res => {
    //         this.budgetLine = res.data.budgetLine
    //       })
    //   })
  },
  methods: {
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.editedCategoryItem = this._.cloneDeep(this.defaultCategoryItem)
      this.editedLineItem = this._.cloneDeep(this.defaultLineItem)
      this.dialog = false
      this.categoryDialog = false
      this.lineItemDialog = false
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })
      }
      this.$apollo.queries.project.refetch()
      this.$apollo.queries.projectCategories.refetch()
      this.$apollo.queries.budgetLine.refetch()
    },
    closeDetailDialog() {
      this.processing = false
      this.detailDialog = false
      this.purchaseOrders = []
      this.selectedLine = {}
    },
    editItem() {
      this.edit = true
      this.editedItem = this._.cloneDeep(this.project)
      this.dialog = true
    },
    editCategory(item) {
      this.edit = true
      this.editedCategoryItem = this._.cloneDeep(item)
      this.categoryDialog = true
    },
    newCategory() {
      this.edit = false
      this.defaultCategoryItem.projectID = this.project.id
      this.editedCategoryItem = this._.cloneDeep(this.defaultCategoryItem)
      this.categoryDialog = true
    },
    editProjectLine(item) {
      this.edit = true
      item.displayAmount = item.displayAmount || 0
      this.editedLineItem = this._.cloneDeep(item)
      this.editedLineItem.forecastAmount =
        this.editedLineItem.forecastAmount / 100
      this.lineItemDialog = true
    },
    newProjectLine(item) {
      this.edit = false
      this.defaultLineItem.currency = this.project.currency
      this.editedLineItem = this._.cloneDeep(this.defaultLineItem)
      this.editedLineItem.projectID = this.project.id
      this.editedLineItem.projectCategoryID = item.id

      this.lineItemDialog = true
    },
    openRemoveProjectCategoryDialog(item) {
      this.deleteItem = item
      this.removeCategoryItemDialog = true
    },
    removeProjectCategory() {
      var that = this
      this.$apollo
        .mutate({
          mutation: REMOVE_CATEGORY,
          variables: {
            id: that.deleteItem.id
          }
        })
        .then(() => {
          this.removeCategoryItemDialog = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Item Removed'
          })
          this.deleteItem = ''
          this.$apollo.queries.project.refetch()
          this.$apollo.queries.projectCategories.refetch()
          this.$apollo.queries.budgetLine.refetch()
        })
    },
    openRemoveProjectLineDialog(item) {
      this.deleteItem = item
      this.removeLineItemDialog = true
    },
    removeProjectLine() {
      this.$apollo
        .mutate({
          mutation: REMOVE_PROJECT_LINE,
          variables: {
            id: this.deleteItem.id
          }
        })
        .then(() => {
          this.removeLineItemDialog = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Item Removed'
          })
          this.deleteItem = ''
          this.$apollo.queries.project.refetch()
          this.$apollo.queries.projectCategories.refetch()
          this.$apollo.queries.budgetLine.refetch()
        })
    },
    lineForecastAmount(items) {
      var that = this
      let amount = 0
      if (items && items.length > 0) {
        that._.forEach(items, function(item) {
          let cents
          if (
            typeof item.forecastAmount === 'number' &&
            isFinite(item.forecastAmount)
          ) {
            cents = item.forecastAmount / 100
          }
          if (
            typeof item.forecastAmount === 'string' ||
            item.forecastAmount instanceof String
          ) {
            cents =
              parseFloat(
                item.forecastAmount.replace(/\./g, '').replace(/,/g, '.')
              ) * 1000
          }

          amount = amount + cents
        })
      }

      return amount
    },
    linePendingAmount(items) {
      let amount = 0
      if (items && items.length > 0) {
        this._.forEach(items, function(item) {
          amount = amount + item.pendingAmount / 100
        })
      }

      return amount
    },
    lineActualAmount(items) {
      let amount = 0
      if (items && items.length > 0) {
        this._.forEach(items, function(item) {
          amount = amount + item.actualAmount / 100
        })
      }

      return amount
    },
    lineBalanceAmount(items) {
      let amount = 0
      if (items && items.length > 0) {
        this._.forEach(items, function(item) {
          amount = amount + item.balanceAmount / 100
        })
      }

      return amount
    },
    viewLineItemDetails(item) {
      this.selectedLine = item

      this.processing = true

      this.$apollo
        .query({
          query: PURCHASE_ORDERS_BY_LINE,
          variables: {
            projectLineID: item.id
          },
          fetchPolicy: 'network-only'
        })
        .then(result => {
          this.purchaseOrders = result.data?.purchaseOrdersByProjectLineID || []
          this.processing = false
          this.detailDialog = true
        })
        .catch(() => {
          this.processing = false
          this.errorMsg =
            'A general server error occured. Please try again later'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  // border: 2px solid rgba(0, 0, 0, 0.12); // var(--v-secondary-lighten3);
  border: 2px solid transparent;
  border-radius: 4px !important;

  &:hover {
    // border-color: var(--v-primary-lighten3);
    border-color: rgba(0, 0, 0, 0.12);
  }

  &:before {
    box-shadow: none !important;
  }

  &:after {
    border: none !important;
  }
}

.v-expansion-panel--active {
  // border-color: var(--v-primary-lighten3);
  border-color: rgba(0, 0, 0, 0.12);

  &:hover {
    // border-color: var(--v-primary-lighten3);
    border-color: rgba(0, 0, 0, 0.12);
  }
}

.success-border {
  border: 2px solid var(--v-success-base) !important;
}

.error-border {
  border: 2px solid var(--v-error-base) !important;
}

.price-line-item {
  border-bottom: 1px solid #ccc;
  &:last-of-type {
    border-bottom: none;
  }
}
</style>
