var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[(_vm.project)?_c('finance-project-detail-header',{attrs:{"project":_vm.project},scopedSlots:_vm._u([{key:"controls",fn:function(){return [(
          _vm.project &&
            _vm.project.owner &&
            _vm.currentUser &&
            (_vm.project.status === 'draft' ||
              _vm.project.status === 'pendingchanges') &&
            _vm.currentUser.id === _vm.project.owner.id
        )?_c('v-btn',{staticClass:"mt-5",attrs:{"fab":"","small":"","absolute":"","right":"","bottom":"","color":"info darken-1"},on:{"click":function($event){return _vm.newCategory()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]},proxy:true}],null,false,2619088909)}):_vm._e(),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"hide-details":"","flat":"","clearable":"","background-color":"#fff","placeholder":"Search...","prepend-inner-icon":"mdi-magnify","autocomplete":"off","disabled":!_vm.purchaseOrders},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('purchase-order-list-items',{attrs:{"data":_vm.purchaseOrders.items,"loading":_vm.$apollo.queries.purchaseOrders.loading,"item-count":_vm.itemCount},on:{"set-options":_vm.setOptions}})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }