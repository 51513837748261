<template>
  <div style="height: 100%;">
    <main-navigation nav-drawar-color="light-theme" light>
      <template v-slot:nav-items>
        <v-list-item
          :to="{ name: 'FinanceDashboard' }"
          exact
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="activeScope('finance_budgets')"
          :to="{ name: 'BudgetList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-briefcase-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Budgets</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="activeScope('finance_projects')"
          :to="{ name: 'ProjectList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-chart-timeline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Projects</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="activeScope('suppliers')"
          :to="{ name: 'SupplierList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Suppliers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="activeScope('purchase_orders')"
          :to="{ name: 'PurchaseOrderList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-cash-usd-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Purchase Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('finance_reports')"
          :to="{ name: 'FinanceReportList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-file-chart-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('finance_settings')"
          :to="{ name: 'FinanceSettingsGeneral' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </main-navigation>

    <!-- <div class="app-layout"> -->
    <router-view />
    <!-- </div> -->
  </div>
</template>

<script>
import MainNavigation from '@/components/core/MainNavigation.vue'

export default {
  name: 'FinanceLayout',
  components: {
    MainNavigation
  },
  data: () => ({
    drawer: true
  }),
  apollo: {},
  computed: {
    userScopes() {
      return this.$store.getters.userScopes
    },
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  created() {
    this.setTheme()
  },
  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = '#002447'
      this.$vuetify.theme.themes.light.secondary = '#53d3d1'
      this.$vuetify.theme.themes.light.accent = '#fbeceb'
      this.$vuetify.theme.themes.light.error = '#FF5252'
      this.$vuetify.theme.themes.light.info = '#2196F3'
      this.$vuetify.theme.themes.light.success = '#4CAF50'
      this.$vuetify.theme.themes.light.warning = '#FFC107'
    },
    activeScope(scope) {
      var idx = this._.findIndex(this.userScopes, function(item) {
        return item.name === scope && item.accessLevel > 0
      })
      if (idx > -1) {
        return true
      }

      return false
    }
  }
}
</script>

<style></style>
