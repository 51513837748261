<template>
  <div class="app-layout">
    <router-view />
  </div>
</template>

<script>
import PURCHASEORDER from '@/graphql/PurchaseOrder.gql'

export default {
  name: 'PurchaseOrderDetailLayout',
  components: {},
  data: () => ({}),
  apollo: {
    purchaseOrder: {
      query: PURCHASEORDER,
      variables() {
        return {
          id: this.$route.params.purchaseOrderID
        }
      }
      // update (data) {
      //   return data.purchaseOrder
      // }
    }
  },
  created() {
    // this.$store.dispatch('customer/GET_CUSTOMER', this.$route.params.customerId
    // this.$apollo
    //   .query({
    //     query: PROPOSAL,
    //     variables: {
    //       id: this.$route.params.purchaseOrderId
    //     },
    //     update (data) {
    //       return data.purchaseOrder
    //     }
    //   })
    //   .then(result => {
    //     if (!result) {
    //       this.purchaseOrder = this.purchaseOrderModel
    //     }
    //     this.purchaseOrder = result.data.purchaseOrder
    //   })
    //   .catch(err => {
    //     // Error
    //     console.error(err)
    //     // We restore the initial user input
    //     this.purchaseOrder = this.purchaseOrderModel
    //   })
  },
  methods: {
    editItem() {},

    deleteItem() {},

    stateColor: item => {
      if (item === 'OPEN') {
        return 'success'
      } else if (item === 'CLOSED') {
        return 'error'
      }
    }
  }
}
</script>

<style scoped>
.nice {
  color: var(--v-primary-base);
}

.page-wrap {
  --page-header-height: 112px;
  /* --page-wrap-offset: -20px; */
}
</style>
