<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header pt-0 px-1">
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <supplier-edit
            :open="dialog"
            :edited-item="editedItem"
            :edit-mode="edit"
            :payment-terms="paymentTerms"
            :currencies="currencies"
            @close-edit-supplier-dialog="close"
            @save-supplier="saveSupplier"
          ></supplier-edit>
          <v-row no-gutters>
            <v-col cols="4" md="3" lg="2">
              <v-row no-gutters>
                <v-btn color="primary" rounded @click="newItem">
                  add supplier
                </v-btn>
              </v-row>
            </v-col>

            <!-- <v-col
              xs="4"
              sm="3"
              md="3"
              lg="3"
              xl="2"
              class="d-none d-xs-flex d-sm-flex"
            >
              <v-select
                v-model="stateFilterValue"
                :items="stateList"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                placeholder="Status"
                class="ml-3"
              ></v-select>
            </v-col> -->
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-card flat>
            <v-card-title class="pt-0">
              <v-text-field
                v-model="search"
                hide-details
                flat
                clearable
                background-color="#fff"
                placeholder="Search..."
                prepend-inner-icon="mdi-magnify"
                autocomplete="off"
              ></v-text-field>
            </v-card-title>
            <supplier-list-items
              :data="suppliers.items"
              :loading="$apollo.queries.suppliers.loading"
              :item-count="itemCount"
              @set-options="setOptions"
            ></supplier-list-items>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SupplierListItems from '@/modules/finance/components/core/SupplierListItems.vue'
import SupplierEdit from '@/modules/finance/components/core/SupplierEdit.vue'
import SUPPLIERS from '@/graphql/Suppliers.gql'
import CREATE_SUPPLIER from '@/graphql/SupplierCreate.gql'
import UPDATE_SUPPLIER from '@/graphql/SupplierUpdate.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'SupplierList',
  components: {
    SupplierListItems,
    SupplierEdit
  },
  props: {},
  data: () => ({
    search: null,
    suppliers: { items: [] },

    // Filters
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    stateFilterValue: null,
    stateList: ['active', 'suspended', 'inactive'],

    defaultItem: {
      id: null,
      supplierCode: null,
      name: null,
      email: null,
      phone: null,
      paymentTerm: null,
      website: null,
      address: {
        id: '',
        email: '',
        phone: '',
        line1: '',
        line2: '',
        line3: '',
        city: '',
        postCode: ''
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null,
      status: 'active'
    },
    editedItem: {
      id: null,
      supplierCode: null,
      name: null,
      email: null,
      phone: null,
      paymentTerm: null,
      website: null,
      address: {
        id: '',
        email: '',
        phone: '',
        line1: '',
        line2: '',
        line3: '',
        city: '',
        postCode: ''
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null,
      status: 'active'
    },
    edit: false,
    dialog: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  apollo: {
    suppliers: {
      query: SUPPLIERS,
      variables() {
        return {
          filters: {
            search: this.search,
            status: this.stateFilterValue,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      result(result) {
        this.page = result.data.suppliers.page
        this.itemsPerPage = result.data.suppliers.pageSize
        this.itemCount = result.data.suppliers.total
      }
    },
    paymentTerms: PAYMENT_TERM_LIST,
    currencies: CURRENCY_LIST
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    setOptions(e) {
      this.options = e
    },
    close(success, supplier) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })

        this.$router.push({
          name: 'SupplierDetail',
          params: { supplierID: supplier.id }
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    editItem(id) {
      this.edit = true
      this.editedItem = this._.cloneDeep(id)
      this.dialog = true
    },
    stateFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.stateFilterValue) {
        return true
      }

      return value === this.stateFilterValue
    },
    saveSupplier(data) {
      if (this.editMode) {
        this.$apollo
          .mutate({
            mutation: UPDATE_SUPPLIER,
            variables: {
              input: data
            }
          })
          .then(result => {
            this.close(true, result.data.updateSupplier)
          })
      } else {
        // New Item

        this.$apollo
          .mutate({
            mutation: CREATE_SUPPLIER,
            variables: {
              input: data
            }
            // update: (store, { data: { createSupplier } }) => {
            //   // Read the data from our cache for this query.
            //   const data = store.readQuery({
            //     query: SUPPLIERS
            //   })
            //   // Add our requirement from the mutation to the end
            //   data.suppliers.unshift(createSupplier)
            //   // Write our data back to the cache.
            //   store.writeQuery({ query: SUPPLIERS, data })
            // }
          })
          .then(result => {
            this.close(true, result.data.createSupplier)
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'A general server error occured. Please try again later'
            })

            return this.close(false)
          })
      }
    }
  }
}
</script>

<style></style>
