<template>
  <div class="page-wrap">
    <purchase-order-detail-header :purchase-order="purchaseOrder">
      <template v-slot:controls>
        <upload-button
          v-if="
            purchaseOrder &&
              purchaseOrder.owner &&
              currentUser &&
              (purchaseOrder.status === 'draft' ||
                purchaseOrder.status === 'pendingchanges') &&
              (currentUser.isSuperAdmin ||
                currentUser.id === purchaseOrder.owner.id ||
                currentUser.userRole === 'director' ||
                currentUser.userRole === 'seniormanager' ||
                currentUser.userRole === 'purchaseOrdermanager')
          "
          btn-color="info darken-1"
          @selected-files="uploadFile"
        ></upload-button>
      </template>
    </purchase-order-detail-header>
    <div class="main-page-column pt-0">
      <v-dialog v-model="removeFileDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove File</v-card-title
          >
          <v-card-text>
            <p>
              This will delete the purchase order file and can't be undone!
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="grey darken-1"
              text
              :disabled="processing"
              @click="closeRemoveFileDialog"
              >Cancel</v-btn
            >
            <v-btn :loading="processing" color="error" text @click="removeFile"
              >Remove</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="page-wrap sub-header">
        <div
          v-if="purchaseOrderFiles && purchaseOrderFiles.length > 0"
          class="page-header"
        >
          <v-row>
            <v-col xs="8" sm="6" md="5" lg="4" xl="4">
              <v-text-field
                v-model="search"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                placeholder="Search..."
                prepend-inner-icon="mdi-magnify"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </div>
        <div class="main-page-column pt-0">
          <div class="scroll-container pt-0">
            <v-data-iterator
              :items="purchaseOrderFiles"
              :search="search"
              hide-default-footer
            >
              <template v-slot:default="props">
                <v-row>
                  <v-card
                    v-for="item in props.items"
                    :key="item.id"
                    outlined
                    class="file-card ma-3"
                  >
                    <!-- <v-btn :href="item.url" link target="_blank"> -->
                    <v-row
                      v-if="item.fileType === 'image'"
                      justify="center"
                      class="pt-3"
                    >
                      <v-img
                        contain
                        :src="item.url"
                        aspect-ratio="1"
                        max-width="150"
                        height="75"
                      ></v-img>
                    </v-row>

                    <v-row v-else justify="center" class="pa-5">
                      <v-icon large>mdi-file-outline</v-icon>
                    </v-row>
                    <v-row no-gutters align="end" class="mt-3 pb-3 px-2">
                      <v-col
                        cols="12"
                        class="text-center text-truncate grey--text text--darken-1"
                      >
                        {{ item.name }}
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-center pt-3 text-caption grey--text"
                      >
                        {{ item.createdAt | moment('DD MMM YYYY') }}
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-row justify="center">
                        <v-btn
                          v-if="authLevel && authLevel === 2"
                          text
                          x-small
                          elevation="0"
                          color="primary"
                          class="ml-2"
                          :href="item.url"
                          link
                          rounded
                          target="_blank"
                          >view</v-btn
                        >
                        <v-btn
                          v-if="authLevel && authLevel === 2"
                          elevation="0"
                          x-small
                          text
                          rounded
                          color="grey lighten-1"
                          class="ml-3"
                          @click.prevent="openRemoveFileDialog(item.id)"
                          >remove</v-btn
                        >
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-alert border="left" text color="info" class="mt-5">
                  <div v-if="purchaseOrder && purchaseOrder.status === 'draft'">
                    Upload any files to support your request
                  </div>
                  <div v-else>No files where added to the request</div>
                </v-alert>
              </template>
            </v-data-iterator>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseOrderDetailHeader from '@/modules/finance/components/core/PurchaseOrderDetailHeader.vue'
import PURCHASE_ORDER from '@/graphql/PurchaseOrder.gql'
import UPLOAD_PURCHASE_ORDER_FILE from '@/graphql/PurchaseOrderFileUpload.gql'
import PURCHASE_ORDER_FILES from '@/graphql/PurchaseOrderFiles.gql'
import FILE_REMOVE from '@/graphql/PurchaseOrderFileRemove.gql'
import UploadButton from '@/components/core/UploadButton.vue'

export default {
  name: 'PurchaseOrderAssets',
  components: {
    PurchaseOrderDetailHeader,
    UploadButton
  },
  data: () => ({
    search: '',
    file: null,
    removeFileDialog: false,
    removeableItem: null,
    processing: false,
    errorMsg: ''
  }),
  computed: {
    authLevel() {
      return this.$store.getters.authLevel('purchase_orders')
    },
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  apollo: {
    purchaseOrder: {
      query: PURCHASE_ORDER,
      variables() {
        return {
          id: this.$route.params.purchaseOrderID
        }
      },
      fetchPolicy: 'cache-only'
    },
    purchaseOrderFiles: {
      query: PURCHASE_ORDER_FILES,
      variables() {
        return {
          purchaseOrderID: this.$route.params.purchaseOrderID
        }
      }
    }
  },
  methods: {
    stateColor: item => {
      if (item === 'open') {
        return 'info'
      } else if (item === 'rejected') {
        return 'error'
      } else if (item === 'approved') {
        return 'success'
      }
    },
    onUploadFile(target) {
      // if (!target.validity.valid) return
      this.files.push(target)
    },
    uploadFile(files) {
      // Create file object
      var that = this
      this._.forEach(files, function(file) {
        var data = {
          purchaseOrderID: that.$route.params.purchaseOrderID,
          file: file
        }

        that.$apollo
          .mutate({
            mutation: UPLOAD_PURCHASE_ORDER_FILE,
            variables: {
              input: data
            },
            update: (store, { data: { uploadPurchaseOrderFile } }) => {
              const data = store.readQuery({
                query: PURCHASE_ORDER_FILES,
                variables: {
                  purchaseOrderID: uploadPurchaseOrderFile.purchaseOrderID
                }
              })
              data.purchaseOrderFiles.push(uploadPurchaseOrderFile)
              store.writeQuery({ query: PURCHASE_ORDER_FILES, data })
            }
          })
          .then(() => {
            that.$store.commit('showSnackbar', {
              active: true,
              message: 'File uploaded'
            })
          })
      })
    },
    openRemoveFileDialog(id) {
      this.removeableItem = id
      this.removeFileDialog = true
    },
    closeRemoveFileDialog() {
      this.removeFileDialog = false
      this.removeableItem = null
      this.processing = false
    },
    removeFile() {
      var vm = this
      this.processing = true
      if (this.removeableItem) {
        this.$apollo
          .mutate({
            mutation: FILE_REMOVE,
            variables: {
              id: this.removeableItem
            },
            update: (store, { data: { removePurchaseOrderFile } }) => {
              const data = store.readQuery({
                query: PURCHASE_ORDER_FILES,
                variables: {
                  purchaseOrderID: vm.$route.params.purchaseOrderID
                }
              })

              let idx = vm._.findIndex(
                data.purchaseOrderFiles,
                item => item.id === removePurchaseOrderFile
              )

              data.purchaseOrderFiles.splice(idx, 1)

              // Remove the item

              store.writeQuery({
                query: PURCHASE_ORDER_FILES,
                variables: {
                  purchaseOrderID: vm.$route.params.purchaseOrderID
                },
                data
              })
            }
          })
          .then(() => {
            this.closeRemoveFileDialog()

            this.$store.commit('showSnackbar', {
              active: true,
              message: 'File Removed'
            })
          })
          .catch(() => {
            this.closeRemoveFileDialog()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Something went wrong'
            })
          })
      } else {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Nothing to remove'
        })
        this.processing = false
        this.removeFileDialog = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrap {
  --page-header-height: 110px;
  /* --page-wrap-offset: -20px; */
  &.sub-header {
    --page-header-height: 50px;
  }
}

.file-card {
  width: 200px;
  font-size: 0.8em;
}
</style>
