<template>
  <v-row justify="center" class="supplier-list">
    <v-col cols="12" class="py-0">
      <v-data-table
        :headers="headers"
        :items="purchaseOrders"
        :loading="loading"
        :hide-default-footer="!purchaseOrders || purchaseOrders.length === 0"
        :footer-props="null"
        :mobile-breakpoint="null"
        :items-per-page="-1"
        class="plain-table"
        @click:row="navToDetail"
      >
        <template v-slot:item.poNumber="{ item }">
          <div class="text-truncate">{{ item.poNumber }}</div>
        </template>
        <template v-slot:item.title="{ item }">
          <div class="py-2 pl-3">
            <div class="text-truncate body-1 primary--text">
              {{ item.title }}
            </div>
            <div class="text-truncate body-2 grey--text text--darken-1 mt-1">
              {{ item.supplier.name }}
            </div>
          </div>
        </template>

        <template v-slot:item.pricing.forecastAmount="{ item }">
          <div class="text-truncate">
            {{
              (item.pricing.forecastAmount / 100)
                | currency(item.pricing.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>

        <template v-slot:item.pricing.actualAmount="{ item }">
          <div
            v-if="item.status === 'approved' || item.status === 'closed'"
            class="text-truncate"
          >
            {{
              (item.pricing.actualAmount / 100)
                | currency(item.pricing.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>

        <template v-slot:item.pricing.balanceAmount="{ item }">
          <div
            v-if="item.status === 'approved' || item.status === 'closed'"
            class="text-truncate info--text"
          >
            {{
              (item.pricing.balanceAmount / 100)
                | currency(item.pricing.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="stateColor(item.status)" x-small>{{
            item.status
          }}</v-chip>
        </template>
        <template v-slot:item.serviceDate="{ item }"
          >{{ item.serviceDate | moment('DD/MM/YYYY') }}
        </template>
        <template v-slot:item.invoiceDate="{ item }"
          >{{ item.invoiceDate | moment('DD/MM/YYYY') }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PurchaseOrderListItems',
  components: {},
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    edit: false
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Title',
          align: 'left',
          sortable: false,
          value: 'title'
        },
        {
          text: 'PO Number',
          align: 'left',
          sortable: false,
          value: 'poNumber'
        },
        {
          text: 'Requested',
          align: 'left',
          value: 'pricing.forecastAmount',
          sortable: false
        },
        {
          text: 'Paid',
          align: 'left',
          value: 'pricing.actualAmount',
          sortable: false
        },
        {
          text: 'Balance',
          align: 'left',
          value: 'pricing.balanceAmount',
          sortable: false
        },
        {
          text: 'Service Date',
          align: 'left',
          value: 'serviceDate',
          sortable: false
        },
        {
          text: 'Payment Date',
          align: 'left',
          value: 'invoiceDate',
          sortable: false
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
          sortable: false
        }
      ]
    },
    purchaseOrders() {
      return this.data
    }
  },
  watch: {},
  methods: {
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    },
    navToDetail(item) {
      this.$router.push({
        name: 'PurchaseOrderDetail',
        params: { purchaseOrderID: item.id }
      })
    }
  }
}
</script>

<style lang="scss">
.plain-table .v-input__slot {
  background: transparent !important;
}

.plain-table.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content) {
  cursor: pointer !important;
}
</style>
