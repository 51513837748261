<template>
  <div class="page-wrap">
    <finance-project-detail-header
      :project="project"
    ></finance-project-detail-header>

    <div class="main-page-column pt-0">
      <project-edit
        :open="dialog"
        :edited-item="editedItem"
        :edit-mode="editDetails"
        @close-edit-project-dialog="close"
      ></project-edit>
      <div class="scroll-container pt-0">
        <v-row>
          <v-col v-if="project" cols="12" class="pt-0">
            <!-- <v-row>
              <v-col cols="12" md="3" class="pb-0">
                <v-card flat>
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Forecast</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0 pl-4">
                    <v-row no-gutters>
                      <v-col v-if="project" class="pl-4 my-3">
                        <div class="text-h5">
                          {{
                            (project.forecastAmount / 100)
                              | currency(project.currency.symbol, 2, {
                                spaceBetweenAmountAndSymbol: true
                              })
                          }}
                        </div></v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" class="pb-0">
                <v-card flat>
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Pending</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row no-gutters>
                      <v-col v-if="project" class="pl-4 my-3 text-h5">{{
                        (project.pendingAmount / 100)
                          | currency(project.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" class="pb-0">
                <v-card flat>
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Actual</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row no-gutters>
                      <v-col v-if="project" class="pl-4 my-3 text-h5">{{
                        (project.actualAmount / 100)
                          | currency(project.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" class="pb-0">
                <v-card flat>
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Balance</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row no-gutters>
                      <v-col
                        v-if="project"
                        class="pl-4 my-3 text-h5 info--text darken-1"
                        >{{
                          (project.balanceAmount / 100)
                            | currency(project.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row> -->
            <v-row class="mb-5">
              <v-col cols="12" md="6">
                <v-card v-if="!$apollo.loading" flat class="mx-auto">
                  <v-card-title class="mb-0 primary--text"
                    ><span class="font-weight-regular">Details</span
                    ><v-spacer></v-spacer
                    ><v-btn
                      v-if="
                        project &&
                          project.owner &&
                          currentUser &&
                          (project.status === 'draft' ||
                            project.status === 'pendingchanges') &&
                          (currentUser.isSuperAdmin ||
                            currentUser.id === project.owner.id ||
                            currentUser.userRole === 'director' ||
                            currentUser.userRole === 'seniormanager' ||
                            currentUser.userRole === 'projectmanager') &&
                          !edit &&
                          !editDetails
                      "
                      color="info"
                      outlined
                      x-small
                      rounded
                      @click="editItem(project)"
                      >edit
                    </v-btn></v-card-title
                  >
                  <v-card-text>
                    <!-- <div class="mb-2">Owner</div> -->
                    <v-row>
                      <v-col class="py-0">
                        <v-row no-gutters justify="start">
                          <avatar
                            :username="
                              project.owner.firstName +
                                ' ' +
                                project.owner.lastName
                            "
                            :size="25"
                            color="white"
                            style="user-select: none;"
                            class="mr-3"
                          ></avatar>
                          <span>{{
                            project.owner.firstName +
                              ' ' +
                              project.owner.lastName
                          }}</span>
                        </v-row>
                      </v-col>

                      <v-col class="py-0">
                        <div class="subtitle-2">
                          <span class="caption">Department</span>
                        </div>
                        <div class=" font-weight-bold">
                          {{ project.department.name }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <div class="mb-2">Budget Allocation</div>
                    <v-row no-gutters justify="start">
                      <v-col cols="12" class="py-0">
                        <v-row>
                          <v-col
                            ><div class="caption">
                              Category
                            </div>
                            <div class="font-weight-bold">
                              {{ budgetCategory.name }}
                            </div></v-col
                          >
                          <v-col>
                            <div class="caption">Line Item</div>
                            <div class="font-weight-bold">
                              {{ budgetLine.title }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        v-if="
                          currentUser.userRole === 'director' ||
                            currentUser.userRole === 'seniormanager' ||
                            currentUser.userRole === 'projectmanager'
                        "
                        class="py-0"
                        cols="12"
                      >
                        <v-row>
                          <v-btn
                            color="info"
                            small
                            text
                            rounded
                            :to="{
                              name: 'BudgetCosting',
                              params: { budgetID: budgetCategory.budgetID }
                            }"
                            class="text-lowercase"
                            >view budget</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <!-- <div class="mb-2">Planning</div> -->
                    <v-row>
                      <v-col>
                        <div class="caption">From</div>
                        <b>{{ project.startDate | moment('DD MMMM YYYY') }}</b>
                      </v-col>
                      <v-col>
                        <div class="caption">Until</div>
                        <b>{{ project.endDate | moment('DD MMMM YYYY') }}</b>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card flat>
                  <v-card-title class="primary--text">
                    <v-row align="center">
                      <v-col class="py-0 font-weight-regular"
                        >Description</v-col
                      >
                      <v-col class="py-0">
                        <v-row no-gutters align="center" justify="end">
                          <v-btn
                            v-if="
                              project &&
                                project.owner &&
                                currentUser &&
                                (project.status === 'draft' ||
                                  project.status === 'pendingchanges') &&
                                (currentUser.isSuperAdmin ||
                                  currentUser.id === project.owner.id ||
                                  currentUser.userRole === 'director' ||
                                  currentUser.userRole === 'seniormanager' ||
                                  currentUser.userRole === 'projectmanager') &&
                                !edit &&
                                !editDetails
                            "
                            color="info"
                            outlined
                            x-small
                            rounded
                            @click="editDescription"
                          >
                            edit
                          </v-btn>
                          <div v-if="edit">
                            <v-btn
                              small
                              text
                              rounded
                              color="grey"
                              @click="cancelDescription"
                              >cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              small
                              rounded
                              @click="saveDescription"
                              >save</v-btn
                            >
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text v-if="!edit && !project.description">
                    <v-row>
                      <v-col cols="12">
                        <v-alert color="info" border="left" text class="mb-0"
                          ><div v-if="project && project.status === 'draft'">
                            Please add a description that best describes your
                            project
                          </div>
                          <div v-else>
                            No description was added to the request
                          </div></v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text
                    v-if="
                      !edit &&
                        project.description &&
                        project.description.length > 0
                    "
                    v-html="project.description"
                  ></v-card-text>
                  <div v-if="edit">
                    <vue-editor
                      v-if="project"
                      id="edit-project-description"
                      v-model="description"
                      :editor-options="editorConfig"
                      placeholder="A short description, which describes what the project is about."
                    />
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceProjectDetailHeader from '@/modules/finance/components/core/FinanceProjectDetailHeader.vue'
import Avatar from 'vue-avatar'
import PROJECT from '@/graphql/Project.gql'
import UPDATE_PROJECT from '@/graphql/ProjectUpdate.gql'
import BUDGET_LINE from '@/graphql/BudgetLine.gql'
import BUDGET_CATEGORY from '@/graphql/BudgetCategory.gql'
import ProjectEdit from '@/modules/finance/components/core/ProjectEdit.vue'

export default {
  name: 'ProjectDetail',
  components: {
    FinanceProjectDetailHeader,
    Avatar,
    ProjectEdit
  },
  data: () => ({
    edit: false,
    description: null,
    processing: false,
    editorConfig: {
      // theme: 'bubble',
      modules: {
        toolbar: false
      }
    },
    defaultItem: {
      name: '',
      description: '',
      departmentID: '',
      budgetID: '',
      budgetLineID: '',
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString()
    },
    editedItem: {
      name: '',
      description: '',
      departmentID: '',
      budgetID: '',
      budgetLineID: '',
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString()
    },
    editDetails: false,
    dialog: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  apollo: {
    project: {
      query: PROJECT,
      variables() {
        return {
          id: this.$route.params.projectID
        }
      },
      fetchPolicy: 'cache-only',
      // Disable the query until projectID is available
      skip() {
        return !this.$route.params.projectID
      }
    },
    budgetLine: {
      query: BUDGET_LINE,
      variables() {
        return {
          id: this.project.budgetLine.id
        }
      },
      // Disable the query
      skip() {
        return !this.project
      }
    },
    budgetCategory: {
      query: BUDGET_CATEGORY,
      variables() {
        return {
          id: this.budgetLine.budgetCategoryID
        }
      },
      // Disable the query
      skip() {
        return !this.budgetLine
      }
    }
  },
  methods: {
    stateColor: item => {
      if (item === 'open') {
        return 'info'
      } else if (item === 'rejected') {
        return 'error'
      } else if (item === 'approved') {
        return 'success'
      }
    },
    editDescription() {
      if (this.project.description) {
        this.description = this._.cloneDeep(this.project.description)
      }
      this.edit = true
    },
    cancelDescription() {
      this.description = null
      this.edit = false
    },
    saveDescription() {
      let data = {
        id: this.project.id,
        name: this.project.name,
        description: this.description,
        departmentID: this.project.department.id,
        budgetID: this.project.budget.id,
        budgetLineID: this.project.budgetLine.id,
        currencyID: this.project.currency.id,
        startDate: this.project.startDate,
        endDate: this.project.endDate
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_PROJECT,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.cancelDescription()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'changes saved'
          })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again later.'
          })
        })
    },
    close(success) {
      this.editDetails = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })
      }
    },
    editItem() {
      this.editDetails = true
      this.editedItem = this._.cloneDeep(this.project)
      this.editedItem.budgetID = this.project.budget.id
      this.editedItem.budgetLineID = this.project.budgetLine.id
      this.editedItem.departmentID = this.project.department.id
      this.dialog = true
    }
  }
}
</script>

<style></style>
