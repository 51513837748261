<template>
  <div class="page-header">
    <v-toolbar
      v-if="!$apollo.loading && project"
      class="mb-0 b-radius"
      extended
      flat
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-row justify="start" align="start">
              <div xs6>
                <h2 class="mb-0 info--text">
                  {{ project.name }}
                </h2>
                <div>
                  <v-chip
                    :color="stateColor(project.status)"
                    :text-color="
                      project.status === 'draft' ? 'grey darken-3' : 'white'
                    "
                    x-small
                    pill
                    >{{ project.status }}</v-chip
                  >
                </div>
              </div>
              <v-spacer></v-spacer>
              <div xs6>
                <v-dialog
                  v-if="
                    project &&
                      project.owner &&
                      currentUser &&
                      project.status === 'approved' &&
                      currentUser.id === project.owner.id
                  "
                  v-model="dialog"
                  persistent
                  max-width="500"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      small
                      submit
                      rounded
                      class="ml-3 mt-2 text-capitalize"
                      v-on="on"
                    >
                      <v-icon small left
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >close project
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline primary--text mb-3"
                      >Close Project</v-card-title
                    >
                    <v-card-text class="pb-0">
                      <p v-if="project && project.pendingAmount <= 1000">
                        This will close the project and release any remaining
                        balance back to the budget.
                      </p>
                      <v-alert v-else type="info" color="info" text>
                        <p>
                          The project can only be closed once the in progress
                          balance falls below
                          {{
                            10
                              | currency(project.currency.symbol, 2, {
                                spaceBetweenAmountAndSymbol: true
                              })
                          }}.
                        </p>
                        <p>
                          Once all orders have been paid, you will be able to
                          close the Project.
                        </p>
                      </v-alert>
                    </v-card-text>
                    <v-card-actions class="pa-5">
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="accent darken-1"
                        text
                        rounded
                        small
                        :disabled="processing"
                        @click="dialog = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="project && project.pendingAmount <= 1000"
                        color="primary darken-1"
                        rounded
                        small
                        :loading="processing"
                        @click="closeProject"
                        >Submit</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                  v-if="
                    project &&
                      project.owner &&
                      currentUser &&
                      (project.status === 'draft' ||
                        project.status === 'pendingchanges') &&
                      (currentUser.id === project.owner.id ||
                        currentUser.isSuperAdmin)
                  "
                  v-model="dialog"
                  persistent
                  max-width="500"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      small
                      submit
                      rounded
                      class="ml-3 mt-2 text-capitalize"
                      v-on="on"
                    >
                      <v-icon small left>mdi-send-outline</v-icon
                      ><span
                        v-if="project && project.status === 'pendingchanges'"
                        >re-</span
                      >submit
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline primary--text mb-3"
                      >Submit for Approval</v-card-title
                    >
                    <v-card-text class="pb-0">
                      <p>This will submit your project for approval.</p>
                      <p>
                        Once submitted you will not be able to make any changes.
                      </p>
                    </v-card-text>
                    <v-card-actions class="pa-5">
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="accent darken-1"
                        text
                        small
                        rounded
                        :disabled="processing"
                        @click="dialog = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary darken-1"
                        rounded
                        small
                        :disabled="processing"
                        @click="submit"
                        >Submit</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="
                        project &&
                        currentUser &&
                        (
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.isAdmin ||
                          currentUser.isSuperAdmin
                        )
                      "
                      v-model="updateOwnerDialog"
                      persistent
                      max-width="450"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          small
                          submit
                          rounded
                          class="mt-2 ml-3"
                          v-on="on"
                        >
                          change owner
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="headline primary--text mb-3"
                          >Transfer Ownership</v-card-title
                        >
                        <v-card-text>
                          <v-form
                            ref="changeOwnerForm"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent
                          >
                            <v-row justify="center">
                              <v-col cols="12" class="pb-0 px-0">
                                <v-row>
                                  <v-col v-if="errorMsg" cols="12" class="py-0">
                                    <v-alert type="error" text border="left">
                                      {{ errorMsg }}
                                    </v-alert>
                                  </v-col>

                                  <v-col cols="12" class="py-0">
                                    <v-select
                                      v-model="ownerEdit.owner"
                                      :items="companyUsers"
                                      :rules="requiredSelectRule"
                                      outlined
                                      label="New Owner"
                                      placeholder="Select New Owner"
                                      hide-details="auto"
                                      autocomplete
                                      return-object
                                      :disabled="$apollo.loading"
                                      dense
                                    >
                                    <template v-slot:selection="data">
                                      {{data.item.firstName + ' ' + data.item.lastName}}
                                      </template>
                                      <template v-slot:item="data">
                                        {{data.item.firstName + ' ' + data.item.lastName}}
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <div class="flex-grow-1"></div>
                          <v-btn
                            color="accent darken-1"
                            text
                            rounded
                            @click="close"
                            >Cancel</v-btn
                          >
                          <v-btn color="primary" small rounded @click="changeOwner"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                </v-dialog>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:extension>
        <v-tabs
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          background-color="transparent"
          align-with-title
          center-active
          show-arrows
          color="info"
        >
          <v-tab
            :to="{
              name: 'ProjectCosting',
              params: { projectID: $route.params.projectID }
            }"
            class="text-capitalize"
            >Cost Breakdown</v-tab
          >

          <v-tab
            :to="{
              name: 'ProjectDetails',
              params: { projectID: $route.params.projectID }
            }"
            class="text-capitalize"
            exact
            >Details</v-tab
          >

          <v-tab
            :to="{
              name: 'ProjectPurchaseOrders',
              params: { projectID: $route.params.projectID }
            }"
            class="text-capitalize"
            >Purchase Orders</v-tab
          >

          <v-tab
            :to="{
              name: 'ProjectAuth',
              params: { projectID: $route.params.projectID }
            }"
            class="text-capitalize"
            >Authorisation</v-tab
          >
          <!-- <v-tab class="text-capitalize">Purchase Orders</v-tab> -->
          <v-tab
            :to="{
              name: 'ProjectAssets',
              params: { projectID: $route.params.projectID }
            }"
            class="text-capitalize"
            >Assets</v-tab
          >

          <v-tab
            v-if="project && project.status === 'draft'"
            :to="{
              name: 'ProjectSettings',
              params: { projectID: $route.params.projectID }
            }"
            class="text-capitalize"
            >Settings</v-tab
          >
        </v-tabs>

        <slot name="controls"></slot>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
import PROJECT from '@/graphql/Project.gql'
import CLOSE_PROJECT from '@/graphql/ProjectClose.gql'
import SUBMIT_PROJECT from '@/graphql/ProjectSubmit.gql'
import REVIEW_PROJECT from '@/graphql/ProjectReview.gql'
import UPDATE_PROJECT_OWNER from '@/graphql/ProjectOwnerUpdate.gql'
import USER_LIST from '@/graphql/Users.gql'

export default {
  name: 'FinanceProjectDetailHeader',
  props: { project: { type: Object, default: () => {} } },
  apollo: {
    project: {
      query: PROJECT,
      variables() {
        return {
          id: this.$route.params.projectID
        }
      }
      // update (data) {
      //   return data.project
      // }
    },
    users: USER_LIST
  },
  data: () => ({
    valid: false,
    processing: false,
    approvalStates: ['approved', 'pendingchanges', 'rejected'],
    reviewItem: {
      status: 'approved',
      comments: null
    },
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    dialog: false,
    approvalDialog: false,
    updateOwnerDialog: false,
    errorMsg: '',
    ownerEdit: {
      id: null,
      ownerID: null
    }
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    currentApprover() {
      var that = this
      if (
        that.project &&
        (!that.project.auditFlows || that.project.auditFlows.length === 0)
      ) {
        return null
      } else {
        if (
          that.project &&
          that.project.status === 'review' &&
          that.currentUser
        ) {
          var approver = null

          that._.find(that.project.auditFlows, function(item) {
            if (item.status === 'review') {
              approver = that._.find(item.auditors, function(auditor) {
                return auditor.status === 'review'
              })
            }
          })

          if (
            approver &&
            approver.userID === that.currentUser.id &&
            that.currentUser
          ) {
            return approver
          }
        }
        return null
      }
    },
    companyUsers() {
      return this._.filter(this.users, (item) => {
        return !item.isSuperAdmin
      })
    }
  },
  methods: {
    close() {
      this.updateOwnerDialog = false
      this.ownerEdit = {
        id: null,
        ownerID: null
      }
      this.$refs.changeOwnerForm.reset()
    },
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-2 upperCaseSpacing'
      }
    },
    closeProject() {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: CLOSE_PROJECT,
          variables: {
            id: this.project.id
          }
        })
        .then(() => {
          this.$apollo.queries.project.refetch()
          this.processing = false
          this.dialog = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Project Closed'
          })
        })
        .catch(() => {
          // if (err.graphQLErrors[0].message === 'project already submitted') {
          this.dialog = false
          this.processing = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong please try again later'
          })
          // }
        })
    },
    submit() {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SUBMIT_PROJECT,
          variables: {
            id: this.project.id
          },
          update: (store, { data: { submitProject } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: PROJECT,
              variables: {
                id: this.project.id
              }
            })

            // update the project with the submitProject return
            data.project = submitProject

            // const newData = (data.project = submitProject)
            // Add our requirement from the mutation to the end
            // data.projects.push(submitProject)
            // Write our data back to the cache.
            store.writeQuery({ query: PROJECT, data })
          }
        })
        .then(() => {
          this.dialog = false
          this.processing = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Project Submitted'
          })
        })
        .catch(err => {
          this.processing = false
          if (err.graphQLErrors[0].message === 'project already submitted') {
            this.dialog = false
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Project already submitted'
            })
          } else {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Something went wrong. Please try again later'
            })
          }
        })
    },
    review() {
      var data = {
        projectID: this.project.id,
        status: this.reviewItem.status,
        comments: this.reviewItem.comments
      }
      if (this.$refs.reviewForm.validate()) {
        this.processing = true

        this.$apollo
          .mutate({
            mutation: REVIEW_PROJECT,
            variables: {
              input: data
            }
            // update: (store, { data: { reviewProject } }) => {
            //   // Read the data from our cache for this query.
            //   const data = store.readQuery({
            //     query: PROJECT,
            //     variables: {
            //       id: this.project.id
            //     }
            //   })

            //   // update the project with the submitProject return
            //   data.project = reviewProject

            //   // const newData = (data.project = submitProject)
            //   // Add our requirement from the mutation to the end
            //   // data.projects.push(submitProject)
            //   // Write our data back to the cache.
            //   store.writeQuery({ query: PROJECT, data })
            // }
          })
          .then(() => {
            this.dialog = false
            this.approvalDialog = false
            this.processing = false

            this.reviewItem = {
              status: 'approved',
              comments: null
            }

            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Project Review Submitted'
            })
          })
          .catch(err => {
            this.dialog = false
            this.approvalDialog = false
            this.rejectionDialog = false
            this.processing = false
            if (
              err.graphQLErrors &&
              err.graphQLErrors[0].message === 'project already reviewed'
            ) {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Project already reviewsd'
              })
            } else {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Something went wrong. Please try again later'
              })
            }
          })
      }
    },
    changeOwner() {
      if (this.$refs.changeOwnerForm.validate()) {
        let data = {
          id: this.$route.params.projectID,
          ownerID: this.ownerEdit.owner?.id
        }

        this.$apollo
          .mutate({
            mutation: UPDATE_PROJECT_OWNER,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message:
                'Ownership Transfered'
            })
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message:
                'A general server error occured. Please try again later'
            })
          })
        }
    }
  }
}
</script>

<style></style>
