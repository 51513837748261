var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('finance-budget-detail-header',{attrs:{"budget":_vm.budget}}),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.budget)?_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8","xl":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"primary--text"},[_c('v-row',[_c('v-col',{staticClass:"py-0 font-weight-regular"},[_vm._v("Description")]),_c('v-col',{staticClass:"py-0"},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[(
                            _vm.budget &&
                              (_vm.budget.status === 'draft' ||
                                _vm.budget.status === 'pendingchanges') &&
                              !_vm.edit
                          )?_c('v-btn',{attrs:{"color":"grey","outlined":"","x-small":"","rounded":""},on:{"click":_vm.editItem}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-pencil-outline")]),_vm._v("edit ")],1):_vm._e(),(_vm.edit)?_c('div',[_c('v-btn',{attrs:{"small":"","text":"","rounded":"","color":"grey"},on:{"click":_vm.cancel}},[_vm._v("cancel")]),_c('v-btn',{attrs:{"color":"primary","small":"","rounded":""},on:{"click":_vm.save}},[_vm._v("save")])],1):_vm._e()],1)],1)],1)],1),(!_vm.edit && !_vm.budget.description)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"color":"info","border":"left","text":""}},[(_vm.budget && _vm.budget.status === 'draft')?_c('div',[_vm._v(" Please add a description that best describes your budget ")]):_c('div',[_vm._v(" No description was added to the request ")])])],1)],1)],1):_vm._e(),(
                    !_vm.edit && _vm.budget.description && _vm.budget.description !== ''
                  )?_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.budget.description)}}):_vm._e(),(_vm.edit)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[(_vm.budget)?_c('vue-editor',{attrs:{"id":"edit-budget-description","editor-options":_vm.editorConfig,"placeholder":"A short description, which describes what the budget is about."},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}):_vm._e()],1)])],1)],1):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }