<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header pt-0 px-1">
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <purchase-order-edit
            v-if="dialog"
            :open="dialog"
            :edited-item="editedItem"
            :edit-mode="edit"
            :financial-years="financialYears"
            @close-edit-purchase-order-dialog="close"
          ></purchase-order-edit>
          <v-row no-gutters>
            <v-col cols="4" md="4" lg="3">
              <v-btn color="primary" rounded @click="newItem">
                Add Purchase Order
              </v-btn>
            </v-col>

            <v-col
              xs="4"
              sm="3"
              md="3"
              lg="3"
              xl="2"
              class="d-none d-xs-flex d-sm-flex"
            >
              <v-select
                v-model="stateFilterValue"
                :items="stateList"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                placeholder="Status"
                class="ml-3"
              ></v-select>
            </v-col>
            <v-col
              v-if="
                currentUser &&
                  (currentUser.isSuperAdmin ||
                    currentUser.userRole === 'director' ||
                    currentUser.userRole === 'seniormanager' ||
                    currentUser.userRole === 'projectmanager')
              "
              xs="4"
              sm="3"
              md="3"
              lg="3"
              xl="2"
              class="d-none d-xs-flex d-sm-flex"
            >
              <v-select
                v-model="departmentID"
                :items="departments"
                hide-details
                outlined
                flat
                dense
                clearable
                item-value="id"
                item-text="name"
                background-color="#fff"
                placeholder="Department"
                class="ml-3"
              ></v-select>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-card flat>
            <v-card-title class="pt-0">
              <v-text-field
                v-model="search"
                hide-details
                flat
                clearable
                background-color="#fff"
                placeholder="Search..."
                prepend-inner-icon="mdi-magnify"
                autocomplete="off"
              ></v-text-field>
            </v-card-title>

            <purchase-order-list-items
              :data="purchaseOrders.items"
              :loading="$apollo.queries.purchaseOrders.loading"
              :item-count="itemCount"
              @set-options="setOptions"
            ></purchase-order-list-items>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseOrderListItems from '@/modules/finance/components/core/PurchaseOrderListItems.vue'
import PurchaseOrderEdit from '@/modules/finance/components/core/PurchaseOrderEdit.vue'
import PURCHASE_ORDERS from '@/graphql/PurchaseOrders.gql'
import FINANCIAL_YEAR_LIST from '@/graphql/FinancialYears.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import DEPARTMENTS from '@/graphql/Departments.gql'

export default {
  name: 'PurchaseOrderList',
  components: {
    PurchaseOrderListItems,
    PurchaseOrderEdit
  },
  data: () => ({
    search: '',

    // Filters
    purchaseOrders: { items: [] },
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    stateFilterValue: null,
    stateList: ['draft', 'approved', 'rejected', 'review', 'closed'],
    departmentID: null,

    defaultItem: {
      title: '',
      description: '',
      supplier: null,
      pricing: {},
      priceLine: null,
      status: 'draft',
      serviceDate: new Date().toISOString(),
      invoiceDate: new Date().toISOString()
    },
    editedItem: {
      title: '',
      description: '',
      supplier: null,
      pricing: {},
      priceLine: null,
      status: 'draft',
      serviceDate: new Date().toISOString(),
      invoiceDate: new Date().toISOString()
    },
    edit: false,
    dialog: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  apollo: {
    purchaseOrders: {
      query: PURCHASE_ORDERS,
      variables() {
        return {
          filters: {
            search: this.search,
            departmentID: this.departmentID,
            status: this.stateFilterValue,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      result(result) {
        this.page = result.data.purchaseOrders.page
        this.itemsPerPage = result.data.purchaseOrders.pageSize
        this.itemCount = result.data.purchaseOrders.total
      }
    },
    currencies: { query: CURRENCY_LIST },
    financialYears: { query: FINANCIAL_YEAR_LIST },
    departments: { query: DEPARTMENTS }
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    setOptions(e) {
      this.options = e
    },
    close(success, id) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })

        this.$router.push({
          name: 'PurchaseOrderDetail',
          params: { purchaseOrderID: id }
        })
      }
    },
    newItem() {
      this.edit = false
      var defaultCurrency = this._.find(this.currencies, function(item) {
        return item.defaultCurrency === true
      })
      this.defaultItem.pricing.displayCurrency = defaultCurrency
      this.defaultItem.departmentID = this.currentUser.department.id
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    stateFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.stateFilterValue) {
        return true
      }

      return value === this.stateFilterValue
    }
  }
}
</script>

<style></style>
