<template>
  <div class="page-wrap">
    <v-dialog
      v-model="removeDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="350"
    >
      <v-card flat>
        <v-card-title class="headline mb-3">Delete Payment Term</v-card-title>
        <v-card-text>
          <p>
            Are you sure you want to delete
            <b v-if="deleteObject">{{ deleteObject.name }}</b> payment term?
          </p>
          <p>This change can't be undone!</p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey lighten-1" text @click="removeDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="deleteItem">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <payment-term-edit
      :edited-item="editedItem"
      :open="dialog"
      :edit-mode="edit"
      @close-payment-term-edit-dialog="close"
    ></payment-term-edit>
    <FinanceSettingsToolbar>
      <template v-slot:controls>
        <v-btn
          v-if="authLevel === 2"
          fab
          small
          color="primary"
          @click="newItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </FinanceSettingsToolbar>
    <div class="main-page-column pt-0" style="width: 100%">
      <div class="page-wrap">
        <div class="main-page-column pt-0">
          <div class="scroll-container pt-0">
            <v-row justify="center">
              <v-col xs="12" sm="12" md="10" lg="10" xl="8">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Days</th>
                        <th class="text-left">Default</th>
                        <th class="text-left">Last Update</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.days }}</td>
                        <td>
                          <v-icon v-if="item.defaultPaymentTerm"
                            >mdi-check</v-icon
                          >
                        </td>
                        <td>{{ item.updatedAt | moment('DD/MM/YY') }}</td>
                        <td class="text-right">
                          <v-btn
                            v-if="authLevel === 2"
                            x-small
                            text
                            rounded
                            color="info"
                            @click="editItem(item)"
                          >
                            edit
                          </v-btn>
                          <v-btn
                            v-if="authLevel === 2"
                            x-small
                            text
                            color="error lighten-2"
                            class="ml-2"
                            :disabled="item.defaultPaymentTerm"
                            @click="removeItem(item)"
                          >
                            delete
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceSettingsToolbar from '@/modules/finance/components/base/FinanceSettingsToolbar.vue'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import PAYMENT_TERM_REMOVE from '@/graphql/PaymentTermRemove.gql'
import PaymentTermEdit from '@/modules/finance/components/core/PaymentTermEdit.vue'

export default {
  name: 'FinanceSettingsPaymentTerms',
  components: {
    PaymentTermEdit,
    FinanceSettingsToolbar
  },
  data: () => ({
    dialog: false,
    removeDialog: false,
    edit: false,
    search: '',
    defaultItem: {
      id: '',
      name: '',
      days: 0,
      defaultPaymentTerm: false,
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      name: '',
      days: 0,
      defaultPaymentTerm: false,
      updatedAt: new Date(),
      createdAt: new Date()
    },
    deleteObject: null
  }),
  computed: {
    items() {
      return this._.orderBy(this.paymentTerms, ['days'], ['asc'])
    },
    authLevel() {
      return this.$store.getters.authLevel('finance_settings')
    }
  },
  apollo: {
    paymentTerms: PAYMENT_TERM_LIST
  },
  methods: {
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Payment Term Saved'
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },

    removeItem(item) {
      this.deleteObject = item
      this.removeDialog = true
    },

    deleteItem() {
      this.$apollo
        .mutate({
          mutation: PAYMENT_TERM_REMOVE,
          variables: {
            id: this.deleteObject.id
          },
          update: (store, { data: { removePaymentTerm } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: PAYMENT_TERM_LIST
            })

            data.paymentTerms = this._.reject(data.paymentTerms, {
              id: removePaymentTerm
            })

            // this._.remove(data.paymentTerms, function (item) {
            //   return item.id === removePaymentTerm.id
            // })

            // Write our data back to the cache.
            store.writeQuery({ query: PAYMENT_TERM_LIST, data })
          }
        })
        .then(() => {
          this.removeDialog = false
          this.deleteObject = null
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Payment Term Removed'
          })
        })
    }
  }
}
</script>

<style lang="scss"></style>
