<template>
  <div class="page-wrap">
    <finance-budget-detail-header
      :budget="budget"
    ></finance-budget-detail-header>

    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-row no-gutters class="mb-3">
          <v-col>
            <v-card flat>
              <v-row>
                <v-col
                  xs="4"
                  sm="3"
                  md="3"
                  lg="3"
                  xl="2"
                  class="d-none d-xs-flex d-sm-flex"
                  ><v-select
                    v-model="stateFilterValue"
                    :items="stateList"
                    hide-details
                    outlined
                    flat
                    dense
                    clearable
                    background-color="#fff"
                    placeholder="Status"
                    class="ml-3"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center" class="project-list">
          <v-col cols="12" class="py-0">
            <v-card flat>
              <v-card-title class="pt-0">
                <v-text-field
                  v-model="search"
                  hide-details
                  flat
                  clearable
                  background-color="#fff"
                  placeholder="Search..."
                  prepend-inner-icon="mdi-magnify"
                  autocomplete="off"
                  :disabled="
                    !projectsByBudgetID || projectsByBudgetID.length === 0
                  "
                ></v-text-field>
              </v-card-title>
              <project-list-items
                :data="projectsByBudgetID.items"
                :loading="$apollo.queries.projectsByBudgetID.loading"
                :item-count="itemCount"
                @set-options="setOptions"
              ></project-list-items>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import BUDGET from '@/graphql/Budget.gql'
import FinanceBudgetDetailHeader from '@/modules/finance/components/core/FinanceBudgetDetailHeader.vue'
import ProjectListItems from '@/modules/finance/components/core/ProjectListItems.vue'
// import ProjectEdit from '@/modules/finance/components/core/ProjectEdit.vue'
import PROJECTS from '@/graphql/ProjectsByBudgetID.gql'

export default {
  name: 'BudgetProjects',
  components: {
    FinanceBudgetDetailHeader,
    ProjectListItems
  },
  data: () => ({
    first: 5,
    page: 1,

    search: null,
    projectsByBudgetID: { items: [] },

    // Filters
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    stateFilterValue: null,
    stateList: ['draft', 'approved', 'rejected', 'review', 'closed'],

    defaultItem: {
      name: '',
      description: '',
      departmentID: '',
      budgetID: '',
      budgetLineID: '',
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString()
    },
    editedItem: {
      name: '',
      description: '',
      departmentID: '',
      budgetID: '',
      budgetLineID: '',
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString()
    },
    edit: false,
    dialog: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  apollo: {
    budget: {
      query: BUDGET,
      variables() {
        return {
          id: this.$route.params.budgetID
        }
      }
    },
    projectsByBudgetID: {
      query: PROJECTS,
      variables() {
        return {
          budgetID: this.$route.params.budgetID,
          filters: {
            search: this.search,
            yearID: this.yearID,
            departmentID: this.departmentID,
            status: this.stateFilterValue,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      result(result) {
        this.page = result.data.projectsByBudgetID.page
        this.itemsPerPage = result.data.projectsByBudgetID.pageSize
        this.itemCount = result.data.projectsByBudgetID.total
      }
    }
  },
  methods: {
    setOptions(e) {
      this.options = e
    },
    close(success, id) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })

        this.$router.push({
          name: 'ProjectDetail',
          params: { projectID: id }
        })
      }
    },
    newItem() {
      this.edit = false
      this.defaultItem.departmentID = this.currentUser.department.id
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    fetchMore() {
      // this.first = item.itemsPerPage
      var after = null
      if (this.projectsByBudgetID && this.projectsByBudgetID.pageInfo) {
        after = this.projectsByBudgetID.pageInfo.endCursor
      }
      this.$apollo.queries.projectsByBudgetID.fetchMore({
        variables: {
          first: this.first,
          after: after
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newProjects = fetchMoreResult.projectsByBudgetID.projects
          const newEdges = fetchMoreResult.projectsByBudgetID.edges
          const hasNextPage =
            fetchMoreResult.projectsByBudgetID.pageInfo.hasNextPage
          const totalCount = fetchMoreResult.projectsByBudgetID.totalCount
          const pageInfo = fetchMoreResult.projectsByBudgetID.pageInfo

          // this.showMoreEnabled = hasNextPage

          return {
            projects: {
              __typename: previousResult.projects.__typename,
              // Merging the projects list
              projects: [...previousResult.projects.projects, ...newProjects],
              edges: [...previousResult.projects.edges, ...newEdges],
              // projects: [...newProjects],
              // edges: [...newEdges],
              totalCount: totalCount,
              pageInfo: pageInfo,
              hasNextPage
            }
          }
        }
      })
    },
    stateFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.stateFilterValue) {
        return true
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.stateFilterValue
    }
  }
}
</script>

<style></style>
