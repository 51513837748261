<template>
  <v-dialog v-model="dialog" width="500" transition="dialog-bottom-transition">
    <v-card>
      <v-card-title>
        <span class="headline primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="budgetLineForm"
          v-model="valid"
          lazy-validation
          @submit.prevent
        >
          <v-row>
            <!-- <v-col cols="12" md="6" lg="6" class="py-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label>Currency</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    v-model="item.currency"
                    :items="currencies"
                    :rules="requiredRule"
                    outlined
                    autocomplete
                    return-object
                    item-text="name"
                    hide-details="auto"
                    disabled
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-col> -->
            <v-col v-if="item && item.currency">
              <v-currency-field
                v-model="item.adjustmentAmount"
                autocomplete="off"
                outlined
                label="Price"
                :prefix="item.currency.symbol"
                :rules="amountRequiredRule"
                hide-details="auto"
                dense
              ></v-currency-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="px-5 pb-5">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          rounded
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn color="primary" rounded :loading="$apollo.loading" @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import BUDGET_CATEGORIES from '@/graphql/BudgetCategories.gql'
import BUDGET from '@/graphql/Budget.gql'
import ADJUST_BUDGET_LINE from '@/graphql/BudgetLineAdjust.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'BudgetLineAdjustDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: null },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    oldAmount: 0,
    // money: {
    //   decimal: ',',
    //   thousands: '.',
    //   // prefix: '€ ',
    //   precision: 2,
    //   masked: false /* doesn't work with directive */
    // },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    amountRequiredRule: [
      v => !!v || 'Field is required',
      v => (!!v && parseInt(v) >= 0) || 'Value can not be below 0'
    ]
  }),
  apollo: {
    currencies: CURRENCY_LIST
  },
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return 'Adjust Budget Line'
    }
  },
  created() {
    const copy = JSON.parse(JSON.stringify(this.editedItem))
    this.oldAmount = copy.pricing.adjustmentAmount * 100
  },
  methods: {
    close(success) {
      // this.$refs.price.$el.getElementsByTagName('input')[0].value = 0
      this.$refs.budgetLineForm.reset()
      this.$emit('close-budget-line-dialog', success)
    },
    save() {
      if (this.$refs.budgetLineForm.validate()) {
        // Set the reporting Currency and exchange rate
        var baseCurrency = this._.find(this.currencies, function(item) {
          return item.defaultCurrency === true
        })

        // Make a copy to make sure the new object is not the observable object
        let item = this._.cloneDeep(this.item)

        // Update Item
        let data = {
          id: item.id,
          title: item.title,
          description: item.description,
          budgetID: item.budgetID,
          budgetCategoryID: item.budgetCategoryID,
          pricing: {
            currencyID: item.currency.id,
            baseCurrencyID: baseCurrency.id,
            exchangeRate: item.currency.exchangeRate,
            displayAmount: Math.round(item.adjustmentAmount * 100) || 0 // Return the price to an int
          }
        }

        this.$apollo
          .mutate({
            mutation: ADJUST_BUDGET_LINE,
            variables: {
              input: data
            }
          })
          .then(result => {
            this.$apollo.query({
              query: BUDGET,
              variables: {
                id: result.data.adjustBudgetLine.budgetID
              },
              fetchPolicy: 'network-only'
            })
            this.close(true)
          })
      }
    }
  }
}
</script>

<style lang="scss">
#edit-budget-description {
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
