<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header pt-0 px-1">
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <!-- <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>-->
          <budget-edit
            :open="dialog"
            :edited-item="editedItem"
            :edit-mode="edit"
            @close-edit-budget-dialog="close"
          ></budget-edit>
          <v-row no-gutters>
            <v-col cols="4" md="3" lg="2">
              <v-row no-gutters>
                <v-btn color="primary" rounded @click="newItem">
                  add budget
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="2" class="d-none d-xs-flex d-sm-flex">
              <v-select
                v-model="stateFilterValue"
                :items="stateList"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                placeholder="Status"
                class="ml-3"
              ></v-select>
            </v-col>
            <v-col
              v-if="
                currentUser &&
                  (currentUser.isSuperAdmin ||
                    currentUser.userRole === 'director' ||
                    currentUser.userRole === 'seniormanager' ||
                    currentUser.userRole === 'projectmanager')
              "
              cols="2"
              class="d-none d-xs-flex d-sm-flex"
            >
              <v-select
                v-model="departmentID"
                :items="departments"
                hide-details
                outlined
                flat
                dense
                clearable
                item-value="id"
                item-text="name"
                background-color="#fff"
                placeholder="Department"
                class="ml-3"
              ></v-select>
            </v-col>
            <v-col
              v-if="
                currentUser &&
                  (currentUser.isSuperAdmin ||
                    currentUser.userRole === 'director' ||
                    currentUser.userRole === 'seniormanager' ||
                    currentUser.userRole === 'projectmanager')
              "
              cols="2"
              class="d-none d-xs-flex d-sm-flex"
            >
              <v-select
                v-model="yearID"
                :items="financialYears"
                hide-details
                outlined
                flat
                dense
                clearable
                item-value="id"
                item-text="name"
                background-color="#fff"
                placeholder="Year"
                class="ml-3"
              ></v-select>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center" class="project-list">
            <v-col cols="12" class="py-0">
              <v-card flat>
                <v-card-title class="pt-0">
                  <v-text-field
                    v-model="search"
                    hide-details
                    flat
                    clearable
                    background-color="#fff"
                    placeholder="Search..."
                    prepend-inner-icon="mdi-magnify"
                    autocomplete="off"
                  ></v-text-field>
                </v-card-title>
                <budget-list-items
                  :data="budgets.items"
                  :loading="$apollo.queries.budgets.loading"
                  :item-count="itemCount"
                  @set-options="setOptions"
                ></budget-list-items>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BudgetListItems from '@/modules/finance/components/core/BudgetListItems.vue'
import BudgetEdit from '@/modules/finance/components/core/BudgetEdit.vue'
import BUDGETS from '@/graphql/Budgets.gql'
import DEPARTMENTS from '@/graphql/Departments.gql'
import FINANCIAL_YEARS from '@/graphql/FinancialYears.gql'

export default {
  name: 'BudgetList',
  components: {
    BudgetListItems,
    BudgetEdit
  },
  data: () => ({
    search: null,
    budgets: { items: [] },

    // Filters
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    stateFilterValue: null,
    stateList: ['draft', 'approved', 'rejected', 'review'],
    yearID: null,
    departmentID: null,

    defaultItem: {
      title: '',
      description: '',
      department: {},
      financialYear: {},
      currency: {}
    },
    editedItem: {
      title: '',
      description: '',
      department: {},
      financialYear: {},
      currency: {}
    },
    edit: false,
    dialog: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  apollo: {
    budgets: {
      query: BUDGETS,
      variables() {
        return {
          filters: {
            search: this.search,
            yearID: this.yearID,
            departmentID: this.departmentID,
            status: this.stateFilterValue,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      result(result) {
        this.page = result.data.budgets.page
        this.itemsPerPage = result.data.budgets.pageSize
        this.itemCount = result.data.budgets.total
      }
    },
    departments: DEPARTMENTS,
    financialYears: FINANCIAL_YEARS
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    setOptions(e) {
      this.options = e
    },
    close(success, id) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Budget Added'
        })

        this.$router.push({
          name: 'BudgetDetail',
          params: { budgetID: id }
        })
      }
    },
    newItem() {
      this.edit = false
      this.defaultItem.department = this.currentUser.department
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    stateFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.stateFilterValue) {
        return true
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.stateFilterValue
    }
  }
}
</script>

<style></style>
