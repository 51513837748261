<template>
  <v-row justify="center">
    <v-col cols="12" class="py-0">
      <v-data-table
        :headers="headers"
        :items="budgets"
        :loading="loading"
        :options.sync="listOptions"
        :server-items-length="itemCount"
        :hide-default-header="!budgets || budgets.length === 0"
        :hide-default-footer="
          !budgets || itemCount < listOptions.itemsPerPage + 1
        "
        :footer-props="footerOptions"
        :mobile-breakpoint="null"
        class="plain-table"
        @click:row="navToDetail"
      >
        <template v-slot:item.title="{ item }">
          <div class="py-2">
            <v-badge :color="stateColor(item.status)" dot left inline>
              <div class="ml-2">
                <div
                  style="white-space: nowrap"
                  class="primary--text text-truncate body-1"
                >
                  {{ item.title }}
                </div>
                <!-- <div class="grey--text darken-2 text-truncate mt-1">
                    {{ item.owner.firstName + ' ' + item.owner.lastName }}
                  </div> -->
              </div>
            </v-badge>
          </div>
        </template>
        <template v-slot:item.financialYear="{}">
          <!-- <div>{{ item.financialYear.name }}</div> -->
          &nbsp;
        </template>
        <!-- <template v-slot:item.owner="{ item }">
            <div class="text-truncate">
              {{ item.owner.firstName + ' ' + item.owner.lastName }}
            </div>
          </template> -->
        <!-- <template v-slot:item.department="{ item }">
            <v-chip color="info lighten-1" x-small class="mr-3">
              {{ item.department.name }}
            </v-chip>
          </template> -->
        <template v-slot:item.department="{ item }">
          <div class="py-2">
            <v-chip
              color="info lighten-2 font-weight-bold upperCaseSpacing"
              x-small
              >{{ item.department.name }}</v-chip
            >
            <div class="grey--text text--darken-1 body-2 text-truncate mt-1">
              {{ item.owner.firstName + ' ' + item.owner.lastName }}
            </div>
          </div>
        </template>
        <template v-slot:item.status="{}">&nbsp;</template>
        <template v-slot:item.forecastAmount="{ item }">
          <div class="text-truncate">
            {{
              (item.forecastAmount / 100)
                | currency(item.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>
        <template v-slot:item.pendingAmount="{ item }">
          <div class="text-truncate">
            {{
              (item.pendingAmount / 100)
                | currency(item.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>
        <template v-slot:item.actualAmount="{ item }">
          <div class="text-truncate">
            {{
              (item.actualAmount / 100)
                | currency(item.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>
        <template v-slot:item.balanceAmount="{ item }">
          <div class="text-truncate info--text">
            {{
              (item.balanceAmount / 100)
                | currency(item.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>
        <template v-slot:no-data>
          <message-box
            icon="mdi-emoticon-neutral-outline"
            title="No Results"
            caption="Try adjusting your filters to get some results"
            icon-color="info"
            title-color="info--text"
            caption-color="grey--text darken-2 font-weight-medium"
          ></message-box>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'

export default {
  name: 'BudgetListItems',
  components: { MessageBox },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    itemCount: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    listOptions: { page: 1, itemsPerPage: 15 },
    footerOptions: {
      'items-per-page-options': [10, 15, 25, 50]
    },
    money: {
      decimal: '.',
      thousands: ',',
      precision: 2,
      masked: false
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Title',
          align: 'left',
          sortable: false,
          value: 'title'
        },
        // { text: 'Owner', value: 'owner', visible: false, sortable: false },
        {
          text: 'Department',
          align: 'left',
          value: 'department',
          sortable: false
        },

        {
          text: 'Forecast',
          align: 'left',
          value: 'forecastAmount',
          sortable: false
        },
        {
          text: 'In Progress',
          align: 'left',
          value: 'pendingAmount',
          sortable: false
        },
        {
          text: 'Actual',
          align: 'left',
          value: 'actualAmount',
          sortable: false
        },
        {
          text: 'Balance',
          align: 'left',
          value: 'balanceAmount',
          sortable: false
        },

        {
          text: '',
          align: 'left',
          value: 'financialYear',
          sortable: false,
          width: 0
        },
        {
          text: '',
          align: 'start',
          value: 'status',
          sortable: false,
          width: 0,
          filter: this.stateFilterValue
        }
      ]
    },
    budgets() {
      return this.data
    }
  },
  watch: {
    listOptions(e) {
      // if (this.projects.totalCount) {
      //   return this.$emit('fetch-more-projects', e)
      // }
      return this.$emit('set-options', e)
    }
  },
  methods: {
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    },
    navToDetail(item) {
      this.$router.push({
        name: 'BudgetDetail',
        params: { budgetID: item.id }
      })
    }
    // editAuth (item) {
    //   this.$emit('edit-auth-template', item)
    // }
  }
}
</script>

<style lang="scss">
.list-item {
  // border: 1px solid transparent;
  &:hover {
    border-color: var(--v-primary-base);
  }
}

.plain-table .v-input__slot {
  background: transparent !important;
}

.plain-table.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content) {
  cursor: pointer !important;
}
</style>
