var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header"},[(!_vm.$apollo.loading && _vm.project)?_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"extended":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left","background-color":"transparent","align-with-title":"","center-active":"","show-arrows":"","color":"info"}},[_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ProjectCosting',
            params: { projectID: _vm.$route.params.projectID }
          }}},[_vm._v("Cost Breakdown")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ProjectDetails',
            params: { projectID: _vm.$route.params.projectID }
          },"exact":""}},[_vm._v("Details")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ProjectPurchaseOrders',
            params: { projectID: _vm.$route.params.projectID }
          }}},[_vm._v("Purchase Orders")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ProjectAuth',
            params: { projectID: _vm.$route.params.projectID }
          }}},[_vm._v("Authorisation")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ProjectAssets',
            params: { projectID: _vm.$route.params.projectID }
          }}},[_vm._v("Assets")]),(_vm.project && _vm.project.status === 'draft')?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ProjectSettings',
            params: { projectID: _vm.$route.params.projectID }
          }}},[_vm._v("Settings")]):_vm._e()],1),_vm._t("controls")]},proxy:true}],null,true)},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"start","align":"start"}},[_c('div',{attrs:{"xs6":""}},[_c('h2',{staticClass:"mb-0 info--text"},[_vm._v(" "+_vm._s(_vm.project.name)+" ")]),_c('div',[_c('v-chip',{attrs:{"color":_vm.stateColor(_vm.project.status),"text-color":_vm.project.status === 'draft' ? 'grey darken-3' : 'white',"x-small":"","pill":""}},[_vm._v(_vm._s(_vm.project.status))])],1)]),_c('v-spacer'),_c('div',{attrs:{"xs6":""}},[(
                  _vm.project &&
                    _vm.project.owner &&
                    _vm.currentUser &&
                    _vm.project.status === 'approved' &&
                    _vm.currentUser.id === _vm.project.owner.id
                )?_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3 mt-2 text-capitalize",attrs:{"color":"primary","small":"","submit":"","rounded":""}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v("close project ")],1)]}}],null,false,1018439743),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary--text mb-3"},[_vm._v("Close Project")]),_c('v-card-text',{staticClass:"pb-0"},[(_vm.project && _vm.project.pendingAmount <= 1000)?_c('p',[_vm._v(" This will close the project and release any remaining balance back to the budget. ")]):_c('v-alert',{attrs:{"type":"info","color":"info","text":""}},[_c('p',[_vm._v(" The project can only be closed once the in progress balance falls below "+_vm._s(_vm._f("currency")(10,_vm.project.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+". ")]),_c('p',[_vm._v(" Once all orders have been paid, you will be able to close the Project. ")])])],1),_c('v-card-actions',{staticClass:"pa-5"},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"accent darken-1","text":"","rounded":"","small":"","disabled":_vm.processing},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),(_vm.project && _vm.project.pendingAmount <= 1000)?_c('v-btn',{attrs:{"color":"primary darken-1","rounded":"","small":"","loading":_vm.processing},on:{"click":_vm.closeProject}},[_vm._v("Submit")]):_vm._e()],1)],1)],1):_vm._e(),(
                  _vm.project &&
                    _vm.project.owner &&
                    _vm.currentUser &&
                    (_vm.project.status === 'draft' ||
                      _vm.project.status === 'pendingchanges') &&
                    (_vm.currentUser.id === _vm.project.owner.id ||
                      _vm.currentUser.isSuperAdmin)
                )?_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3 mt-2 text-capitalize",attrs:{"color":"primary","small":"","submit":"","rounded":""}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-send-outline")]),(_vm.project && _vm.project.status === 'pendingchanges')?_c('span',[_vm._v("re-")]):_vm._e(),_vm._v("submit ")],1)]}}],null,false,1801724022),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary--text mb-3"},[_vm._v("Submit for Approval")]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v("This will submit your project for approval.")]),_c('p',[_vm._v(" Once submitted you will not be able to make any changes. ")])]),_c('v-card-actions',{staticClass:"pa-5"},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"accent darken-1","text":"","small":"","rounded":"","disabled":_vm.processing},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary darken-1","rounded":"","small":"","disabled":_vm.processing},on:{"click":_vm.submit}},[_vm._v("Submit")])],1)],1)],1):_vm._e(),(
                      _vm.project &&
                      _vm.currentUser &&
                      (
                        _vm.currentUser.userRole === 'director' ||
                        _vm.currentUser.userRole === 'seniormanager' ||
                        _vm.currentUser.isAdmin ||
                        _vm.currentUser.isSuperAdmin
                      )
                    )?_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 ml-3",attrs:{"color":"primary","small":"","submit":"","rounded":""}},on),[_vm._v(" change owner ")])]}}],null,false,1714929642),model:{value:(_vm.updateOwnerDialog),callback:function ($$v) {_vm.updateOwnerDialog=$$v},expression:"updateOwnerDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary--text mb-3"},[_vm._v("Transfer Ownership")]),_c('v-card-text',[_c('v-form',{ref:"changeOwnerForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pb-0 px-0",attrs:{"cols":"12"}},[_c('v-row',[(_vm.errorMsg)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error","text":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.companyUsers,"rules":_vm.requiredSelectRule,"outlined":"","label":"New Owner","placeholder":"Select New Owner","hide-details":"auto","autocomplete":"","return-object":"","disabled":_vm.$apollo.loading,"dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.firstName + ' ' + data.item.lastName)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.firstName + ' ' + data.item.lastName)+" ")]}}],null,false,2621033468),model:{value:(_vm.ownerEdit.owner),callback:function ($$v) {_vm.$set(_vm.ownerEdit, "owner", $$v)},expression:"ownerEdit.owner"}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"accent darken-1","text":"","rounded":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","small":"","rounded":""},on:{"click":_vm.changeOwner}},[_vm._v("Save")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }