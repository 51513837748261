<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <div v-if="item" class="scroll-container pt-0">
          <v-form
            ref="projectCategoryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12" class="pb-0">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-model="item.name"
                          autocomplete="off"
                          placeholder="Name"
                          outlined
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" class="pb-0">
                    <label>Description</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <vue-editor
                      id="edit-project-description"
                      v-model="item.description"
                      :editor-options="editorConfig"
                      placeholder="A short description, which describes what the project is for."
                      class="pb-3"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-1"
          text
          rounded
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="primary darken-1"
          rounded
          small
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PROJECT_CATEGORIES from '@/graphql/ProjectCategories.gql'
import CREATE_PROJECT_CATEGORY from '@/graphql/ProjectCategoryCreate.gql'
import UPDATE_PROJECT_CATEGORY from '@/graphql/ProjectCategoryUpdate.gql'

export default {
  name: 'ProjectCategoryDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    money: {
      decimal: ',',
      thousands: '.',
      // prefix: '€ ',
      precision: 2,
      masked: false /* doesn't work with directive */
    },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ]
  }),
  apollo: {},
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Project Category' : 'New Project Category'
    }
  },
  methods: {
    close(success) {
      this.$refs.projectCategoryForm.reset()
      this.$emit('close-project-category-dialog', success)
    },
    save() {
      // Return the price to an int

      if (this.editMode) {
        // Make a copy to make sure the new object is not the observable object
        let item = this._.cloneDeep(this.item)

        // Update Item
        let data = {
          id: item.id,
          name: item.name,
          description: item.description,
          projectID: this.$route.params.projectID
        }

        if (this.$refs.projectCategoryForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_PROJECT_CATEGORY,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        var that = this
        // New Item
        let data = {
          name: this.item.name,
          description: this.item.description,
          projectID: this.$route.params.projectID
        }

        if (this.$refs.projectCategoryForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_PROJECT_CATEGORY,
              variables: {
                input: data
              },
              update: (store, { data: { createProjectCategory } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: PROJECT_CATEGORIES,
                  variables: {
                    projectID: that.$route.params.projectID
                  }
                })
                // Add our requirement from the mutation to the end
                data.projectCategories.push(createProjectCategory)
                // Write our data back to the cache.
                store.writeQuery({
                  query: PROJECT_CATEGORIES,
                  variables: { projectID: that.$route.params.projectID },
                  data
                })
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-project-description {
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
