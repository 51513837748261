<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="px-3 pb-0">
        <div v-if="item" class="scroll-container pt-0">
          <v-form
            ref="projectForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12" class="pb-0">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Title</label>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      autocomplete="off"
                      placeholder="Give your project a title"
                      outlined
                      hide-details="auto"
                      :rules="requiredRule"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-3">
                    <label>Description</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <vue-editor
                      id="edit-project-description"
                      v-model="item.description"
                      :editor-options="editorConfig"
                      placeholder="A short description, which describes what the project is for."
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Department</label>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="item.departmentID"
                          :items="departments"
                          :rules="requiredRule"
                          hide-details="auto"
                          outlined
                          autocomplete
                          item-value="id"
                          item-text="name"
                          dense
                          :disabled="
                            currentUser &&
                            (currentUser.userRole === 'mananager' ||
                              currentUser.userRole === 'user') &&
                            !currentUser.isSuperAdmin
                          "
                          @change="clearBudgetLines(item.departmentID)"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Currency</label>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="item.currency"
                          :items="currencies"
                          :rules="requiredRule"
                          outlined
                          hide-details="auto"
                          autocomplete
                          return-object
                          item-text="name"
                          :disabled="editMode"
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" lg="6" class="pt-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">Start Date</v-col>
                      <v-col cols="12">
                        <v-menu
                          ref="menu1"
                          v-model="startDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formattedStartDate"
                              outlined
                              prepend-inner-icon="mdi-calendar-month-outline"
                              dense
                              hide-details="auto"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.startDate"
                            no-title
                            @input="startDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" class="pt-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">End Date</v-col>
                      <v-col cols="12">
                        <v-menu
                          ref="menu1"
                          v-model="endDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formattedEndDate"
                              outlined
                              prepend-inner-icon="mdi-calendar-month-outline"
                              dense
                              hide-details="auto"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.endDate"
                            :min="
                              $moment(item.startDate)
                                .add(1, 'days')
                                .format('YYYY-MM-DD')
                            "
                            no-title
                            @input="endDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    !editMode && budgetCategories && budgetCategories.length > 0
                  "
                >
                  <v-col cols="12" class="pt-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Budget Allocation</label>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="item.budgetLine"
                          :items="budgetCategories"
                          :rules="requiredRule"
                          outlined
                          item-value="id"
                          item-text="title"
                          return-object
                          dense
                          clearable
                          messages="Select a budget item to allocate this project against"
                          :disabled="
                            budgetCategories && budgetCategories.length === 0
                          "
                          :loading="$apollo.loading"
                        >
                          <template v-slot:selection="data">{{
                            data.item.title
                          }}</template>
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content
                                v-text="data.item"
                              ></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-row no-gutters>
                                    <v-col>{{ data.item.title }}</v-col>
                                    <v-col
                                      v-if="currentUser.userRole !== 'user'"
                                    >
                                      <v-row no-gutters justify="end">
                                        <div>
                                          <span class="overline">Balance:</span>
                                          {{
                                            (data.item.balanceAmount / 100)
                                              | currency(
                                                data.item.currency.symbol,
                                                2,
                                                {
                                                  spaceBetweenAmountAndSymbol: true,
                                                }
                                              )
                                          }}
                                        </div>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          small
          rounded
          :disabled="processing"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="primary darken-1"
          small
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DEPARTMENT_LIST from '@/graphql/Departments.gql'
import FINANCIAL_YEAR_LIST from '@/graphql/FinancialYears.gql'
import BUDGET_CATEGORIES_BY_DEPARTMENT_YEAR_LIST from '@/graphql/BudgetCategoriesByDepartmentYear.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import PROJECTS from '@/graphql/Projects.gql'
import CREATE_PROJECT from '@/graphql/ProjectCreate.gql'
import UPDATE_PROJECT from '@/graphql/ProjectUpdate.gql'

export default {
  name: 'ProjectEdit',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: Boolean,
    open: Boolean,
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,

    editorConfig: {
      modules: {
        toolbar: false,
      },
    },
    startDate: false,
    endDate: false,
    requiredDepartmentRule: [
      (v) => !!v.id || 'Field is required',
      (v) => !/^\s+$/.test(v) || 'Field is required',
    ],
    requiredRule: [
      (v) => !!v || 'Field is required',
      (v) => !/^\s+$/.test(v) || 'Field is required',
    ],
    requiredSelectRule: [
      (v) => !!v.id || 'Field is required',
      (v) => !/^\s+$/.test(v) || 'Field is required',
    ],
    dateRule: [(v) => !!v || 'Date is required'],
    budgetCategories: [],
  }),
  apollo: {
    departments: DEPARTMENT_LIST,
    financialYears: FINANCIAL_YEAR_LIST,
    currencies: CURRENCY_LIST,
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      },
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      },
    },
    unFormattedStartDate() {
      return this.item.startDate
    },
    formTitle() {
      return this.editMode ? 'Edit Project' : 'New Project'
    },
    formattedStartDate() {
      return this.item.startDate
        ? this.$moment(this.item.startDate).format('LL')
        : ''
    },
    formattedEndDate() {
      // this.$moment().locale('nl')
      // if (this.item.endDate) {
      return this.item.endDate
        ? this.$moment(this.item.endDate).format('LL')
        : ''
      // } else if (this.item.endDate < this.item.startDate) {
      //   return this.$moment(this.item.startDate).format('LL')
      // } else {
      //   return this.item.endDate
      //     ? this.$moment(this.item.endDate).format('LL')
      //     : this.$moment(this.item.startDate).format('LL')
      // }
    },
  },
  watch: {
    dialog() {
      if (this.$refs && this.$refs.projectForm) {
        this.$refs.projectForm.resetValidation()
      }
    },
    open(val) {
      if (val && this.unFormattedStartDate && this.item.departmentID) {
        // Get list of budgetCategories
        var year = this._.find(
          this.financialYears,
          (item) =>
            this.$moment(this.unFormattedStartDate).isSameOrAfter(
              this.$moment(item.start)
            ) &&
            this.$moment(this.unFormattedStartDate).isSameOrBefore(
              this.$moment(item.end)
            )
        )
        this.getBudgetCategories(this.item.departmentID, year.id)
      }
    },
    formattedStartDate(date) {
      let dateObj = this.$moment(date, 'LL')
      if (dateObj.isAfter(this.$moment(this.formattedEndDate, 'LL'))) {
        // this.formattedEndDate = date
        this.item.endDate = this.$moment(date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }

      if (this.item.departmentID) {
        // Get list of budgetCategories
        var year = this._.find(
          this.financialYears,
          (item) =>
            dateObj.isSameOrAfter(this.$moment(item.start).format('LL')) &&
            dateObj.isSameOrBefore(this.$moment(item.end).format('LL'))
        )
        this.getBudgetCategories(this.item.departmentID, year.id)
      }
    },
  },
  methods: {
    clearBudgetLines(departmentID) {
      this.item.budgetLine = {}
      this.item.budgetLineID = ''

      if (this.unFormattedStartDate) {
        // Get list of budgetCategories
        var year = this._.find(
          this.financialYears,
          (item) =>
            this.$moment(this.unFormattedStartDate).isSameOrAfter(
              this.$moment(item.start)
            ) &&
            this.$moment(this.unFormattedStartDate).isSameOrBefore(
              this.$moment(item.end)
            )
        )
        this.getBudgetCategories(departmentID, year.id)
      }
    },
    // changedDepartment () {
    //   var year = this._.find(
    //     this.financialYears,
    //     item =>
    //       this.$moment(item.start)
    //         .format('LL')
    //         .isSameOrAfter(this.$moment(item.start).format('LL')) &&
    //       this.$moment(item.start)
    //         .format('LL')
    //         .isSameOrBefore(this.$moment(item.end).format('LL'))
    //   )
    //   this.getBudgetCategories(this.item.departmentID, year.id)
    // },
    getBudgetCategories(departmentID, financialYearID) {
      // this.item.budgetID = null
      // this.item.budgetLineID = null
      this.$apollo
        .query({
          query: BUDGET_CATEGORIES_BY_DEPARTMENT_YEAR_LIST,
          variables: {
            departmentID: departmentID,
            financialYearID: financialYearID,
          },
        })
        .then((res) => {
          var that = this
          if (res.data.budgetCategoriesByDepartmentYear.length > 0) {
            this.budgetCategories = []

            this._.forEach(
              res.data.budgetCategoriesByDepartmentYear,
              function (item, idx) {
                let header = {
                  header: item.name,
                }
                let divider = {
                  divider: true,
                }

                if (idx > 0) {
                  that.budgetCategories.push(divider)
                }

                if (item.lineItems && item.lineItems.length > 0) {
                  that.budgetCategories.push(header, ...item.lineItems)
                }
              }
            )
          } else {
            this.budgetCategories = []
          }
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'A general server error occured. Please try again later',
          })

          return this.close(false)
        })
    },
    close(success, projectID) {
      // this.$refs.projectForm.reset()
      this.$emit('close-edit-project-dialog', success, projectID)
    },
    save() {
      this.processing = true

      if (this.editMode) {
        // Update Item

        if (this.$refs.projectForm.validate()) {
          let data = {
            id: this.item.id,
            name: this.item.name,
            description: this.item.description,
            departmentID: this.item.departmentID,
            budgetID: this.item.budgetID,
            budgetLineID: this.item.budgetLine.id,
            currencyID: this.item.currency.id,
            startDate: this.$moment(this.item.startDate).utc(),
            endDate: this.$moment(this.item.endDate).utc(),
          }

          this.$apollo
            .mutate({
              mutation: UPDATE_PROJECT,
              variables: {
                input: data,
              },
            })
            .then(result => {
              this.processing = false
              this.close(true, result.data.updateProject.id)
            })
            .catch(() => {
              this.processing = false
              this.$store.commit('showSnackbar', {
                active: true,
                message:
                  'A general server error occured. Please try again later',
              })

              // return this.close(false)
            })
        }
      } else {
        // New Item

        if (this.$refs.projectForm.validate()) {
          let data = {
            name: this.item.name,
            description: this.item.description,
            departmentID: this.item.departmentID,
            budgetID: this.item.budgetLine.budgetID,
            budgetLineID: this.item.budgetLine.id,
            currencyID: this.item.currency.id,
            startDate: this.$moment(this.item.startDate).utc(),
            endDate: this.$moment(this.item.endDate).utc(),
          }

          this.$apollo
            .mutate({
              mutation: CREATE_PROJECT,
              variables: {
                input: data,
              },
              update: (store, { data: { createProject } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: PROJECTS,
                })
                // Add our requirement from the mutation to the end
                data.projects.unshift(createProject)
                // Write our data back to the cache.
                store.writeQuery({ query: PROJECTS, data })
              },
            })
            .then(result => {
              this.processing = false
              this.close(true, result.data.createProject.id)
            })
            .catch(() => {
              this.processing = false
              this.$store.commit('showSnackbar', {
                active: true,
                message:
                  'A general server error occured. Please try again later',
              })

              // return this.close(false)
            })
        }
      }
    },
  },
}
</script>

<style lang="scss">
#edit-project-description {
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }

  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
