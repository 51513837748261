<template>
  <div class="page-wrap">
    <purchase-order-detail-header
      :purchase-order="purchaseOrder"
    ></purchase-order-detail-header>
    <div class="main-page-column pt-0">
      <v-dialog v-model="secondRemoveDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Purchase Order</v-card-title
          >
          <v-card-text>
            <p>
              This will delete the purchase order and any associated with data.
            </p>
            <p>
              This operation can't be undone!
            </p>

            <v-checkbox
              v-model="removalConfirmation"
              color="error"
              label="I understand the concequences!"
            >
            </v-checkbox>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="grey darken-1"
              text
              :disabled="processing"
              @click="closeRemoveDialog(false)"
              >Cancel</v-btn
            >
            <v-btn
              color="error"
              text
              :disabled="!removalConfirmation"
              :loading="processing"
              @click="removePurchaseOrder"
              >Remove Purchase Order</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="scroll-container pt-0">
        <v-row justify="center">
          <v-col
            v-if="purchaseOrder && purchaseOrder.status === 'draft'"
            cols="12"
            md="10"
            lg="8"
            xl="6"
          >
            <v-card flat class="mx-auto">
              <v-card-title class="mb-3 primary--text"
                >Delete Purchase Order</v-card-title
              >
              <v-card-subtitle
                >Once you delete this purchase order, you will lose all data
                associated with it.</v-card-subtitle
              >

              <v-card-text>
                <v-btn
                  v-if="
                    purchaseOrder &&
                      authLevel &&
                      authLevel === 2 &&
                      currentUser &&
                      (currentUser.id === purchaseOrder.owner.id ||
                        currentUser.isSuperAdmin ||
                        currentUser.isAdmin ||
                        currentUser.userRole === 'director' ||
                        currentUser.userRole === 'seniormanager' ||
                        currentUser.userRole === 'purchaseOrdermanager')
                  "
                  color="error lighten-2"
                  depressed
                  rounded
                  small
                  class="mt-3 upperCaseSpacing"
                  @click="openSecondRemoveDialog"
                >
                  delete purchaseOrder
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseOrderDetailHeader from '@/modules/finance/components/core/PurchaseOrderDetailHeader.vue'
import PURCHASEORDER from '@/graphql/PurchaseOrder.gql'
import REMOVE_PURCHASE_ORDER from '@/graphql/PurchaseOrderRemove.gql'

export default {
  name: 'PurchaseOrderSettings',
  components: { PurchaseOrderDetailHeader },
  props: {},
  data: () => ({
    processing: false,
    removeDialog: false,
    secondRemoveDialog: false,
    removalConfirmation: false
  }),
  apollo: {
    purchaseOrder: {
      query: PURCHASEORDER,
      variables() {
        return {
          id: this.$route.params.purchaseOrderID
        }
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('purchase_orders')
    }
  },
  methods: {
    closeRemoveDialog(success) {
      this.removeDialog = false
      this.secondRemoveDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Purchase Order Removed'
        })
      }
      this.processing = false
    },

    openSecondRemoveDialog() {
      this.removalConfirmation = false
      this.removeDialog = false
      this.secondRemoveDialog = true
    },

    removePurchaseOrder() {
      var that = this
      if (this.removalConfirmation) {
        this.processing = true
        this.$apollo
          .mutate({
            mutation: REMOVE_PURCHASE_ORDER,
            variables: {
              id: that.purchaseOrder.id
            }
          })
          .then(() => {
            this.closeRemoveDialog(true)

            this.$router.replace({ name: 'PurchaseOrderList' })
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'An error occured please try again later'
            })
            this.processing = false
          })
      }
    }
  }
}
</script>

<style lang="scss">
// @import '~quill/dist/quill.bubble.css';
// @import '~quill/dist/quill.snow.css';
#edit-purchaseOrder-description {
  border: 1px solid #ccc;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
