<template>
  <div class="page-wrap">
    <finance-project-detail-header :project="project">
    </finance-project-detail-header>

    <div class="main-page-column pt-0">
      <v-dialog v-model="removeDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Project</v-card-title
          >
          <v-card-text>
            <p>
              This will delete this project. This will also remove all
              categories and project lines associated with this project.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey darken-1" text @click="closeRemoveDialog(false)"
              >Cancel</v-btn
            >
            <v-btn color="error" text @click="openSecondRemoveDialog"
              >Remove</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="secondRemoveDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Are you really sure?</v-card-title
          >
          <v-card-text>
            <p>
              This operation can't be undone!
            </p>

            <v-checkbox
              v-model="removalConfirmation"
              color="error"
              label="I understand the concequences!"
            >
            </v-checkbox>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="grey darken-1"
              text
              :disabled="processing"
              @click="closeRemoveDialog(false)"
              >Cancel</v-btn
            >
            <v-btn
              color="error"
              text
              :disabled="!removalConfirmation"
              :loading="processing"
              @click="removeProject"
              >Remove Project</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="scroll-container pt-0">
        <v-row justify="center">
          <v-col
            v-if="project && project.status === 'draft'"
            cols="12"
            md="10"
            lg="8"
            xl="6"
          >
            <v-card flat class="mx-auto">
              <v-card-title class="mb-3 primary--text"
                >Delete Project</v-card-title
              >
              <v-card-subtitle
                >Once you delete this project, you will lose all data associated
                with it.</v-card-subtitle
              >

              <v-card-text>
                <v-btn
                  v-if="
                    project &&
                      authLevel &&
                      authLevel === 2 &&
                      currentUser &&
                      (currentUser.id === project.owner.id ||
                        currentUser.isSuperAdmin ||
                        currentUser.isAdmin ||
                        currentUser.userRole === 'director' ||
                        currentUser.userRole === 'seniormanager' ||
                        currentUser.userRole === 'projectmanager')
                  "
                  color="error lighten-2"
                  depressed
                  rounded
                  small
                  class="mt-3 upperCaseSpacing"
                  @click="openSecondRemoveDialog"
                >
                  delete project
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceProjectDetailHeader from '@/modules/finance/components/core/FinanceProjectDetailHeader.vue'
import PROJECT_LIST from '@/graphql/Projects.gql'
import REMOVE_PROJECT from '@/graphql/ProjectRemove.gql'
import PROJECT from '@/graphql/Project.gql'

export default {
  name: 'ProjectSettings',
  components: {
    FinanceProjectDetailHeader
  },
  data: () => ({
    processing: false,
    removeDialog: false,
    secondRemoveDialog: false,
    removalConfirmation: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('finance_projects')
    }
  },
  apollo: {
    project: {
      query: PROJECT,
      variables() {
        return {
          id: this.$route.params.projectID
        }
      }
    }
  },
  methods: {
    closeRemoveDialog(success) {
      this.removeDialog = false
      this.secondRemoveDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Project Removed'
        })
      }
      this.processing = false
    },

    openSecondRemoveDialog() {
      this.removalConfirmation = false
      this.removeDialog = false
      this.secondRemoveDialog = true
    },

    removeProject() {
      var that = this
      if (this.removalConfirmation) {
        this.processing = true
        this.$apollo
          .mutate({
            mutation: REMOVE_PROJECT,
            variables: {
              id: that.project.id
            },
            update: (store, { data: { removeProject } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PROJECT_LIST
              })

              var idx = that._.findIndex(data.projects, function(item) {
                return item.id === removeProject
              })

              data.projects.splice(idx, 1)

              // Add our requirement from the mutation to the end
              // data.projectCategory.lineItems.push(createProjectLine)
              // Write our data back to the cache.
              store.writeQuery({
                query: PROJECT_LIST,
                data
              })
            }
          })
          .then(() => {
            this.closeRemoveDialog(true)

            this.$router.replace({ name: 'ProjectList' })
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'An error occured please try again later'
            })
            this.processing = false
          })
      }
    }
  }
}
</script>

<style></style>
