<template>
  <div class="app-layout">
    <router-view />
  </div>
</template>

<script>
import BUDGET from '@/graphql/Budget.gql'

export default {
  name: 'FinanceBudgetDetailLayout',
  components: {},
  data: () => ({}),
  apollo: {
    budget: {
      query: BUDGET,
      variables() {
        return {
          id: this.$route.params.budgetID
        }
      },
      fetchPolicy: 'cache-only'
      // update (data) {
      //   return data.budget
      // }
    }
  },
  created() {},
  methods: {}
}
</script>

<style scoped>
.nice {
  color: var(--v-primary-base);
}

.page-wrap {
  --page-header-height: 112px;
  /* --page-wrap-offset: -20px; */
}
</style>
