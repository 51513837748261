<template>
  <div class="page-wrap">
    <FinanceSettingsToolbar> </FinanceSettingsToolbar>
    <div class="main-page-column">
      <div class="scroll-container pt-0">
        <v-row justify="center">
          <v-col xs="12" sm="12" md="10" lg="10" xl="8">
            <v-row class="mb-5">
              <v-col cols="12" class="text-center">
                <h1 class="title">General info</h1>
                <h4 class="subtitle-1">
                  Basic company info, such as the company name and address
                </h4>
              </v-col>

              <v-col cols="12">
                <v-card v-if="!$apollo.loading" flat class="mx-auto">
                  <v-card-title class="primary--text">Daily Paper</v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card v-if="!$apollo.loading" flat class="mx-auto">
                  <v-card-title class="primary--text"
                    >Contact Info</v-card-title
                  >
                  <v-card-text>
                    <div>Email</div>
                    <div>info@dailypaperclothing.com</div>
                    <v-divider class="my-4"></v-divider>
                    <div>Phone</div>
                    <div>+31203348970</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceSettingsToolbar from '@/modules/finance/components/base/FinanceSettingsToolbar.vue'

export default {
  name: 'FinanceSettingsGeneral',
  components: {
    FinanceSettingsToolbar
  },
  data: () => ({
    dialog: false,
    edit: false,
    search: '',
    defaultItem: {
      id: '',
      name: '',
      isoName: '',
      symbol: '',
      exchangRate: '',
      defaultCurrency: false,
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      name: '',
      isoName: '',
      symbol: '',
      exchangRate: '',
      defaultCurrency: false,
      updatedAt: new Date(),
      createdAt: new Date()
    }
  }),
  computed: {
    authLevel() {
      return this.$store.getters.authLevel('finance_settings')
    }
  },
  apollo: {
    // currencies: CURRENCY_LIST
  },
  methods: {
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Currency Saved'
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    }
  }
}
</script>

<style></style>
