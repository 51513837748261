<template>
  <div class="page-wrap">
    <finance-budget-detail-header :budget="budget">
      <template v-slot:controls>
        <v-btn
          v-if="
            budget &&
              budget.owner &&
              currentUser &&
              (budget.status === 'draft' ||
                budget.status === 'pendingchanges') &&
              currentUser.id === budget.owner.id
          "
          fab
          small
          absolute
          right
          bottom
          color="info darken-1"
          @click="newCategory()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </finance-budget-detail-header>
    <div class="main-page-column pt-0">
      <budget-edit
        :open="dialog"
        :edited-item="editedItem"
        :edit-mode="edit"
        @close-edit-budget-dialog="close"
      ></budget-edit>
      <budget-category-dialog
        :open="categoryDialog"
        :edited-item="editedCategoryItem"
        :edit-mode="edit"
        @close-budget-category-dialog="close"
      ></budget-category-dialog>
      <budget-line-dialog
        :open="lineItemDialog"
        :edited-item="editedLineItem"
        :edit-mode="edit"
        @close-budget-line-dialog="close"
      ></budget-line-dialog>
      <budget-line-adjust-dialog
        :open="adjustLineItemDialog"
        :edited-item="editedLineItem"
        :edit-mode="edit"
        @close-budget-line-dialog="close"
      ></budget-line-adjust-dialog>
      <v-dialog v-model="removeLineItemDialog" persistent max-width="350">
        <v-card flat>
          <v-card-title class="headline error--text mb-3"
            >Remove Item</v-card-title
          >
          <v-card-text>
            <p>Are you sure your want to delete this item.</p>
            <p>All deletions are final and can't be reversed.</p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="accent darken-1"
              text
              @click="removeLineItemDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="error" text @click="removeBudgetLine">Remove</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="removeCategoryItemDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Category</v-card-title
          >
          <v-card-text>
            <p>Are you sure your want to delete this category.</p>
            <p>This will also delete all the line items.</p>
            <p>All deletions are final and can't be reversed.</p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="accent darken-1"
              text
              @click="removeCategoryItemDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="error" text @click="removeBudgetCategory"
              >Remove</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="detailDialog" persistent fullscreen scrollable>
        <v-card>
          <v-card-title class="mb-3">
            <v-row>
              <v-col>
                <div class="headline mb-5">Details</div>
                <div>
                  <div class="caption grey--text">
                    Line
                  </div>
                  <div class="text-body-1">{{ selectedLine.title }}</div>
                </div>
              </v-col>
              <v-col cols="1">
                <v-row justify="end">
                  <v-btn icon @click="closeDetailDialog"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <div class="text-h6 font-weight-regular mb-3">Projects</div>
            <div>
              <project-all-list-items
                :data="projects"
                :loading="processing"
              ></project-all-list-items>
            </div>
            <div class="text-h6 font-weight-regular mb-3 mt-5">
              Purchase Orders
            </div>
            <div>
              <purchase-order-all-list-items
                :data="purchaseOrders"
                :loading="processing"
              ></purchase-order-all-list-items>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div class="scroll-container pt-0">
        <!-- Summary boxes -->
        <v-row v-if="budget" justify="center">
          <v-col v-if="budget" cols="12" class="pt-0">
            <v-row>
              <v-col cols="12" md="3" class="pb-0">
                <v-card flat height="100%">
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Forecast</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0 pl-4">
                    <v-row no-gutters>
                      <v-col v-if="budget" class="pl-4 my-3">
                        <div class="text-h5">
                          {{
                            (budget.forecastAmount / 100)
                              | currency(budget.currency.symbol, 2, {
                                spaceBetweenAmountAndSymbol: true
                              })
                          }}
                        </div>
                        <div
                          v-if="budget.adjustmentAmount"
                          class="text-caption"
                        >
                          New:
                          <span class="font-weight-bold info--text">{{
                            budgetAdjustment
                              | currency(budget.currency.symbol, 2, {
                                spaceBetweenAmountAndSymbol: true
                              })
                          }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" class="pb-0">
                <v-card flat height="100%">
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >In Progress</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row no-gutters>
                      <v-col v-if="budget" class="pl-4 my-3 text-h5">{{
                        (budget.pendingAmount / 100)
                          | currency(budget.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" class="pb-0">
                <v-card flat height="100%">
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Actual</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row no-gutters>
                      <v-col v-if="budget" class="pl-4 my-3 text-h5">{{
                        (budget.actualAmount / 100)
                          | currency(budget.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" class="pb-0">
                <v-card flat height="100%">
                  <v-card-title class="pb-0">
                    <span
                      class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                      >Balance</span
                    >
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row no-gutters>
                      <v-col
                        v-if="budget"
                        class="pl-4 my-3 text-h5 info--text darken-1"
                        >{{
                          (budget.balanceAmount / 100)
                            | currency(budget.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- Categories -->
        <v-row v-if="budget" justify="center">
          <v-col cols="12">
            <v-expansion-panels multiple>
              <v-expansion-panel
                v-for="item in budgetCategories"
                :key="item.id"
                class="mb-1"
              >
                <v-expansion-panel-header v-slot="{ open }" class="py-2">
                  <v-row no-gutters>
                    <v-col cols="12" md="3">
                      <v-row no-gutters align="center">
                        <div
                          class="text-body-1 text-truncate primary--text font-weight-medium pt-1 pb-1"
                        >
                          {{ item.name }}
                        </div>

                        <v-btn
                          v-if="
                            open &&
                              budget &&
                              budget.owner &&
                              currentUser &&
                              (budget.status === 'draft' ||
                                budget.status === 'pendingchanges') &&
                              currentUser.id === budget.owner.id
                          "
                          left
                          small
                          icon
                          class="ml-3"
                          color="info"
                          @click.stop="editCategory(item)"
                        >
                          <v-icon small>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="
                            open &&
                              budget &&
                              budget.owner &&
                              currentUser &&
                              (budget.status === 'draft' ||
                                budget.status === 'pendingchanges') &&
                              currentUser.id === budget.owner.id
                          "
                          small
                          text
                          rounded
                          color="grey"
                          class="text-lowercase"
                          @click.stop="openRemoveBudgetCategoryDialog(item)"
                        >
                          delete
                        </v-btn>
                      </v-row>

                      <v-row
                        v-if="
                          open &&
                            budget &&
                            budget.owner &&
                            currentUser &&
                            (budget.status === 'draft' ||
                              budget.status === 'pendingchanges') &&
                            currentUser.id === budget.owner.id
                        "
                        no-gutters
                        class="mt-5"
                      >
                        <v-btn
                          small
                          rounded
                          color="info"
                          class="mr-3 text-capitalize"
                          @click.stop="newBudgetLine(item)"
                        >
                          <v-icon left>mdi-playlist-plus</v-icon>Add Line Item
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="8" class="text--secondary py-0">
                      <v-fade-transition leave-absolute>
                        <v-row v-if="open">
                          <v-col class="py-0">
                            <v-card flat>
                              <v-card-text>
                                <div
                                  class="text-caption text-uppercase grey--text font-weight-medium"
                                >
                                  Forecast
                                </div>
                                <div class="text-body-1 font-weight-medium">
                                  {{
                                    lineForecastAmount(item.lineItems || [])
                                      | currency(budget.currency.symbol, 2, {
                                        spaceBetweenAmountAndSymbol: true
                                      })
                                  }}
                                </div>
                                <div
                                  v-if="
                                    categoryAdjustment(item.lineItems || []) !==
                                      0
                                  "
                                  class="text-caption"
                                >
                                  New:
                                  <span class="font-weight-bold info--text">{{
                                    categoryAdjustment(item.lineItems || [])
                                      | currency(budget.currency.symbol, 2, {
                                        spaceBetweenAmountAndSymbol: true
                                      })
                                  }}</span>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col class="py-0 hidden-sm-only">
                            <v-card flat>
                              <v-card-text>
                                <div
                                  class="text-caption text-uppercase grey--text font-weight-medium"
                                >
                                  In Progress
                                </div>
                                <div class="text-body-1 font-weight-medium">
                                  {{
                                    linePendingAmount(item.lineItems || [])
                                      | currency(budget.currency.symbol, 2, {
                                        spaceBetweenAmountAndSymbol: true
                                      })
                                  }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col class="py-0 hidden-sm-only">
                            <v-card flat>
                              <v-card-text>
                                <div
                                  class="text-caption text-uppercase grey--text font-weight-medium"
                                >
                                  Actual
                                </div>
                                <div class="text-body-1 font-weight-medium">
                                  {{
                                    lineActualAmount(item.lineItems || [])
                                      | currency(budget.currency.symbol, 2, {
                                        spaceBetweenAmountAndSymbol: true
                                      })
                                  }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col class="py-0">
                            <v-card flat>
                              <v-card-text>
                                <div
                                  class="text-caption text-uppercase grey--text font-weight-medium"
                                >
                                  Balance
                                </div>
                                <div
                                  class="text-body-1 font-weight-medium info--text"
                                >
                                  {{
                                    lineBalanceAmount(item.lineItems || [])
                                      | currency(budget.currency.symbol, 2, {
                                        spaceBetweenAmountAndSymbol: true
                                      })
                                  }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="1" class="py-0 hidden-sm-only"
                            >&nbsp;</v-col
                          >
                        </v-row>
                        <v-row v-else align="center" class="pt-1">
                          <v-col class="py-0">
                            <div class="text-caption text-uppercase grey--text">
                              Forecast
                            </div>
                            <div class="mt-2">
                              {{
                                lineForecastAmount(item.lineItems || [])
                                  | currency(budget.currency.symbol, 2, {
                                    spaceBetweenAmountAndSymbol: true
                                  })
                              }}
                            </div>
                            <div
                              v-if="
                                lineAdjustmentAmount(item.lineItems || []) > 0
                              "
                              class="text-caption"
                            >
                              New:
                              <span class="font-weight-bold info--text">{{
                                lineAdjustmentAmount(item.lineItems || [])
                                  | currency(budget.currency.symbol, 2, {
                                    spaceBetweenAmountAndSymbol: true
                                  })
                              }}</span>
                            </div></v-col
                          >
                          <v-col class="py-0 hidden-sm-only"
                            ><div
                              class="text-caption text-uppercase grey--text"
                            >
                              In Progress
                            </div>
                            <div class="mt-2">
                              {{
                                linePendingAmount(item.lineItems || [])
                                  | currency(budget.currency.symbol, 2, {
                                    spaceBetweenAmountAndSymbol: true
                                  })
                              }}
                            </div></v-col
                          >
                          <v-col class="py-0 hidden-sm-only"
                            ><div
                              class="text-caption text-uppercase grey--text"
                            >
                              Actual
                            </div>
                            <div class="mt-2">
                              {{
                                lineActualAmount(item.lineItems || [])
                                  | currency(budget.currency.symbol, 2, {
                                    spaceBetweenAmountAndSymbol: true
                                  })
                              }}
                            </div></v-col
                          >
                          <v-col class="py-0"
                            ><div
                              class="text-caption text-uppercase grey--text"
                            >
                              Balance
                            </div>
                            <div class="mt-2 info--text">
                              {{
                                lineBalanceAmount(item.lineItems || [])
                                  | currency(budget.currency.symbol, 2, {
                                    spaceBetweenAmountAndSymbol: true
                                  })
                              }}
                            </div></v-col
                          >
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    v-if="item.lineItems && item.lineItems.length > 0"
                    class="pb-0 caption"
                    style="border-bottom: 1px solid #ccc"
                  >
                    <v-col
                      cols="3"
                      class="text-uppercase grey--text font-weight-medium pb-0 pt-6"
                      >Item</v-col
                    >
                    <v-col cols="8">
                      <v-row>
                        <v-col
                          class="text-uppercase text-truncate grey--text font-weight-medium pb-0"
                          >Forecast</v-col
                        >
                        <v-col
                          class="text-uppercase text-truncate grey--text font-weight-medium pb-0 hidden-sm-only"
                          >In Progress</v-col
                        >
                        <v-col
                          class="text-uppercase text-truncate grey--text font-weight-medium pb-0 hidden-sm-only"
                          >Actual</v-col
                        >
                        <v-col
                          class="text-uppercase text-truncate grey--text font-weight-medium pb-0"
                          >Balance</v-col
                        >
                        <v-col cols="1" class="pb-0 hidden-sm-only">
                          &nbsp;
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="1"></v-col>
                  </v-row>
                  <v-row
                    v-for="(line, idx) in item.lineItems"
                    :key="idx"
                    class="price-line-item"
                    align="center"
                  >
                    <v-col cols="3">{{ line.title }}</v-col>
                    <v-col cols="8" class="py-0">
                      <v-row align="center">
                        <v-col>
                          <div>
                            {{
                              (line.forecastAmount / 100)
                                | currency(budget.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}
                          </div>
                          <div
                            v-if="
                              line.adjustmentAmount && line.adjustmentAmount > 0
                            "
                            class="text-caption"
                          >
                            New:
                            <span class="font-weight-bold info--text">{{
                              (line.adjustmentAmount / 100)
                                | currency(budget.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}</span>
                          </div></v-col
                        >
                        <v-col class="hidden-sm-only">{{
                          (line.pendingAmount / 100)
                            | currency(budget.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col>
                        <v-col class="hidden-sm-only">{{
                          (line.actualAmount / 100)
                            | currency(budget.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col>
                        <v-col>{{
                          (line.balanceAmount / 100)
                            | currency(budget.currency.symbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</v-col>
                        <v-col cols="1" class="hidden-sm-only">
                          <v-btn
                            v-show="line.forecastAmount !== line.balanceAmount"
                            text
                            small
                            color="info"
                            class="text-lowercase"
                            :loading="processing"
                            @click="viewLineItemDetails(line)"
                            >details</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                      <v-row justify="end" align="center">
                        <v-btn
                          v-if="
                            budget &&
                              budget.owner &&
                              currentUser &&
                              (budget.status === 'approved' ||
                                budget.status === 'pendingadjustment') &&
                              currentUser.id === budget.owner.id
                          "
                          x-small
                          rounded
                          text
                          class="mr-2"
                          color="info"
                          @click="adjustBudgetLine(line)"
                        >
                          adjust
                        </v-btn>

                        <v-btn
                          v-if="
                            budget &&
                              budget.owner &&
                              currentUser &&
                              (budget.status === 'draft' ||
                                budget.status === 'pendingchanges') &&
                              currentUser.id === budget.owner.id
                          "
                          fab
                          x-small
                          elevation="0"
                          class="mr-2"
                          @click="editBudgetLine(line)"
                        >
                          <v-icon x-small color="info"
                            >mdi-pencil-outline</v-icon
                          >
                        </v-btn>
                        <v-btn
                          v-if="
                            budget &&
                              budget.owner &&
                              currentUser &&
                              (budget.status === 'draft' ||
                                budget.status === 'pendingchanges') &&
                              currentUser.id === budget.owner.id
                          "
                          fab
                          x-small
                          elevation="0"
                          @click="openRemoveBudgetLineDialog(line)"
                        >
                          <v-icon x-small color="error"
                            >mdi-trash-can-outline</v-icon
                          >
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import BUDGET from '@/graphql/Budget.gql'
import BUDGET_CATEGORIES from '@/graphql/BudgetCategories.gql'
import PROJECTS_BY_LINE from '@/graphql/ProjectsByBudgetLineID.gql'
import PURCHASE_ORDERS_BY_LINE from '@/graphql/PurchaseOrdersByBudgetLineID.gql'
import REMOVE_BUDGET_LINE from '@/graphql/BudgetLineRemove.gql'
import REMOVE_CATEGORY from '@/graphql/BudgetCategoryRemove.gql'
import BudgetEdit from '@/modules/finance/components/core/BudgetEdit.vue'
import BudgetCategoryDialog from '@/modules/finance/components/core/BudgetCategoryDialog.vue'
import BudgetLineDialog from '@/modules/finance/components/core/BudgetLineDialog.vue'
import BudgetLineAdjustDialog from '@/modules/finance/components/core/BudgetLineAdjustDialog.vue'
import FinanceBudgetDetailHeader from '@/modules/finance/components/core/FinanceBudgetDetailHeader.vue'
import ProjectAllListItems from '@/modules/finance/components/core/ProjectAllListItems.vue'
import PurchaseOrderAllListItems from '@/modules/finance/components/core/PurchaseOrderAllListItems.vue'

export default {
  name: 'BudgetCosting',
  components: {
    FinanceBudgetDetailHeader,
    BudgetEdit,
    BudgetCategoryDialog,
    BudgetLineDialog,
    BudgetLineAdjustDialog,
    ProjectAllListItems,
    PurchaseOrderAllListItems
  },
  data: () => ({
    money: {
      decimal: '.',
      thousands: ',',
      precision: 2,
      masked: false
    },
    defaultItem: {
      title: '',
      description: '',
      department: {},
      financialYear: {},
      pricing: {}
    },
    editedItem: {
      title: '',
      description: '',
      department: {},
      financialYear: {},
      pricing: {}
    },
    defaultCategoryItem: {
      id: '',
      budgetID: '',
      name: '',
      description: ''
    },
    editedCategoryItem: {
      id: '',
      budgetID: '',
      name: '',
      description: ''
    },
    defaultLineItem: {
      budgetID: '',
      budgetCategoryID: '',
      title: '',
      description: '',
      pricing: {
        displayAmount: 0
      }
    },
    editedLineItem: {
      budgetID: '',
      budgetCategoryID: '',
      title: '',
      description: '',
      pricing: {
        displayAmount: 0
      }
    },
    deleteItem: '',
    edit: false,
    dialog: false,
    categoryDialog: false,
    lineItemDialog: false,
    removeLineItemDialog: false,
    adjustLineItemDialog: false,
    removeCategoryItemDialog: false,
    detailDialog: false,
    projects: [],
    purchaseOrders: [],
    selectedLine: {},
    processing: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    budgetAdjustment() {
      var vm = this
      var amount = 0
      // Count categories
      vm._.each(vm.budgetCategories, item => {
        vm._.each(item.lineItems, line => {
          if (line.adjustmentAmount && line.adjustmentAmount > 0) {
            amount = amount + line.adjustmentAmount
          } else {
            amount = amount + line.forecastAmount
          }
        })
      })
      return amount / 100
    }
  },
  apollo: {
    budget: {
      query: BUDGET,
      variables() {
        return {
          id: this.$route.params.budgetID
        }
      },
      fetchPolicy: 'network-only'
      // update (data) {
      //   return data.budget
      // }
    },
    budgetCategories: {
      query: BUDGET_CATEGORIES,
      variables() {
        return {
          budgetID: this.$route.params.budgetID
        }
      },
      fetchPolicy: 'network-only'
      // update (data) {
      //   return data.budget
      // }
    }
  },
  methods: {
    categoryAdjustment(items) {
      var vm = this
      var adjustmentCount = 0
      var amount = 0
      // Count categories
      if (items && items.length > 0) {
        vm._.each(items, line => {
          if (line.adjustmentAmount && line.adjustmentAmount !== 0) {
            amount = amount + line.adjustmentAmount
            adjustmentCount++
          } else {
            amount = amount + line.forecastAmount
          }
        })
      }

      if (adjustmentCount > 0) {
        return amount / 100
      } else {
        return 0
      }
    },
    stateColor: item => {
      if (item === 'draft') {
        return 'info'
      } else if (item === 'rejected') {
        return 'error'
      } else if (item === 'approved') {
        return 'success'
      }
    },
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.editedCategoryItem = this._.cloneDeep(this.defaultCategoryItem)
      this.editedLineItem = this._.cloneDeep(this.defaultLineItem)
      this.dialog = false
      this.categoryDialog = false
      this.lineItemDialog = false
      this.adjustLineItemDialog = false
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })
      }
      this.$apollo.queries.budget.refetch()
      this.$apollo.queries.budgetCategories.refetch()
    },
    closeDetailDialog() {
      this.processing = false
      this.detailDialog = false
      this.projects = []
      this.purchaseOrders = []
      this.selectedLine = {}
    },
    editItem() {
      this.edit = true
      this.editedItem = this._.cloneDeep(this.budget)
      this.dialog = true
    },
    editCategory(item) {
      this.edit = true
      this.editedCategoryItem = this._.cloneDeep(item)
      this.categoryDialog = true
    },
    newCategory() {
      this.edit = false
      this.defaultCategoryItem.budgetID = this.budget.id
      this.editedCategoryItem = this._.cloneDeep(this.defaultCategoryItem)
      this.categoryDialog = true
    },
    editBudgetLine(item) {
      this.edit = true
      item.displayAmount = item.displayAmount || 0
      this.editedLineItem = this._.cloneDeep(item)
      this.editedLineItem.forecastAmount =
        this.editedLineItem.forecastAmount / 100
      this.lineItemDialog = true
    },
    adjustBudgetLine(item) {
      this.edit = true
      item.displayAmount = item.displayAmount || 0
      this.editedLineItem = this._.cloneDeep(item)
      this.editedLineItem.adjustmentAmount =
        this.editedLineItem.adjustmentAmount / 100
      this.adjustLineItemDialog = true
    },
    newBudgetLine(item) {
      this.edit = false
      this.defaultLineItem.currency = this.budget.currency
      this.editedLineItem = this._.cloneDeep(this.defaultLineItem)
      this.editedLineItem.budgetID = this.budget.id
      this.editedLineItem.budgetCategoryID = item.id

      this.lineItemDialog = true
    },
    openRemoveBudgetCategoryDialog(item) {
      this.deleteItem = item
      this.removeCategoryItemDialog = true
    },
    removeBudgetCategory() {
      var that = this
      this.$apollo
        .mutate({
          mutation: REMOVE_CATEGORY,
          variables: {
            id: that.deleteItem.id
          },
          update: (store, { data: { removeBudgetCategory } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: BUDGET_CATEGORIES,
              variables: {
                budgetID: that.$route.params.budgetID
              }
            })

            var idx = that._.findIndex(data.budgetCategories, function(item) {
              return item.id === removeBudgetCategory
            })

            data.budgetCategories.splice(idx, 1)

            // Add our requirement from the mutation to the end
            // data.budgetCategory.lineItems.push(createBudgetLine)
            // Write our data back to the cache.
            store.writeQuery({
              query: BUDGET_CATEGORIES,
              variables: {
                budgetID: that.$route.params.budgetID
              },
              data
            })
          }
        })
        .then(() => {
          this.$apollo
            .query({
              query: BUDGET,
              variables: {
                id: that.$route.params.budgetID
              },
              fetchPolicy: 'network-only'
            })
            .then(() => {
              this.removeCategoryItemDialog = false
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Item Removed'
              })
              this.deleteItem = ''
              this.$apollo.queries.budget.refetch()
            })
        })
    },
    openRemoveBudgetLineDialog(item) {
      this.deleteItem = item
      this.removeLineItemDialog = true
    },
    removeBudgetLine() {
      var that = this
      this.$apollo
        .mutate({
          mutation: REMOVE_BUDGET_LINE,
          variables: {
            id: this.deleteItem.id
          },
          update: (store, { data: { removeBudgetLine } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: BUDGET,
              variables: {
                id: that.deleteItem.budgetID
              }
            })

            // Update the price
            data.budget.forecastAmount =
              data.budget.forecastAmount - that.deleteItem.forecastAmount

            const lineData = store.readQuery({
              query: BUDGET_CATEGORIES,
              variables: {
                budgetID: that.deleteItem.budgetID
              }
            })

            var idx = that._.findIndex(lineData.budgetCategories, function(
              item
            ) {
              return item.id === that.deleteItem.budgetCategoryID
            })

            var itemIdx = that._.findIndex(
              lineData.budgetCategories[idx].lineItems,
              function(item) {
                return item.id === removeBudgetLine
              }
            )

            lineData.budgetCategories[idx].lineItems.splice(itemIdx, 1)

            // Write our data back to the cache.
            store.writeQuery(
              { query: BUDGET, data },
              {
                query: BUDGET_CATEGORIES,
                variables: {
                  budgetID: that.deleteItem.budgetID
                },
                lineData
              }
            )
          }
        })
        .then(() => {
          this.removeLineItemDialog = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Item Removed'
          })
          this.deleteItem = ''
          this.$apollo.queries.budget.refetch()
        })
    },
    lineForecastAmount(items) {
      var that = this
      let amount = 0
      if (items && items.length > 0) {
        that._.forEach(items, function(item) {
          let cents
          if (
            typeof item.forecastAmount === 'number' &&
            isFinite(item.forecastAmount)
          ) {
            cents = item.forecastAmount / 100
          }
          if (
            typeof item.forecastAmount === 'string' ||
            item.forecastAmount instanceof String
          ) {
            cents =
              parseFloat(
                item.forecastAmount.replace(/\./g, '').replace(/,/g, '.')
              ) * 1000
          }

          amount = amount + cents
        })
      }

      return amount
    },
    lineAdjustmentAmount(items) {
      var that = this
      let amount = 0
      if (items && items.length > 0) {
        that._.forEach(items, function(item) {
          let cents
          if (
            typeof item.adjustmentAmount === 'number' &&
            isFinite(item.adjustmentAmount)
          ) {
            cents = item.adjustmentAmount / 100
          }
          if (
            typeof item.adjustmentAmount === 'string' ||
            item.adjustmentAmount instanceof String
          ) {
            cents =
              parseFloat(
                item.adjustmentAmount.replace(/\./g, '').replace(/,/g, '.')
              ) * 1000
          }

          amount = amount + cents
        })
      }

      return amount
    },
    linePendingAmount(items) {
      let amount = 0
      if (items && items.length > 0) {
        this._.forEach(items, function(item) {
          amount = amount + item.pendingAmount / 100
        })
      }

      return amount
    },
    lineActualAmount(items) {
      let amount = 0
      if (items && items.length > 0) {
        this._.forEach(items, function(item) {
          amount = amount + item.actualAmount / 100
        })
      }

      return amount
    },
    lineBalanceAmount(items) {
      let amount = 0
      if (items && items.length > 0) {
        this._.forEach(items, function(item) {
          amount = amount + item.balanceAmount / 100
        })
      }

      return amount
    },
    viewLineItemDetails(item) {
      this.selectedLine = item

      this.processing = true
      this.$apollo
        .query({
          query: PROJECTS_BY_LINE,
          variables: {
            budgetLineID: item.id
          },
          fetchPolicy: 'network-only'
        })
        .then(result => {
          this.projects = result.data?.projectsByBudgetLineID || []
          this.$apollo
            .query({
              query: PURCHASE_ORDERS_BY_LINE,
              variables: {
                budgetLineID: item.id
              },
              fetchPolicy: 'network-only'
            })
            .then(result => {
              this.purchaseOrders =
                result.data?.purchaseOrdersByBudgetLineID || []
              this.processing = false
              this.detailDialog = true
            })
            .catch(() => {
              this.processing = false
              this.errorMsg =
                'A general server error occured. Please try again later'
            })
        })
        .catch(() => {
          this.processing = false
          this.errorMsg =
            'A general server error occured. Please try again later'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  // border: 2px solid rgba(0, 0, 0, 0.12); // var(--v-secondary-lighten3);
  border: 2px solid transparent;
  border-radius: 4px !important;

  &:hover {
    // border-color: var(--v-primary-lighten3);
    border-color: rgba(0, 0, 0, 0.12);
  }

  &:before {
    box-shadow: none !important;
  }

  &:after {
    border: none !important;
  }
}

.v-expansion-panel--active {
  // border-color: var(--v-primary-lighten3);
  border-color: rgba(0, 0, 0, 0.12);

  &:hover {
    // border-color: var(--v-primary-lighten3);
    border-color: rgba(0, 0, 0, 0.12);
  }
}

.price-line-item {
  border-bottom: 1px solid #ccc;
  &:last-of-type {
    border-bottom: none;
  }
}
</style>
