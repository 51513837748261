<template>
  <div class="page-wrap">
    <financial-year-edit
      :edited-item="editedItem"
      :open="dialog"
      :edit-mode="edit"
      @close-edit-financial-year-dialog="close"
    ></financial-year-edit>
    <FinanceSettingsToolbar>
      <template v-slot:controls>
        <v-btn fab small color="primary" @click="newItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </FinanceSettingsToolbar>
    <div class="main-page-column pt-0" style="width: 100%">
      <div class="scroll-container">
        <div class="page-wrap">
          <div class="main-content pt-0">
            <v-row justify="center">
              <v-col xs="12" sm="12" md="10" lg="10" xl="8">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">From</th>
                        <th class="text-left">Until</th>
                        <th class="text-left">Last Update</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in financialYears" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.start | moment('DD/MM/YYYY') }}</td>
                        <td>{{ item.end | moment('DD/MM/YYYY') }}</td>
                        <td>{{ item.updatedAt | moment('DD/MM/YYYY') }}</td>
                        <td>
                          <v-row justify="end">
                            <v-btn
                              v-if="authLevel === 2"
                              x-small
                              text
                              color="info"
                              @click="editItem(item)"
                            >
                              edit
                            </v-btn>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FINANCIAL_YEAR_LIST from '@/graphql/FinancialYears.gql'
import FinancialYearEdit from '@/modules/finance/components/core/FinancialYearEdit.vue'
import FinanceSettingsToolbar from '@/modules/finance/components/base/FinanceSettingsToolbar.vue'

export default {
  name: 'FinanceSettingsYears',
  components: {
    FinancialYearEdit,
    FinanceSettingsToolbar
  },
  data: () => ({
    dialog: false,
    edit: false,
    search: '',
    defaultItem: {
      id: '',
      name: '',
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      name: '',
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      updatedAt: new Date(),
      createdAt: new Date()
    }
  }),
  computed: {
    authLevel() {
      return this.$store.getters.authLevel('finance_settings')
    }
  },
  apollo: {
    financialYears: FINANCIAL_YEAR_LIST
  },
  methods: {
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Financial Year Saved'
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    }
  }
}
</script>

<style></style>
