<template>
  <v-row>
    <v-col>
      <v-card flat>
        <v-card-title class="pt-0">
          <v-text-field
            v-model="search"
            hide-details
            flat
            clearable
            background-color="#fff"
            placeholder="Search..."
            prepend-inner-icon="mdi-magnify"
            autocomplete="off"
            :disabled="!purchaseOrders || purchaseOrders.items.length === 0"
          ></v-text-field>
        </v-card-title>

        <purchase-order-list-items
          :data="purchaseOrders.items"
          :loading="$apollo.queries.purchaseOrders.loading"
          :item-count="itemCount"
          @set-options="setOptions"
        ></purchase-order-list-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import country from 'country-list'
import PurchaseOrderListItems from '@/modules/finance/components/core/PurchaseOrderListItems.vue'
import PURCHASE_ORDERS from '@/graphql/PurchaseOrders.gql'
import DEPARTMENTS from '@/graphql/Departments.gql'

export default {
  name: 'SupplierPurchaseOrders',
  components: { PurchaseOrderListItems },
  props: {
    supplier: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    paymentTerms: { type: Array, default: () => [] },
    authLevel: { type: Number, default: () => 0 },
    currencies: {
      type: Array,
      default: () => []
    }
  },
  apollo: {
    purchaseOrders: {
      query: PURCHASE_ORDERS,
      variables() {
        return {
          filters: {
            search: this.search,
            supplierID: this.$route.params.supplierID,
            status: this.stateFilterValue,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      result(result) {
        this.page = result.data.purchaseOrders.page
        this.itemsPerPage = result.data.purchaseOrders.pageSize
        this.itemCount = result.data.purchaseOrders.total
      }
    },
    departments: DEPARTMENTS
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [
      v => !!v || 'Field is required',
      v => !!v.id || 'Field is required'
    ],

    // Filters
    search: null,
    purchaseOrders: { items: [] },
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    stateFilterValue: null,
    stateList: ['draft', 'approved', 'rejected', 'review', 'closed'],

    editDialog: false,
    processing: false,
    editMode: false,
    errorMsg: '',
    editTerm: {
      percentage: 100,
      days: 30,
      description: null
    },
    purchaseOrdersBySupplier: [],
    defaultItem: {
      id: null,
      supplierCode: null,
      name: null,
      email: null,
      phone: null,
      paymentTerm: null,
      bankAccount: null,
      swift: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null,
      status: null
    },
    editedItem: {
      id: null,
      supplierCode: null,
      name: null,
      email: null,
      phone: null,
      paymentTerm: null,
      bankAccount: null,
      swift: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null,
      status: null
    }
  }),
  computed: {
    item() {
      this.setEditedItem()
      return this._.cloneDeep(this.supplier)
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    }
  },
  methods: {
    setOptions(e) {
      this.options = e
    },
    getFullCountryName(code) {
      return country.getName(code)
    }
  }
}
</script>

<style scoped lang="scss">
.team-selection >>> .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.size-delimiter {
  &:last-child {
    span {
      visibility: hidden;
    }
  }
}
</style>
