<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="500"
    >
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="financialYearEditForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12" class="pb-0">Name</v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.name"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  placeholder="Name"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12" class="pb-0">Start Date</v-col>
                  <v-col cols="12">
                    <v-menu
                      ref="menu1"
                      v-model="startDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formattedStartDate"
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-month-outline"
                          autocomplete="off"
                          hide-details="auto"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.start"
                        no-title
                        @input="startDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12" class="pb-0">End Date</v-col>
                  <v-col cols="12">
                    <v-menu
                      ref="menu2"
                      v-model="endDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formattedEndDate"
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-month-outline"
                          autocomplete="off"
                          hide-details="auto"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.end"
                        :min="item.start"
                        no-title
                        @input="endDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text rounded @click="close(false)">Cancel</v-btn>
          <v-btn color="primary" rounded @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FINANCIAL_YEAR_LIST from '@/graphql/FinancialYears.gql'
import CREATE_FINANCIAL_YEAR from '@/graphql/FinancialYearCreate.gql'
import UPDATE_FINANCIAL_YEAR from '@/graphql/FinancialYearUpdate.gql'

export default {
  name: 'FinancialYearEdit',
  props: {
    editedItem: { type: Object, default: null },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    disable: false,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    startDate: false,
    endDate: false,
    dateRule: [v => !!v || 'Date is required']
  }),
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        // if (val) {
        //   return true
        // }
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Financial Year' : 'New Financial Year'
    },
    formattedStartDate() {
      return this.item.start ? this.$moment(this.item.start).format('LL') : ''
    },
    formattedEndDate() {
      // this.$moment().locale('nl')
      if (!this.item.start) {
        return this.item.end ? this.$moment(this.item.end).format('LL') : ''
      } else if (this.item.end < this.item.start) {
        return this.$moment(this.item.start).format('LL')
      } else {
        return this.item.end
          ? this.$moment(this.item.end).format('LL')
          : this.$moment(this.item.start).format('LL')
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  methods: {
    close(success) {
      // if (!this.editMode) {
      //   this.$refs.userForm.reset()
      // }
      this.$emit('close-edit-financial-year-dialog', success)
    },

    save() {
      if (this.editMode) {
        // Edit item
        let item = {
          id: this.item.id,
          name: this.item.name,
          start: this.$moment(this.item.start).utc(),
          end: this.$moment(this.item.end).utc()
        }
        // validate
        if (this.$refs.financialYearEditForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_FINANCIAL_YEAR,
              variables: {
                input: item
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        // Save new item
        let item = {
          name: this.item.name,
          start: this.$moment(this.item.start).utc(),
          end: this.$moment(this.item.end).utc()
        }
        // validate
        if (this.$refs.financialYearEditForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_FINANCIAL_YEAR,
              variables: {
                input: item
              },
              update: (store, { data: { createFinancialYear } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: FINANCIAL_YEAR_LIST
                })
                // Add our requirement from the mutation to the end
                data.financialYears.push(createFinancialYear)
                // Write our data back to the cache.
                store.writeQuery({ query: FINANCIAL_YEAR_LIST, data })
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      }
    }
    // disable () {
    //   const val = this.editedItem.defaultCurrency
    //   if (val) {
    //     return true
    //   }
    //   return false
    // }
  }
}
</script>

<style></style>
