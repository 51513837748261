<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    transition="dialog-bottom-transition"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <div v-if="item" class="scroll-container pt-0">
          <v-form
            ref="projectLineForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12" class="pb-0">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Title</label>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-model="item.title"
                          autocomplete="off"
                          placeholder="Title"
                          outlined
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" class="pb-0">
                    <label>Description</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <vue-editor
                      id="edit-project-description"
                      v-model="item.description"
                      :editor-options="editorConfig"
                      placeholder="A short description, which describes what the project is for."
                      class="pb-3"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-alert outlined type="info">
                      <div>
                        Available Budget Balance:
                        <span
                          v-if="budgetLine && budgetLine.pricing"
                          :class="[
                            budgetLine.pricing.balanceAmount > 0
                              ? 'success--text'
                              : 'error--text'
                          ]"
                          >{{
                            (budgetLine.pricing.balanceAmount / 100)
                              | currency(
                                budgetLine.pricing.currency.symbol,
                                2,
                                {
                                  spaceBetweenAmountAndSymbol: true
                                }
                              )
                          }}</span
                        >
                      </div>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" lg="6" class="py-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Currency</label>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-select
                          v-model="item.currency"
                          :items="currencies"
                          :rules="requiredRule"
                          outlined
                          autocomplete
                          return-object
                          item-text="name"
                          disabled
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" class="py-0">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Price</label>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-currency-field
                          v-model="item.forecastAmount"
                          autocomplete="off"
                          outlined
                          :rules="requiredRule"
                          dense
                        ></v-currency-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="px-5 pb-5">
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-1"
          text
          rounded
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="primary darken-1"
          rounded
          small
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PROJECT from '@/graphql/Project.gql'
import CREATE_PROJECT_LINE from '@/graphql/ProjectLineCreate.gql'
import UPDATE_PROJECT_LINE from '@/graphql/ProjectLineUpdate.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'ProjectLineDialog',
  components: {},
  props: {
    project: { type: Object, default: () => ({}) },
    editedItem: { type: Object, default: () => ({}) },
    editMode: Boolean,
    open: Boolean,
    budgetLine: { type: Object, default: () => ({}) }
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    oldAmount: 0,
    // money: {
    //   decimal: ',',
    //   thousands: '.',
    //   // prefix: '€ ',
    //   precision: 2,
    //   masked: false /* doesn't work with directive */
    // },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ]
  }),
  apollo: {
    currencies: CURRENCY_LIST
  },
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Project Line' : 'New Project Line'
    }
  },
  created() {
    const copy = JSON.parse(JSON.stringify(this.editedItem))
    this.oldAmount = copy.pricing.reportingForecastAmount * 100
  },
  methods: {
    close(success) {
      // this.$refs.price.$el.getElementsByTagName('input')[0].value = 0
      this.$refs.projectLineForm.reset()
      this.$emit('close-project-line-dialog', success)
    },
    save() {
      // Return the price to an int
      var that = this

      // Set the reporting Currency and exchange rate
      var defaultCurrency = this._.find(this.currencies, function(item) {
        return item.defaultCurrency === true
      })

      if (this.editMode) {
        // Make a copy to make sure the new object is not the observable object
        let item = this._.cloneDeep(this.item)

        // Update Item
        let data = {
          id: item.id,
          title: item.title,
          description: item.description,
          projectID: item.projectID,
          projectCategoryID: item.projectCategoryID,
          pricing: {
            currencyID: item.currency.id,
            baseCurrencyID: defaultCurrency.id,
            exchangeRate: item.currency.exchangeRate,
            displayAmount: item.forecastAmount * 100 || 0 // Return the price to an int
          }
        }

        if (this.$refs.projectLineForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_PROJECT_LINE,
              variables: {
                input: data
              }
            })
            .then(result => {
              this.$apollo
                .query({
                  query: PROJECT,
                  variables: {
                    id: result.data.updateProjectLine.projectID
                  },
                  fetchPolicy: 'network-only'
                })
                .then(() => {
                  this.close(true)
                })
            })
        }
      } else {
        let item = this._.cloneDeep(this.item)

        // New Item
        let data = {
          title: item.title,
          description: item.description,
          projectID: item.projectID,
          projectCategoryID: item.projectCategoryID,
          pricing: {
            currencyID: item.currency.id,
            baseCurrencyID: defaultCurrency.id,
            exchangeRate: item.currency.exchangeRate,
            displayAmount: Math.round(item.forecastAmount * 100) || 0 // Return the price to an int
          }
        }

        if (this.$refs.projectLineForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_PROJECT_LINE,
              variables: {
                input: data
              }
            })
            .then(() => {
              that.close(true)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-project-description {
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
