<template>
  <v-dialog v-model="dialog" width="500" transition="dialog-bottom-transition">
    <v-card>
      <v-card-title>
        <span class="headline primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="budgetCategoryForm"
          v-model="valid"
          lazy-validation
          @submit.prevent
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label>Name</label>
                </v-col>
                <v-col cols="12" class="py-2">
                  <v-text-field
                    v-model="item.name"
                    autocomplete="off"
                    placeholder="Name"
                    outlined
                    :rules="requiredRule"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col cols="12" class="pb-2">
              <label>Description</label>
            </v-col>
            <v-col cols="12" class="py-0">
              <vue-editor
                id="edit-budget-description"
                v-model="item.description"
                :editor-options="editorConfig"
                placeholder="A short description, which describes what the budget is for."
                class="pb-3"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row no-gutters class="px-3 pb-3" justify="end">
          <v-btn
            color="grey"
            text
            rounded
            :disabled="$apollo.loading"
            @click="close(false)"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            rounded
            :loading="$apollo.loading"
            @click="save"
            >Save</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BUDGET_CATEGORIES from '@/graphql/BudgetCategories.gql'
import CREATE_BUDGET_CATEGORY from '@/graphql/BudgetCategoryCreate.gql'
import UPDATE_BUDGET_CATEGORY from '@/graphql/BudgetCategoryUpdate.gql'

export default {
  name: 'BudgetCategoryDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    money: {
      decimal: ',',
      thousands: '.',
      // prefix: '€ ',
      precision: 2,
      masked: false /* doesn't work with directive */
    },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ]
  }),
  apollo: {},
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Budget Category' : 'New Budget Category'
    }
  },
  methods: {
    close(success) {
      this.$refs.budgetCategoryForm.reset()
      this.$emit('close-budget-category-dialog', success)
    },
    save() {
      // Return the price to an int

      if (this.editMode) {
        // Make a copy to make sure the new object is not the observable object
        let item = this._.cloneDeep(this.item)

        // Update Item
        let data = {
          id: item.id,
          name: item.name,
          description: item.description,
          budgetID: this.$route.params.budgetID
        }

        if (this.$refs.budgetCategoryForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_BUDGET_CATEGORY,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        // New Item
        let data = {
          name: this.item.name,
          description: this.item.description,
          budgetID: this.$route.params.budgetID
        }

        if (this.$refs.budgetCategoryForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_BUDGET_CATEGORY,
              variables: {
                input: data
              },
              update: (store, { data: { createBudgetCategory } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: BUDGET_CATEGORIES,
                  variables: {
                    budgetID: createBudgetCategory.budgetID
                  }
                })
                // Add our requirement from the mutation to the end
                data.budgetCategories.push(createBudgetCategory)
                // Write our data back to the cache.
                store.writeQuery({
                  query: BUDGET_CATEGORIES,
                  variables: { budgetID: createBudgetCategory.budgetID },
                  data
                })
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-budget-description {
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
