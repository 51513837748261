<template>
  <div class="page-header">
    <v-toolbar
      v-if="!$apollo.loading && purchaseOrder"
      class="mb-0 b-radius"
      extended
      flat
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-row justify="start" align="start">
              <div xs6>
                <h2 class="mb-0 info--text">
                  {{ purchaseOrder.title }}
                </h2>
                <div>
                  <v-chip :color="stateColor(purchaseOrder.status)" x-small>{{
                    purchaseOrder.status
                  }}</v-chip>
                  <span
                    v-if="purchaseOrder.poNumber"
                    class="grey--text text--darken-2 ml-5"
                    >{{ purchaseOrder.poNumber }}</span
                  >
                </div>
              </div>
              <v-spacer></v-spacer>
              <div xs6>
                <v-dialog
                  v-if="
                    purchaseOrder &&
                      purchaseOrder.owner &&
                      currentUser &&
                      (purchaseOrder.status === 'draft' ||
                        purchaseOrder.status === 'pendingchanges') &&
                      currentUser.id === purchaseOrder.owner.id
                  "
                  v-model="dialog"
                  persistent
                  max-width="500"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      small
                      rounded
                      class="ml-3 mt-2 text-capitalize"
                      v-on="on"
                    >
                      <v-icon small left>mdi-send-outline</v-icon
                      ><span
                        v-if="
                          purchaseOrder &&
                            purchaseOrder.status === 'pendingchanges'
                        "
                        >re-</span
                      >submit
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline primary--text mb-3"
                      >Submit for Approval</v-card-title
                    >
                    <v-card-text class="pb-0">
                      <p>This will submit your purchaseOrder for approval.</p>
                      <p>
                        Once submitted you will not be able to make any changes.
                      </p>
                    </v-card-text>
                    <v-card-actions class="pa-5">
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="accent darken-1"
                        text
                        small
                        rounded
                        :disabled="processing"
                        @click="dialog = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary darken-1"
                        small
                        rounded
                        :disabled="processing"
                        @click="submit"
                        >Submit</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-if="
                    purchaseOrder &&
                      purchaseOrder.status === 'approved' &&
                      paymentAuthLevel === 2
                  "
                  v-model="closePurchaseOrderDialog"
                  persistent
                  max-width="500"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      small
                      rounded
                      class="ml-3 mt-2 text-capitalize"
                      v-on="on"
                    >
                      <v-icon small left
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >close order
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline primary--text mb-3"
                      >Close Order</v-card-title
                    >
                    <v-card-text
                      v-if="
                        purchaseOrder.pricing && purchaseOrder.pricing.currency
                      "
                      class="pb-0"
                    >
                      <v-alert type="info" text border="left">
                        No payments can be record afterwords
                      </v-alert>
                      <p>
                        This settles the remaining balance and sets the status
                        to Closed.
                      </p>
                      <p>This can't be undone!</p>
                    </v-card-text>
                    <v-card-actions class="pa-5">
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="accent darken-1"
                        text
                        small
                        rounded
                        :disabled="processing"
                        @click="closePurchaseOrderDialog = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary darken-1"
                        small
                        rounded
                        :loading="processing"
                        @click="closePurchaseOrder"
                        >Submit</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-if="
                    purchaseOrder &&
                    currentUser &&
                    (
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.isAdmin ||
                      currentUser.isSuperAdmin
                    )
                  "
                  v-model="updateOwnerDialog"
                  persistent
                  max-width="450"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      small
                      submit
                      rounded
                      class="mt-2 ml-3"
                      v-on="on"
                    >
                      change owner
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="headline primary--text mb-3"
                      >Transfer Ownership</v-card-title
                    >
                    <v-card-text>
                      <v-form
                        ref="changeOwnerForm"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent
                      >
                        <v-row justify="center">
                          <v-col cols="12" class="pb-0 px-0">
                            <v-row>
                              <v-col v-if="errorMsg" cols="12" class="py-0">
                                <v-alert type="error" text border="left">
                                  {{ errorMsg }}
                                </v-alert>
                              </v-col>

                              <v-col cols="12" class="py-0">
                                <v-alert type="info" text border="left">
                                  Refresh the page after saving to apply the changes
                                </v-alert>
                              </v-col>

                              <v-col cols="12" class="py-0">
                                <v-select
                                  v-model="ownerEdit.owner"
                                  :items="companyUsers"
                                  :rules="requiredSelectRule"
                                  outlined
                                  label="New Owner"
                                  placeholder="Select New Owner"
                                  hide-details="auto"
                                  autocomplete
                                  return-object
                                  :disabled="$apollo.loading"
                                  dense
                                >
                                  <template v-slot:selection="data">
                                  {{data.item.firstName + ' ' + data.item.lastName}}
                                  </template>
                                  <template v-slot:item="data">
                                    {{data.item.firstName + ' ' + data.item.lastName}}
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="accent darken-1"
                        text
                        rounded
                        @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn color="primary" small rounded @click="changeOwner"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:extension>
        <v-tabs
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          background-color="transparent"
          align-with-title
          center-active
          show-arrows
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            :to="{
              name: 'PurchaseOrderDetail',
              params: { purchaseOrderID: $route.params.purchaseOrderID }
            }"
            class="text-capitalize"
            exact
            >Details</v-tab
          >

          <v-tab
            :to="{
              name: 'PurchaseOrderAuth',
              params: { purchaseOrderID: $route.params.purchaseOrderID }
            }"
            class="text-capitalize"
            >Authorisation</v-tab
          >
          <v-tab
            :to="{
              name: 'PurchaseOrderAssets',
              params: { purchaseOrderID: $route.params.purchaseOrderID }
            }"
            class="text-capitalize"
            >Assets</v-tab
          >

          <v-tab
            v-if="purchaseOrder && purchaseOrder.status === 'draft'"
            :to="{
              name: 'PurchaseOrderSettings',
              params: { purchaseOrderID: $route.params.purchaseOrderID }
            }"
            class="text-capitalize"
            >Settings</v-tab
          >
        </v-tabs>

        <slot name="controls"></slot>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
import PURCHASEORDER from '@/graphql/PurchaseOrder.gql'
import SUBMIT_PURCHASEORDER from '@/graphql/PurchaseOrderSubmit.gql'
import REVIEW_PURCHASEORDER from '@/graphql/PurchaseOrderReview.gql'
import CLOSE_PURCHASEORDER from '@/graphql/PurchaseOrderClose.gql'
import UPDATE_PURCHASE_ORDER_OWNER from '@/graphql/PurchaseOrderOwnerUpdate.gql'
import USER_LIST from '@/graphql/Users.gql'

export default {
  name: 'PurchaseOrderDetailHeader',
  props: { purchaseOrder: { type: Object, default: () => {} } },
  data: () => ({
    valid: false,
    approvalStates: ['approved', 'pendingchanges', 'rejected'],
    reviewItem: {
      status: 'approved',
      comments: null
    },
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    closePurchaseOrderDialog: false,
    dialog: false,
    processing: false,
    approvalDialog: false,
    updateOwnerDialog: false,
    errorMsg: '',
    ownerEdit: {
      id: null,
      ownerID: null
    }
  }),
  computed: {
    paymentAuthLevel() {
      return this.$store.getters.authLevel('purchase_order_payments')
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    currentApprover() {
      var that = this
      if (
        that.purchaseOrder &&
        (!that.purchaseOrder.auditFlows ||
          that.purchaseOrder.auditFlows.length === 0)
      ) {
        return null
      } else {
        if (
          that.purchaseOrder &&
          that.purchaseOrder.status === 'review' &&
          that.currentUser
        ) {
          var approver = null

          that._.find(that.purchaseOrder.auditFlows, function(item) {
            if (item.status === 'review') {
              approver = that._.find(item.auditors, function(auditor) {
                return auditor.status === 'review'
              })
            }
          })

          if (
            approver &&
            approver.userID === that.currentUser.id &&
            that.currentUser
          ) {
            return approver
          }
        }
        return null
      }
    },
    companyUsers() {
      return this._.filter(this.users, (item) => {
        return !item.isSuperAdmin
      })
    }
  },
  apollo: {
    purchaseOrder: {
      query: PURCHASEORDER,
      variables() {
        return {
          id: this.$route.params.purchaseOrderID
        }
      }
    },
    users: USER_LIST
  },
  methods: {
    close() {
      this.updateOwnerDialog = false
      this.ownerEdit = {
        id: null,
        ownerID: null
      }
      this.$refs.changeOwnerForm.reset()
    },
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-2 upperCaseSpacing'
      }
    },
    submit() {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SUBMIT_PURCHASEORDER,
          variables: {
            id: this.purchaseOrder.id
          },
          update: (store, { data: { submitPurchaseOrder } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: PURCHASEORDER,
              variables: {
                id: this.purchaseOrder.id
              }
            })

            // update the purchaseOrder with the submitPurchaseOrder return
            data.purchaseOrder = submitPurchaseOrder

            // const newData = (data.purchaseOrder = submitPurchaseOrder)
            // Add our requirement from the mutation to the end
            // data.purchaseOrders.push(submitPurchaseOrder)
            // Write our data back to the cache.
            store.writeQuery({ query: PURCHASEORDER, data })
          }
        })
        .then(() => {
          this.dialog = false
          this.processing = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'PurchaseOrder Submitted'
          })
        })
        .catch(err => {
          this.processing = false

          if (
            err.graphQLErrors[0].message === 'purchaseOrder already submitted'
          ) {
            this.dialog = false
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'PurchaseOrder already submitted'
            })
          } else {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Something went wrong. Please try again later'
            })
          }
        })
    },
    review() {
      var data = {
        purchaseOrderID: this.purchaseOrder.id,
        status: this.reviewItem.status,
        comments: this.reviewItem.comments
      }
      if (this.$refs.reviewForm.validate()) {
        this.processing = true

        this.$apollo
          .mutate({
            mutation: REVIEW_PURCHASEORDER,
            variables: {
              input: data
            }
            // update: (store, { data: { reviewPurchaseOrder } }) => {
            //   // Read the data from our cache for this query.
            //   const data = store.readQuery({
            //     query: PURCHASEORDER,
            //     variables: {
            //       id: this.purchaseOrder.id
            //     }
            //   })

            //   // update the purchaseOrder with the submitPurchaseOrder return
            //   data.purchaseOrder = reviewPurchaseOrder

            //   // const newData = (data.purchaseOrder = submitPurchaseOrder)
            //   // Add our requirement from the mutation to the end
            //   // data.purchaseOrders.push(submitPurchaseOrder)
            //   // Write our data back to the cache.
            //   store.writeQuery({ query: PURCHASEORDER, data })
            // }
          })
          .then(() => {
            this.dialog = false
            this.approvalDialog = false
            this.processing = false

            this.reviewItem = {
              status: 'approved',
              comments: null
            }

            this.$store.commit('showSnackbar', {
              active: true,
              message: 'PurchaseOrder Review Submitted'
            })
          })
          .catch(err => {
            this.dialog = false
            this.approvalDialog = false
            this.rejectionDialog = false
            this.processing = false

            if (
              err.graphQLErrors &&
              err.graphQLErrors[0].message === 'purchaseOrder already reviewed'
            ) {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'PurchaseOrder already reviewsd'
              })
            } else {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Something went wrong. Please try again later'
              })
            }
          })
      }
    },
    closePurchaseOrder() {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: CLOSE_PURCHASEORDER,
          variables: {
            id: this.purchaseOrder.id
          }
        })
        .then(() => {
          this.$apollo.queries.purchaseOrder.refetch()
          this.closePurchaseOrderDialog = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'PurchaseOrder Closed'
          })
          this.processing = false
        })
        .catch(() => {
          this.closePurchaseOrderDialog = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong try again later'
          })
          this.processing = false
        })
    },
    changeOwner() {
      if (this.$refs.changeOwnerForm.validate()) {
        let data = {
          id: this.$route.params.purchaseOrderID,
          ownerID: this.ownerEdit.owner?.id
        }

        this.$apollo
          .mutate({
            mutation: UPDATE_PURCHASE_ORDER_OWNER,
            variables: {
              input: data
            },
          //   update: (store, { data: { updatePurchaseOrderOwner } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: PURCHASEORDER,
          //     variables: {
          //       id: this.purchaseOrder.id
          //     }
          //   })

          //   // update the purchaseOrder with the submitPurchaseOrder return
          //   data.purchaseOrder = updatePurchaseOrderOwner

          //   // const newData = (data.purchaseOrder = submitPurchaseOrder)
          //   // Add our requirement from the mutation to the end
          //   // data.purchaseOrders.push(submitPurchaseOrder)
          //   // Write our data back to the cache.
          //   store.writeQuery({ query: PURCHASEORDER, data })
          // }
          })
          .then(() => {
            // this.$apollo.queries.purchaseOrder.refetch()
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message:
                'Ownership Transfered'
            })
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message:
                'A general server error occured. Please try again later'
            })
          })
        }
    }
  }
}
</script>

<style></style>
