<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="500px"
    >
      <!-- <template v-slot:activator="{ on }">

      </template>-->
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="paymentTermEditForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.name"
                  outlined
                  label="Name"
                  autocomplete="off"
                  placeholder="Name"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.days"
                  outlined
                  type="number"
                  label="Days"
                  autocomplete="off"
                  placeholder="Days"
                  min="0"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="item.defaultPaymentTerm"
                  :disabled="item.defaultPaymentTerm"
                  color="primary"
                  label="Default"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text rounded @click="close(false)">Cancel</v-btn>
          <v-btn color="primary" rounded @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import CREATE_PAYMENT_TERM from '@/graphql/PaymentTermCreate.gql'
import UPDATE_PAYMENT_TERM from '@/graphql/PaymentTermUpdate.gql'

export default {
  name: 'PaymentTermEdit',
  props: {
    editedItem: { type: Object, default: null },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    disable: false,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    startDate: false,
    endDate: false
  }),
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        // if (val) {
        //   return true
        // }
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Payment Term' : 'New Payment Term'
    },
    formattedStartDate() {
      return this.item.start ? this.$moment(this.item.start).format('LL') : ''
    },
    formattedEndDate() {
      // this.$moment().locale('nl')
      if (!this.item.start) {
        return this.item.end ? this.$moment(this.item.end).format('LL') : ''
      } else if (this.item.end < this.item.start) {
        return this.$moment(this.item.start).format('LL')
      } else {
        return this.item.end
          ? this.$moment(this.item.end).format('LL')
          : this.$moment(this.item.start).format('LL')
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  methods: {
    close(success) {
      this.$emit('close-payment-term-edit-dialog', success)
    },

    save() {
      if (this.editMode) {
        // Edit item
        let item = {
          id: this.item.id,
          name: this.item.name,
          days: this.item.days,
          defaultPaymentTerm: this.item.defaultPaymentTerm
        }
        // validate
        if (this.$refs.paymentTermEditForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_PAYMENT_TERM,
              variables: {
                input: item
              },
              update: (store, { data: { updatePaymentTerm } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: PAYMENT_TERM_LIST
                })

                if (updatePaymentTerm.defaultPaymentTerm) {
                  this._.forEach(data.paymentTerms, function(item) {
                    if (item.id !== updatePaymentTerm.id) {
                      item.defaultPaymentTerm = false
                    }
                  })
                }

                this._.orderBy(data.paymentTerms, ['days'], ['asc'])

                // Write our data back to the cache.
                store.writeQuery({ query: PAYMENT_TERM_LIST, data })
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        // Save new item
        let item = {
          name: this.item.name,
          days: this.item.days,
          defaultPaymentTerm: this.item.defaultPaymentTerm
        }
        // validate
        if (this.$refs.paymentTermEditForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_PAYMENT_TERM,
              variables: {
                input: item
              },
              update: (store, { data: { createPaymentTerm } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: PAYMENT_TERM_LIST
                })

                if (createPaymentTerm.defaultPaymentTerm) {
                  this._.forEach(data.paymentTerms, function(item) {
                    item.defaultPaymentTerm = false
                  })
                }

                data.paymentTerms.push(createPaymentTerm)

                this._.orderBy(data.paymentTerms, ['days'], ['asc'])

                // Write our data back to the cache.
                store.writeQuery({ query: PAYMENT_TERM_LIST, data })
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      }
    }
    // disable () {
    //   const val = this.editedItem.defaultCurrency
    //   if (val) {
    //     return true
    //   }
    //   return false
    // }
  }
}
</script>

<style></style>
