<template>
  <div class="page-wrap">
    <purchase-order-detail-header
      :purchase-order="purchaseOrder"
    ></purchase-order-detail-header>

    <div class="main-page-column pt-0">
      <purchaseOrder-edit
        :open="dialog"
        :edited-item="editedItem"
        :edit-mode="editDetails"
        @close-edit-purchase-order-dialog="close"
      ></purchaseOrder-edit>

      <v-dialog v-model="removePaymentDialog" persistent max-width="500">
        <v-card>
          <v-card-title class="headline primary--text mb-3"
            >Remove Payment</v-card-title
          >
          <v-card-text class="pb-0">
            <v-row v-if="errorMsg && errorMsg != ''">
              <v-col>
                <v-alert type="error" text border="left">
                  {{ errorMsg }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                This will remove the payment and can't be undone
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-5">
            <div class="flex-grow-1"></div>
            <v-btn
              color="accent darken-1"
              text
              small
              rounded
              :disabled="processing"
              @click="close(false)"
              >Cancel</v-btn
            >
            <v-btn
              color="primary darken-1"
              small
              rounded
              :loading="processing"
              @click="removePayment"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="scroll-container pt-0">
        <v-row v-if="purchaseOrder">
          <v-col cols="12" md="4">
            <v-card flat>
              <v-card-title class="pb-0">
                <span
                  class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                  >Requested</span
                >
              </v-card-title>
              <v-card-text class="pb-0 pl-4">
                <v-row no-gutters>
                  <v-col v-if="purchaseOrder" class="pl-4 my-3">
                    <div class="text-h5 info--text darken-1">
                      {{
                        (purchaseOrder.pricing.forecastAmount / 100)
                          | currency(purchaseOrder.pricing.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </div></v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card flat>
              <v-card-title class="pb-0">
                <span
                  class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                  >Paid</span
                >
              </v-card-title>
              <v-card-text class="pb-0">
                <v-row no-gutters>
                  <v-col v-if="purchaseOrder" class="pl-4 my-3 text-h5">{{
                    (purchaseOrder.pricing.actualAmount / 100)
                      | currency(purchaseOrder.pricing.currency.symbol, 2, {
                        spaceBetweenAmountAndSymbol: true
                      })
                  }}</v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card flat>
              <v-card-title class="pb-0">
                <span
                  class="pl-4 text-caption text-uppercase grey--text font-weight-medium"
                  >Balance</span
                >
              </v-card-title>
              <v-card-text class="pb-0">
                <v-row no-gutters>
                  <v-col v-if="purchaseOrder" class="pl-4 my-3 text-h5">{{
                    (purchaseOrder.pricing.balanceAmount / 100)
                      | currency(purchaseOrder.pricing.currency.symbol, 2, {
                        spaceBetweenAmountAndSymbol: true
                      })
                  }}</v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="purchaseOrder">
          <v-col cols="12" md="6">
            <v-card v-if="!$apollo.loading" flat class="mx-auto">
              <v-card-title class="mb-0 primary--text"
                ><span class="font-weight-regular">Details</span>
                <v-spacer></v-spacer>

                <v-btn
                  v-if="
                    purchaseOrder &&
                      purchaseOrder.owner &&
                      currentUser &&
                      (purchaseOrder.status === 'draft' ||
                        purchaseOrder.status === 'pendingchanges') &&
                      (currentUser.isSuperAdmin ||
                        currentUser.id === purchaseOrder.owner.id ||
                        currentUser.userRole === 'director' ||
                        currentUser.userRole === 'seniormanager' ||
                        currentUser.userRole === 'purchaseOrdermanager') &&
                      !edit &&
                      !editDetails
                  "
                  color="info"
                  x-small
                  rounded
                  outlined
                  @click="editItem(purchaseOrder)"
                >
                  edit
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row no-gutters class="mb-5">
                  <v-col>
                    <div class="mb-1 caption">PO Number</div>
                    <span class="font-weight-medium">{{
                      purchaseOrder.poNumber
                    }}</span>
                  </v-col>
                  <v-col>
                    <div>
                      <span class="caption">Department</span>
                    </div>
                    <div>
                      <span class="font-weight-medium">
                        {{ purchaseOrder.department.name }}
                      </span>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col
                    ><div class="mb-1 caption">Supplier</div>
                    <v-row no-gutters justify="start">
                      <span class="font-weight-medium">{{
                        purchaseOrder.supplier.name
                      }}</span>
                    </v-row></v-col
                  >
                  <v-col
                    ><div class="mb-1 caption">Owner</div>
                    <v-row no-gutters justify="start">
                      <avatar
                        :username="
                          purchaseOrder.owner.firstName +
                            ' ' +
                            purchaseOrder.owner.lastName
                        "
                        :size="25"
                        color="white"
                        style="user-select: none;"
                        class="mr-3"
                      ></avatar>
                      <span class="font-weight-medium">{{
                        purchaseOrder.owner.firstName +
                          ' ' +
                          purchaseOrder.owner.lastName
                      }}</span>
                    </v-row></v-col
                  >
                </v-row>

                <v-divider class="my-4"></v-divider>

                <v-row no-gutters justify="start" class="mb-5">
                  <v-col cols="6">
                    <div class="mb-1 caption">Invoice Date</div>
                    <span class="font-weight-medium">{{
                      purchaseOrder.serviceDate | moment('DD MMM YYYY')
                    }}</span>
                  </v-col>
                  <v-col cols="6">
                    <div class="mb-1 caption">Payment Date</div>
                    <span class="font-weight-medium">{{
                      purchaseOrder.invoiceDate | moment('DD MMM YYYY')
                    }}</span>
                  </v-col>
                </v-row>

                <v-row no-gutters justify="start" class="mb-5">
                  <v-col>
                    <div class="mb-1 caption">Payment Terms</div>
                    <span class="font-weight-medium">{{
                      purchaseOrder.paymentTerm.name
                    }}</span>
                  </v-col>
                </v-row>

                <v-divider class="my-4"></v-divider>
                <div class="mb-5">
                  <div class="mb-1 caption">
                    <span v-if="budgetLine">Budget</span>
                    <span v-if="projectLine">Project</span>
                  </div>
                  <div class="font-weight-medium">
                    <span v-if="budget">{{ budget.title }}</span>
                    <span v-if="project">{{ project.name }}</span>
                  </div>
                </div>
                <v-row v-if="budgetLine" no-gutters justify="start">
                  <v-col>
                    <div class="mb-1 caption">
                      Category
                    </div>
                    <div class="font-weight-medium">
                      {{ budgetCategory.name }}
                    </div>
                  </v-col>

                  <v-col
                    ><div class="mb-1 caption">Line Item</div>
                    <div class="font-weight-medium">
                      {{ budgetLine.title }}
                    </div></v-col
                  >
                  <v-col
                    v-if="
                      currentUser.userRole === 'director' ||
                        currentUser.userRole === 'seniormanager' ||
                        currentUser.userRole === 'projectmanager'
                    "
                    cols="12"
                  >
                    <v-row class="mt-3">
                      <v-btn
                        color="info"
                        text
                        small
                        rounded
                        class="text-lowercase"
                        :to="{
                          name: 'BudgetCosting',
                          params: { budgetID: budgetLine.budgetID }
                        }"
                        >view budget</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="projectLine" no-gutters justify="start">
                  <v-col>
                    <div class="mb-1 caption">
                      Category
                    </div>
                    <div class="font-weight-medium">
                      {{ projectCategory.name }}
                    </div>
                  </v-col>

                  <v-col>
                    <div class="mb-1 caption">Line Item</div>
                    <div class="font-weight-medium">
                      {{ projectLine.title }}
                    </div>
                  </v-col>

                  <v-col
                    v-if="
                      currentUser.userRole === 'director' ||
                        currentUser.userRole === 'seniormanager' ||
                        currentUser.userRole === 'projectmanager'
                    "
                    cols="12"
                  >
                    <v-row class="mt-3">
                      <v-btn
                        color="info"
                        text
                        rounded
                        small
                        :to="{
                          name: 'ProjectCosting',
                          params: { projectID: projectLine.projectID }
                        }"
                        >view project</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-row>
              <!-- Description box -->
              <v-col cols="12"
                ><v-card flat>
                  <v-card-title class="primary--text">
                    <v-row align="center">
                      <v-col class="py-0 font-weight-regular"
                        >Description</v-col
                      >
                      <v-col class="py-0">
                        <v-row no-gutters align="center" justify="end">
                          <v-btn
                            v-if="
                              purchaseOrder &&
                                purchaseOrder.owner &&
                                currentUser &&
                                (purchaseOrder.status === 'draft' ||
                                  purchaseOrder.status === 'pendingchanges') &&
                                (currentUser.isSuperAdmin ||
                                  currentUser.id === purchaseOrder.owner.id ||
                                  currentUser.userRole === 'director' ||
                                  currentUser.userRole === 'seniormanager' ||
                                  currentUser.userRole ===
                                    'purchaseOrdermanager') &&
                                !edit &&
                                !editDetails
                            "
                            color="info"
                            x-small
                            outlined
                            rounded
                            @click="editDescription"
                          >
                            edit
                          </v-btn>
                          <div v-if="edit">
                            <v-btn
                              small
                              text
                              rounded
                              color="grey"
                              @click="cancelDescription"
                              >cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              small
                              rounded
                              @click="saveDescription"
                              >save</v-btn
                            >
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text v-if="!edit && !purchaseOrder.description">
                    <v-row>
                      <v-col cols="12">
                        <v-alert color="info" border="left" text class="mb-0"
                          ><div
                            v-if="
                              purchaseOrder && purchaseOrder.status === 'draft'
                            "
                          >
                            Please add a description that best describes this
                            purchase order
                          </div>
                          <div v-else>
                            No description was added to the request
                          </div></v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text
                    v-if="
                      !edit &&
                        purchaseOrder.description &&
                        purchaseOrder.description.length > 0
                    "
                    v-html="purchaseOrder.description"
                  ></v-card-text>
                  <div v-if="edit">
                    <vue-editor
                      v-if="purchaseOrder"
                      id="edit-purchaseOrder-description"
                      v-model="description"
                      :editor-options="editorConfig"
                      placeholder="A short description, which describes what the purchase order is about."
                    />
                  </div> </v-card
              ></v-col>

              <!-- Payment List -->
              <v-col
                v-if="
                  purchaseOrder &&
                    purchaseOrder.owner &&
                    currentUser &&
                    (purchaseOrder.status === 'approved' ||
                      purchaseOrder.status === 'closed') &&
                    (currentUser.isSuperAdmin ||
                      currentUser.id === purchaseOrder.owner.id ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'purchaseOrdermanager') &&
                    !edit &&
                    !editDetails
                "
                cols="12"
                ><v-card flat>
                  <v-card-title class="primary--text">
                    <v-row align="center">
                      <v-col class="py-0 font-weight-regular">Payments</v-col>
                      <v-col class="py-0">
                        <v-row no-gutters align="center" justify="end">
                          <v-dialog
                            v-if="
                              purchaseOrder &&
                                purchaseOrder.status === 'approved' &&
                                paymentAuthLevel === 2
                            "
                            v-model="paymentDialog"
                            persistent
                            max-width="500"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn color="info" small rounded v-on="on">
                                add payment
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title class="headline primary--text mb-3"
                                >Record Payment</v-card-title
                              >
                              <v-card-text
                                v-if="
                                  purchaseOrder.pricing &&
                                    purchaseOrder.pricing.currency
                                "
                                class="pb-0"
                              >
                                <v-form
                                  ref="paymentForm"
                                  v-model="valid"
                                  @submit.prevent
                                >
                                  <v-row v-if="errorMsg && errorMsg != ''">
                                    <v-col>
                                      <v-alert type="error" text border="left">
                                        {{ errorMsg }}
                                      </v-alert>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <v-currency-field
                                        v-model="expenseAmount"
                                        autocomplete="off"
                                        outlined
                                        :prefix="
                                          purchaseOrder.pricing.currency.symbol
                                        "
                                        label="Amount"
                                        :rules="amountRequiredRule"
                                        :hint="
                                          'Balance After: ' +
                                            balanceAfterPayment
                                        "
                                        hide-details="auto"
                                        dense
                                      ></v-currency-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <v-file-input
                                        v-model="invoiceFile"
                                        prepend-inner-icon="mdi-paperclip"
                                        :prepend-icon="null"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        clearable
                                        placeholder="upload invoice"
                                      ></v-file-input>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <v-checkbox
                                        v-model="closeOrder"
                                        label="Close Purchase Order"
                                        hint="Don't check this box if you expect more payments"
                                        persistent-hint
                                      ></v-checkbox>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-card-text>
                              <v-card-actions class="pa-5">
                                <div class="flex-grow-1"></div>
                                <v-btn
                                  color="accent darken-1"
                                  text
                                  small
                                  rounded
                                  :disabled="processing"
                                  @click="close(false)"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="primary darken-1"
                                  small
                                  rounded
                                  :disabled="!valid"
                                  :loading="processing"
                                  @click="savePayment"
                                  >Submit</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text
                    v-if="
                      !edit &&
                        purchaseOrder &&
                        purchaseOrder.status === 'approved' &&
                        (!purchaseOrder.payments ||
                          purchaseOrder.payments.length === 0)
                    "
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-alert color="info" border="left" text class="mb-0"
                          ><div>
                            Please record your payments against the balance
                          </div></v-alert
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <!-- Payments Table -->
                  <v-card-text v-else>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="item in purchaseOrder.payments"
                            :key="item.id"
                          >
                            <td>
                              <div class="text-truncate">
                                {{
                                  (item.pricing.actualAmount / 100)
                                    | currency(
                                      item.pricing.currency.symbol,
                                      2,
                                      {
                                        spaceBetweenAmountAndSymbol: true
                                      }
                                    )
                                }}
                              </div>
                            </td>
                            <td>
                              <div class="text-truncate">
                                {{ item.updatedAt | moment('DD/MM/YY') }}
                              </div>
                            </td>
                            <td>
                              <div class="text-truncate">
                                {{
                                  item.user.firstName + ' ' + item.user.lastName
                                }}
                              </div>
                            </td>
                            <td>
                              <v-row justify="end">
                                <span v-if="item.invoice" class="text-truncate">
                                  <v-btn
                                    :href="item.invoice"
                                    text
                                    small
                                    rounded
                                    color="info"
                                    class="text-lowercase"
                                    target="_blank"
                                    >view invoice</v-btn
                                  >
                                </span>
                                <span>
                                  <v-btn
                                    v-if="
                                      purchaseOrder &&
                                        purchaseOrder.owner &&
                                        currentUser &&
                                        purchaseOrder.status === 'approved' &&
                                        (currentUser.isSuperAdmin ||
                                          currentUser.id ===
                                            purchaseOrder.owner.id ||
                                          currentUser.userRole === 'director' ||
                                          currentUser.userRole ===
                                            'seniormanager' ||
                                          currentUser.userRole ===
                                            'purchaseOrdermanager')
                                    "
                                    text
                                    small
                                    rounded
                                    color="grey"
                                    class="text-lowercase"
                                    @click.stop="
                                      openRemovePaymentDialog(item.id)
                                    "
                                    >delete</v-btn
                                  >
                                </span>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseOrderDetailHeader from '@/modules/finance/components/core/PurchaseOrderDetailHeader.vue'
import PurchaseOrderEdit from '@/modules/finance/components/core/PurchaseOrderEdit.vue'
import Avatar from 'vue-avatar'
import PURCHASEORDER from '@/graphql/PurchaseOrder.gql'
import BUDGET from '@/graphql/Budget.gql'
import BUDGET_LINE from '@/graphql/BudgetLine.gql'
import BUDGET_CATEGORY from '@/graphql/BudgetCategory.gql'
import PROJECT from '@/graphql/Project.gql'
import PROJECT_LINE from '@/graphql/ProjectLine.gql'
import PROJECT_CATEGORY from '@/graphql/ProjectCategory.gql'
import UPDATE_PURCHASE_ORDER from '@/graphql/PurchaseOrderUpdate.gql'
import CLOSE_PURCHASE_ORDER from '@/graphql/PurchaseOrderClose.gql'
import EXPENSE_CREATE from '@/graphql/ExpenseCreate.gql'
import EXPENSE_REMOVE from '@/graphql/ExpenseRemove.gql'

export default {
  name: 'PurchaseOrderDetail',
  components: {
    PurchaseOrderDetailHeader,
    PurchaseOrderEdit,
    Avatar
  },
  data() {
    return {
      edit: false,
      description: null,
      processing: false,
      editorConfig: {
        // theme: 'bubble',
        modules: {
          toolbar: false
        }
      },
      dialog: false,
      valid: true,
      errorMsg: '',
      paymentDialog: false,
      removePaymentDialog: false,
      deleteableID: null,
      purchaseOrder: null,
      expenseAmount: 0,
      invoiceFile: null,
      closeOrder: false,
      requiredRule: [
        v => !!v || 'Field is required',
        v => !/^\s+$/.test(v) || 'Field is required'
      ],
      amountRequiredRule: [
        v => !!v || 'Field is required',
        v => (!!v && parseInt(v) > 0) || 'Value must be greater than 0',
        v =>
          (!!v && this.auditBalance > -1) ||
          "Value can't be greater than outstanding balance"
      ],
      defaultItem: {
        title: '',
        description: '',
        supplier: null,
        pricing: {},
        status: 'draft',
        serviceDate: new Date().toISOString(),
        invoiceDate: new Date().toISOString()
      },
      editedItem: {
        title: '',
        description: '',
        supplier: null,
        pricing: {},
        status: 'draft',
        serviceDate: new Date().toISOString(),
        invoiceDate: new Date().toISOString()
      },
      editDetails: false
    }
  },
  apollo: {
    purchaseOrder: {
      query: PURCHASEORDER,
      variables() {
        return {
          id: this.$route.params.purchaseOrderID
        }
      },
      fetchPolicy: 'no-cache'
    },
    budgetLine: {
      query: BUDGET_LINE,
      variables() {
        return {
          id: this.purchaseOrder.budgetLine.id
        }
      },
      // Disable the query
      skip() {
        return !this.purchaseOrder || !this.purchaseOrder.budgetLine.id
      }
    },
    budget: {
      query: BUDGET,
      variables() {
        return {
          id: this.budgetLine.budgetID
        }
      },
      // Disable the query
      skip() {
        return !this.budgetLine || !this.budgetLine.budgetID
      }
    },
    budgetCategory: {
      query: BUDGET_CATEGORY,
      variables() {
        return {
          id: this.budgetLine.budgetCategoryID
        }
      },
      // Disable the query
      skip() {
        return !this.budgetLine
      }
    },
    projectLine: {
      query: PROJECT_LINE,
      variables() {
        return {
          id: this.purchaseOrder.projectLine.id
        }
      },
      // Disable the query
      skip() {
        return !this.purchaseOrder || !this.purchaseOrder.projectLine.id
      }
    },
    project: {
      query: PROJECT,
      variables() {
        return {
          id: this.projectLine.projectID
        }
      },
      // Disable the query
      skip() {
        return !this.projectLine || !this.projectLine.id
      }
    },
    projectCategory: {
      query: PROJECT_CATEGORY,
      variables() {
        return {
          id: this.projectLine.projectCategoryID
        }
      },
      // Disable the query
      skip() {
        return !this.projectLine
      }
    }
  },
  computed: {
    paymentAuthLevel() {
      return this.$store.getters.authLevel('purchase_order_payments')
    },
    balanceAfterPayment() {
      return this.purchaseOrder &&
        this.purchaseOrder.pricing &&
        this.purchaseOrder.pricing.balanceAmount
        ? this.purchaseOrder.pricing.currency.symbol +
            ' ' +
            (
              (this.purchaseOrder.pricing.balanceAmount -
                this.expenseAmount * 100) /
              100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
        : 0
    },
    auditBalance() {
      return this.purchaseOrder &&
        this.purchaseOrder.pricing &&
        this.purchaseOrder.pricing.balanceAmount
        ? this.purchaseOrder.pricing.balanceAmount - this.expenseAmount * 100
        : 0
    },
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'pricing',
          sortable: false
        },
        { text: '', align: 'left', value: 'user', sortable: false },
        {
          text: '',
          align: 'left',
          value: 'createdAt',
          sortable: false
        },
        {
          text: '',
          align: 'left',
          value: 'invoice',
          sortable: false
        }
      ]
    },
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  methods: {
    stateColor: item => {
      if (item === 'open') {
        return 'info'
      } else if (item === 'rejected') {
        return 'error'
      } else if (item === 'approved') {
        return 'success'
      }
    },
    editDescription() {
      if (this.purchaseOrder.description) {
        this.description = this._.cloneDeep(this.purchaseOrder.description)
      }
      this.edit = true
    },
    cancelDescription() {
      this.description = null
      this.edit = false
    },
    saveDescription() {
      let data = {
        id: this.purchaseOrder.id,
        title: this.purchaseOrder.title,
        description: this.description,
        departmentID: this.purchaseOrder.department.id,
        paymentTermID: this.purchaseOrder.paymentTerm.id,
        budgetLineID: this.purchaseOrder.budgetLine
          ? this.purchaseOrder.budgetLine.id
          : null,
        projectLineID: this.purchaseOrder.projectLine
          ? this.purchaseOrder.projectLine.id
          : null,
        pricing: {
          displayAmount: this.purchaseOrder.pricing.forecastAmount,
          currencyID: this.purchaseOrder.pricing.currency.id,
          baseCurrencyID: this.purchaseOrder.pricing.baseCurrency.id,
          exchangeRate: this.purchaseOrder.pricing.currency.exchangeRate
        },
        supplierID: this.purchaseOrder.supplier.id,
        serviceDate: this.$moment(this.purchaseOrder.serviceDate).utc()
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_PURCHASE_ORDER,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.cancelDescription()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'changes saved'
          })
          this.$apollo.queries.purchaseOrder.refetch()
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'An error occured please try again later'
          })
          this.$apollo.queries.purchaseOrder.refetch()
        })
    },
    savePayment() {
      this.errorMsg = ''
      var vm = this
      if (this.$refs.paymentForm.validate() && this.auditBalance > -1) {
        vm.processing = true
        let data = {
          purchaseOrderID: vm.$route.params.purchaseOrderID,
          amount: vm.expenseAmount ? Math.floor(vm.expenseAmount * 100) : 0,
          close: vm.closeOrder,
          file: vm.invoiceFile
        }

        // if (!vm.closeOrder && vm.auditBalance === 0) {
        //   data.close = true
        //   vm.closeOrder = true
        // }

        vm.$apollo
          .mutate({
            mutation: EXPENSE_CREATE,
            variables: {
              input: data
            }
          })
          .then(() => {
            if (vm.closeOrder) {
              vm.$apollo
                .mutate({
                  mutation: CLOSE_PURCHASE_ORDER,
                  variables: {
                    id: vm.$route.params.purchaseOrderID
                  }
                })
                .then(() => {
                  vm.$apollo.queries.purchaseOrder.refetch()
                  vm.close(true)
                })
                .catch(() => {
                  vm.errorMsg = 'Something went wrong try again later'
                  vm.processing = false
                })
            } else {
              vm.$apollo.queries.purchaseOrder.refetch()
              vm.close(true)
            }
          })
          .catch(() => {
            vm.errorMsg = 'Something went wrong try again later'
            vm.processing = false
          })
      }
    },
    openRemovePaymentDialog(id) {
      this.deleteableID = id
      this.removePaymentDialog = true
    },
    removePayment() {
      if (this.deleteableID) {
        this.processing = true
        this.errorMsg = ''

        this.$apollo
          .mutate({
            mutation: EXPENSE_REMOVE,
            variables: {
              id: this.deleteableID
            }
          })
          .then(() => {
            this.$apollo.queries.purchaseOrder.refetch()
            this.processing = false
            this.errorMsg = ''
            this.deleteableID = null
            this.removePaymentDialog = false
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong try again later'

            this.processing = false
          })
      }
    },
    close(success) {
      this.editDetails = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      this.paymentDialog = false
      this.removePaymentDialog = false
      this.processing = false
      this.errorMsg = ''
      if (this.$refs.paymentForm) {
        this.$refs.paymentForm.resetValidation()
      }
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })
        this.$apollo.queries.purchaseOrder.refetch()
      }
      this.expenseAmount = 0
      this.invoiceFile = null
      this.closeOrder = false
    },
    editItem() {
      this.editDetails = true
      this.editedItem = this._.cloneDeep(this.purchaseOrder)
      this.editedItem.departmentID = this.purchaseOrder.department.id
      this.editedItem.budgetLineID = this.purchaseOrder.budgetLine.id
      this.editedItem.projectLine = this.purchaseOrder.projectLine
      this.editedItem.supplier = this.purchaseOrder.supplier
      this.editedItem.paymentTermID = this.purchaseOrder.paymentTerm.id
      this.editedItem.pricing.displayAmount =
        this.purchaseOrder.pricing.forecastAmount / 100
      this.dialog = true
    }
  }
}
</script>

<style lang="scss">
#edit-review-comments {
  border: 1px solid #ccc;
  border-radius: 5px;
  // height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
  .ql-editor {
    min-height: 100px !important;
  }
}

#edit-purchaseOrder-description {
  border: 1px solid #ccc;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
