<template>
  <div class="page-header">
    <v-toolbar class="mb-0 b-radius" flat>
      <v-tabs
        next-icon="mdi-chevron-right"
        prev-icon="mdi-chevron-left"
        background-color="transparent"
        align-with-title
        center-active
        show-arrows
        color="info"
      >
        <v-tab
          :to="{ name: 'FinanceSettingsGeneral' }"
          class="text-capitalize"
          exact
          >General</v-tab
        >
        <v-tab :to="{ name: 'FinanceSettingsYears' }" class="text-capitalize"
          >Financial Years</v-tab
        >
        <v-tab
          :to="{ name: 'FinanceSettingsCurrencies' }"
          class="text-capitalize"
          >Currencies</v-tab
        >
        <v-tab
          :to="{ name: 'FinanceSettingsPaymentTerms' }"
          class="text-capitalize"
          >Payment Terms</v-tab
        >
      </v-tabs>

      <slot name="controls"></slot>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'FinanceSettingsToolbar'
}
</script>

<style></style>
