<template>
  <div class="page-wrap">
    <finance-budget-detail-header
      :budget="budget"
    ></finance-budget-detail-header>
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-row>
          <v-col>
            <v-card flat>
              <v-card-title class="pt-0">
                <v-text-field
                  v-model="search"
                  hide-details
                  flat
                  clearable
                  background-color="#fff"
                  placeholder="Search..."
                  prepend-inner-icon="mdi-magnify"
                  autocomplete="off"
                  :disabled="!purchaseOrders"
                ></v-text-field>
              </v-card-title>

              <purchase-order-list-items
                :data="purchaseOrders.items"
                :loading="$apollo.queries.purchaseOrders.loading"
                :item-count="itemCount"
                @set-options="setOptions"
              ></purchase-order-list-items>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import country from 'country-list'
import PurchaseOrderListItems from '@/modules/finance/components/core/PurchaseOrderListItems.vue'
import FinanceBudgetDetailHeader from '@/modules/finance/components/core/FinanceBudgetDetailHeader.vue'
import BUDGET from '@/graphql/Budget.gql'
import PURCHASE_ORDERS from '@/graphql/PurchaseOrders.gql'
import DEPARTMENTS from '@/graphql/Departments.gql'

export default {
  name: 'BudgetPurchaseOrders',
  components: {
    FinanceBudgetDetailHeader,
    PurchaseOrderListItems
  },
  props: {},
  data: () => ({
    processing: false,
    search: null,

    // Filters
    purchaseOrders: { items: [] },
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    stateFilterValue: null,
    stateList: ['draft', 'approved', 'rejected', 'review', 'closed']
  }),
  apollo: {
    budget: {
      query: BUDGET,
      variables() {
        return {
          id: this.$route.params.budgetID
        }
      }
      // fetchPolicy: 'cache-only'
    },
    purchaseOrders: {
      query: PURCHASE_ORDERS,
      variables() {
        return {
          filters: {
            search: this.search,
            budgetID: this.$route.params.budgetID,
            status: this.stateFilterValue,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      result(result) {
        this.page = result.data.purchaseOrders.page
        this.itemsPerPage = result.data.purchaseOrders.pageSize
        this.itemCount = result.data.purchaseOrders.total
      }
    },
    departments: DEPARTMENTS
  },
  computed: {},
  methods: {
    setOptions(e) {
      this.options = e
    },
    getFullCountryName(code) {
      return country.getName(code)
    }
  }
}
</script>

<style lang="scss">
// @import '~quill/dist/quill.bubble.css';
// @import '~quill/dist/quill.snow.css';
#edit-budget-description {
  border: 1px solid #ccc;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
