var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('purchase-order-detail-header',{attrs:{"purchase-order":_vm.purchaseOrder}}),_c('div',{staticClass:"main-page-column pt-0"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.secondRemoveDialog),callback:function ($$v) {_vm.secondRemoveDialog=$$v},expression:"secondRemoveDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline error--text mb-3"},[_vm._v("Remove Purchase Order")]),_c('v-card-text',[_c('p',[_vm._v(" This will delete the purchase order and any associated with data. ")]),_c('p',[_vm._v(" This operation can't be undone! ")]),_c('v-checkbox',{attrs:{"color":"error","label":"I understand the concequences!"},model:{value:(_vm.removalConfirmation),callback:function ($$v) {_vm.removalConfirmation=$$v},expression:"removalConfirmation"}})],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"grey darken-1","text":"","disabled":_vm.processing},on:{"click":function($event){return _vm.closeRemoveDialog(false)}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error","text":"","disabled":!_vm.removalConfirmation,"loading":_vm.processing},on:{"click":_vm.removePurchaseOrder}},[_vm._v("Remove Purchase Order")])],1)],1)],1),_c('div',{staticClass:"scroll-container pt-0"},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.purchaseOrder && _vm.purchaseOrder.status === 'draft')?_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8","xl":"6"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"mb-3 primary--text"},[_vm._v("Delete Purchase Order")]),_c('v-card-subtitle',[_vm._v("Once you delete this purchase order, you will lose all data associated with it.")]),_c('v-card-text',[(
                  _vm.purchaseOrder &&
                    _vm.authLevel &&
                    _vm.authLevel === 2 &&
                    _vm.currentUser &&
                    (_vm.currentUser.id === _vm.purchaseOrder.owner.id ||
                      _vm.currentUser.isSuperAdmin ||
                      _vm.currentUser.isAdmin ||
                      _vm.currentUser.userRole === 'director' ||
                      _vm.currentUser.userRole === 'seniormanager' ||
                      _vm.currentUser.userRole === 'purchaseOrdermanager')
                )?_c('v-btn',{staticClass:"mt-3 upperCaseSpacing",attrs:{"color":"error lighten-2","depressed":"","rounded":"","small":""},on:{"click":_vm.openSecondRemoveDialog}},[_vm._v(" delete purchaseOrder ")]):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }