<template>
  <div class="page-wrap">
    <currency-edit
      :edited-item="editedItem"
      :open="dialog"
      :edit-mode="edit"
      @close-edit-currency-dialog="close"
    ></currency-edit>

    <FinanceSettingsToolbar>
      <template v-slot:controls>
        <v-btn fab small color="primary" @click="newItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </FinanceSettingsToolbar>
    <div class="main-page-column pt-0" style="width: 100%">
      <div class="page-wrap">
        <div class="main-page-column pt-0">
          <div class="scroll-container pt-0">
            <v-row justify="center">
              <v-col xs="12" sm="12" md="10" lg="10" xl="8">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Currency Code</th>
                        <th class="text-left">Symbol</th>
                        <th class="text-left">Exchange Rate</th>
                        <th class="text-left">Production Exchange Rate</th>
                        <th class="text-left">Default Currency</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="currency in currencies" :key="currency.id">
                        <td>{{ currency.name }}</td>
                        <td>{{ currency.isoName }}</td>
                        <td>{{ currency.symbol }}</td>
                        <td>{{ currency.exchangeRate.toFixed(2) }}</td>
                        <td>
                          {{ currency.productionExchangeRate.toFixed(2) }}
                        </td>
                        <td>
                          <v-icon v-if="currency.defaultCurrency"
                            >mdi-check</v-icon
                          >
                        </td>
                        <td>
                          <v-btn
                            v-if="authLevel === 2"
                            x-small
                            text
                            rounded
                            color="info"
                            @click="editItem(currency)"
                          >
                            edit
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceSettingsToolbar from '@/modules/finance/components/base/FinanceSettingsToolbar.vue'
import CurrencyEdit from '@/modules/finance/components/core/CurrencyEdit.vue'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'FinanceSettingsCurrencies',
  components: {
    FinanceSettingsToolbar,
    CurrencyEdit
  },
  data: () => ({
    dialog: false,
    edit: false,
    search: '',
    currencies: [],
    defaultItem: {
      id: '',
      name: '',
      isoName: '',
      symbol: '',
      exchangeRate: '1.0000',
      productionExchangeRate: '1.0000',
      defaultCurrency: false,
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      name: '',
      isoName: '',
      symbol: '',
      exchangeRate: '1.0000',
      productionExchangeRate: '1.0000',
      defaultCurrency: false,
      updatedAt: new Date(),
      createdAt: new Date()
    }
  }),
  computed: {
    authLevel() {
      return this.$store.getters.authLevel('finance_settings')
    }
  },
  apollo: {
    currencies: { query: CURRENCY_LIST, fetchPolicy: 'network-only' }
  },
  methods: {
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Currency Saved'
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.editedItem.exchangeRate = this.editedItem.exchangeRate.toFixed(4)
      this.dialog = true
    }
  }
}
</script>

<style></style>
