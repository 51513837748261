<template>
  <div class="page-wrap">
    <finance-budget-detail-header
      :budget="budget"
    ></finance-budget-detail-header>
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-row justify="center">
          <v-col v-if="budget" cols="12" md="10" lg="8" xl="6">
            <v-row>
              <v-col cols="12">
                <v-card flat>
                  <v-card-title class="primary--text">
                    <v-row>
                      <v-col class="py-0 font-weight-regular"
                        >Description</v-col
                      >
                      <v-col class="py-0">
                        <v-row no-gutters align="center" justify="end">
                          <v-btn
                            v-if="
                              budget &&
                                (budget.status === 'draft' ||
                                  budget.status === 'pendingchanges') &&
                                !edit
                            "
                            color="grey"
                            outlined
                            x-small
                            rounded
                            @click="editItem"
                          >
                            <v-icon left small>mdi-pencil-outline</v-icon>edit
                          </v-btn>
                          <div v-if="edit">
                            <v-btn
                              small
                              text
                              rounded
                              color="grey"
                              @click="cancel"
                              >cancel</v-btn
                            >
                            <v-btn color="primary" small rounded @click="save"
                              >save</v-btn
                            >
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text v-if="!edit && !budget.description">
                    <v-row>
                      <v-col cols="12">
                        <v-alert color="info" border="left" text class="mb-0">
                          <div v-if="budget && budget.status === 'draft'">
                            Please add a description that best describes your
                            budget
                          </div>
                          <div v-else>
                            No description was added to the request
                          </div>
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text
                    v-if="
                      !edit && budget.description && budget.description !== ''
                    "
                    v-html="budget.description"
                  ></v-card-text>
                  <v-card-text v-if="edit">
                    <v-row>
                      <v-col cols="12">
                        <div>
                          <vue-editor
                            v-if="budget"
                            id="edit-budget-description"
                            v-model="description"
                            :editor-options="editorConfig"
                            placeholder="A short description, which describes what the budget is about."
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceBudgetDetailHeader from '@/modules/finance/components/core/FinanceBudgetDetailHeader.vue'
import BUDGET from '@/graphql/Budget.gql'
import UPDATE_BUDGET from '@/graphql/BudgetUpdate.gql'

export default {
  name: 'BudgetDescription',
  components: {
    FinanceBudgetDetailHeader
  },
  props: {
    editedItem: {
      type: Object,
      default: null
    },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    edit: false,
    description: null,
    processing: false,
    editorConfig: {
      // theme: 'bubble',
      modules: {
        toolbar: false
      }
    }
  }),
  apollo: {
    budget: {
      query: BUDGET,
      variables() {
        return {
          id: this.$route.params.budgetID
        }
      }
      // fetchPolicy: 'cache-only'
    }
  },
  computed: {},
  methods: {
    editItem() {
      if (this.budget.description) {
        this.description = this._.cloneDeep(this.budget.description)
      }
      this.edit = true
    },
    cancel() {
      this.description = null
      this.edit = false
    },
    save() {
      // Return the price to a int
      // if (this.description) {

      let data = {
        id: this.budget.id,
        title: this.budget.title,
        description: this.description,
        departmentID: this.budget.department.id,
        financialYearID: this.budget.financialYear.id,
        currencyID: this.budget.currency.id
      }

      if (!this.description || this.description === '') delete data.description

      this.$apollo
        .mutate({
          mutation: UPDATE_BUDGET,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.cancel()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'changes saved'
          })
        })
      // } else {

      // }
    }
  }
}
</script>

<style lang="scss">
// @import '~quill/dist/quill.bubble.css';
// @import '~quill/dist/quill.snow.css';
#edit-budget-description {
  border: 1px solid #ccc;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
