var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('finance-project-detail-header',{attrs:{"project":_vm.project}}),_c('div',{staticClass:"main-page-column pt-0"},[_c('project-edit',{attrs:{"open":_vm.dialog,"edited-item":_vm.editedItem,"edit-mode":_vm.editDetails},on:{"close-edit-project-dialog":_vm.close}}),_c('div',{staticClass:"scroll-container pt-0"},[_c('v-row',[(_vm.project)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(!_vm.$apollo.loading)?_c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"mb-0 primary--text"},[_c('span',{staticClass:"font-weight-regular"},[_vm._v("Details")]),_c('v-spacer'),(
                      _vm.project &&
                        _vm.project.owner &&
                        _vm.currentUser &&
                        (_vm.project.status === 'draft' ||
                          _vm.project.status === 'pendingchanges') &&
                        (_vm.currentUser.isSuperAdmin ||
                          _vm.currentUser.id === _vm.project.owner.id ||
                          _vm.currentUser.userRole === 'director' ||
                          _vm.currentUser.userRole === 'seniormanager' ||
                          _vm.currentUser.userRole === 'projectmanager') &&
                        !_vm.edit &&
                        !_vm.editDetails
                    )?_c('v-btn',{attrs:{"color":"info","outlined":"","x-small":"","rounded":""},on:{"click":function($event){return _vm.editItem(_vm.project)}}},[_vm._v("edit ")]):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('avatar',{staticClass:"mr-3",staticStyle:{"user-select":"none"},attrs:{"username":_vm.project.owner.firstName +
                              ' ' +
                              _vm.project.owner.lastName,"size":25,"color":"white"}}),_c('span',[_vm._v(_vm._s(_vm.project.owner.firstName + ' ' + _vm.project.owner.lastName))])],1)],1),_c('v-col',{staticClass:"py-0"},[_c('div',{staticClass:"subtitle-2"},[_c('span',{staticClass:"caption"},[_vm._v("Department")])]),_c('div',{staticClass:" font-weight-bold"},[_vm._v(" "+_vm._s(_vm.project.department.name)+" ")])])],1),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"mb-2"},[_vm._v("Budget Allocation")]),_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"caption"},[_vm._v(" Category ")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.budgetCategory.name)+" ")])]),_c('v-col',[_c('div',{staticClass:"caption"},[_vm._v("Line Item")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.budgetLine.title)+" ")])])],1)],1),(
                        _vm.currentUser.userRole === 'director' ||
                          _vm.currentUser.userRole === 'seniormanager' ||
                          _vm.currentUser.userRole === 'projectmanager'
                      )?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-btn',{staticClass:"text-lowercase",attrs:{"color":"info","small":"","text":"","rounded":"","to":{
                            name: 'BudgetCosting',
                            params: { budgetID: _vm.budgetCategory.budgetID }
                          }}},[_vm._v("view budget")])],1)],1):_vm._e()],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"caption"},[_vm._v("From")]),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.project.startDate,'DD MMMM YYYY')))])]),_c('v-col',[_c('div',{staticClass:"caption"},[_vm._v("Until")]),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.project.endDate,'DD MMMM YYYY')))])])],1)],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"primary--text"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0 font-weight-regular"},[_vm._v("Description")]),_c('v-col',{staticClass:"py-0"},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[(
                            _vm.project &&
                              _vm.project.owner &&
                              _vm.currentUser &&
                              (_vm.project.status === 'draft' ||
                                _vm.project.status === 'pendingchanges') &&
                              (_vm.currentUser.isSuperAdmin ||
                                _vm.currentUser.id === _vm.project.owner.id ||
                                _vm.currentUser.userRole === 'director' ||
                                _vm.currentUser.userRole === 'seniormanager' ||
                                _vm.currentUser.userRole === 'projectmanager') &&
                              !_vm.edit &&
                              !_vm.editDetails
                          )?_c('v-btn',{attrs:{"color":"info","outlined":"","x-small":"","rounded":""},on:{"click":_vm.editDescription}},[_vm._v(" edit ")]):_vm._e(),(_vm.edit)?_c('div',[_c('v-btn',{attrs:{"small":"","text":"","rounded":"","color":"grey"},on:{"click":_vm.cancelDescription}},[_vm._v("cancel")]),_c('v-btn',{attrs:{"color":"primary","small":"","rounded":""},on:{"click":_vm.saveDescription}},[_vm._v("save")])],1):_vm._e()],1)],1)],1)],1),(!_vm.edit && !_vm.project.description)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"color":"info","border":"left","text":""}},[(_vm.project && _vm.project.status === 'draft')?_c('div',[_vm._v(" Please add a description that best describes your project ")]):_c('div',[_vm._v(" No description was added to the request ")])])],1)],1)],1):_vm._e(),(
                    !_vm.edit &&
                      _vm.project.description &&
                      _vm.project.description.length > 0
                  )?_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.project.description)}}):_vm._e(),(_vm.edit)?_c('div',[(_vm.project)?_c('vue-editor',{attrs:{"id":"edit-project-description","editor-options":_vm.editorConfig,"placeholder":"A short description, which describes what the project is about."},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}):_vm._e()],1):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }