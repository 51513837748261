<template>
  <div>
    <supplier-edit
      :open="supplierDialog"
      :edited-item="editedSupplierItem"
      :payment-terms="paymentTerms"
      :currencies="currencies"
      @close-edit-supplier-dialog="closeSupplierDialog"
      @save-supplier="saveSupplier"
    ></supplier-edit>
    <v-dialog
      v-if="!supplierDialog"
      v-model="dialog"
      width="700"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="purchaseOrderForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row v-if="errorMsg">
                  <v-col cols="12">
                    <v-alert type="error" text border="left">
                      {{ errorMsg }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Supplier</label>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="item.supplier"
                      :items="suppliers.items"
                      :search-input.sync="searchSupplier"
                      outlined
                      clearable
                      autocomplete="off"
                      placeholder="Search and select"
                      dense
                      return-object
                      hide-details="auto"
                      item-text="name"
                      :disabled="editMode"
                      :rules="requiredRule"
                    >
                      <template v-slot:no-data>
                        <v-row>
                          <v-col class="py-0">
                            <v-btn
                              text
                              color="info"
                              rounded
                              @click="openSupplierDialog"
                              >Add New Supplier</v-btn
                            >
                          </v-col>
                        </v-row>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="!item.supplier">
                  <v-btn text color="info" rounded @click="openSupplierDialog"
                    >Add New Supplier</v-btn
                  >
                </v-row>
                <v-row v-if="item.supplier" no-gutters>
                  <v-col>
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label>Title</label>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.title"
                          autocomplete="off"
                          placeholder="Give this purchase order a title"
                          outlined
                          hide-details="auto"
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-3">
                      <v-col cols="12" class="pb-3">
                        <label>Description</label>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <vue-editor
                          id="edit-description"
                          v-model="item.description"
                          :editor-options="editorConfig"
                          placeholder="A short description, which describes what the purchase order is about."
                          :rules="requiredRule"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" lg="6">
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <label>Department</label>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="item.departmentID"
                              :items="departments"
                              :rules="requiredRule"
                              outlined
                              autocomplete
                              item-value="id"
                              item-text="name"
                              dense
                              hide-details="auto"
                              :disabled="
                                editMode ||
                                (currentUser &&
                                  currentUser.userRole &&
                                  currentUser.userRole.toLowerCase() !==
                                    'director' &&
                                  currentUser.userRole.toLowerCase() !==
                                    'seniormanager' &&
                                  currentUser.userRole.toLowerCase() !==
                                    'projectmanager' &&
                                  (!currentUser.isSuperAdmin ||
                                    !currentUser.isAdmin))
                              "
                              @change="clearLines(item.departmentID)"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6" lg="6">
                        <v-row>
                          <v-col cols="12" class="pb-0"
                            >Expected Invoice Date</v-col
                          >
                          <v-col cols="12">
                            <v-menu
                              ref="menu1"
                              v-model="serviceDate"
                              :close-on-content-click="false"
                              persistent-hint
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  :value="formattedInvoiceDate"
                                  hint="This is the date the Supplier is eligable to invoice service"
                                  transition="scale-transition"
                                  outlined
                                  prepend-inner-icon="mdi-calendar-month-outline"
                                  dense
                                  hide-details="auto"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="item.serviceDate"
                                no-title
                                @input="startDate = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row v-if="!editMode">
                      <v-col cols="12" class="pb-0">
                        <label>Allocation</label>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-radio-group
                          v-model="allocation"
                          row
                          dense
                          persistent-hint
                          hide-details="auto"
                          hint="Allocate the order against either a budget or directly against a department budget"
                          @change="clearLines(item.departmentID)"
                        >
                          <v-radio
                            color="primary"
                            label="Budget"
                            value="budget"
                          ></v-radio>
                          <v-radio
                            color="primary"
                            label="Project"
                            value="project"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        !editMode &&
                        allocation === 'budget' &&
                        (!budgetCategories || budgetCategories.length === 0)
                      "
                    >
                      <v-col>
                        <v-alert type="info" color="info" border="left" text>
                          No budgets were found for the deparment and expected
                          invoice date
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        !editMode &&
                        allocation === 'project' &&
                        (!projects || projects.length === 0)
                      "
                    >
                      <v-col>
                        <v-alert type="info" color="info" border="left" text>
                          No projects were found for the department and expected
                          invoice date
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row v-if="!editMode">
                      <v-col cols="12">
                        <v-row
                          v-if="budgetCategories && budgetCategories.length > 0"
                        >
                          <v-col cols="12" class="pb-0">
                            <label>Budget Line</label>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="item.budgetLineID"
                              :items="budgetCategories"
                              :rules="requiredRule"
                              outlined
                              item-value="id"
                              item-text="title"
                              dense
                              clearable
                              autocomplete="off"
                              persistent-hint
                              hide-details="auto"
                              hint="Select a budget item to allocate this purchase order against"
                              :disabled="
                                budgetCategories &&
                                budgetCategories.length === 0
                              "
                              :loading="$apollo.loading"
                            >
                              <template v-slot:selection="data">{{
                                data.item.title
                              }}</template>
                              <template v-slot:item="data">
                                <template
                                  v-if="[typeof data.item !== 'object']"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-row no-gutters>
                                        <v-col>{{ data.item.title }}</v-col>
                                        <v-col
                                          v-if="
                                            currentUser &&
                                            currentUser.userRole !== 'user'
                                          "
                                        >
                                          <v-row no-gutters justify="end">
                                            <div>
                                              <span class="overline"
                                                >Balance</span
                                              >
                                              {{
                                                (data.item.balanceAmount / 100)
                                                  | currency(
                                                    data.item.currency.symbol,
                                                    2,
                                                    {
                                                      spaceBetweenAmountAndSymbol: true,
                                                    }
                                                  )
                                              }}
                                            </div>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content
                                    v-text="data.item"
                                  ></v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row v-if="!editMode && projects && projects.length > 0">
                      <v-col cols="12" class="py-0">
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <label>Project</label>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="projectID"
                              :items="projects"
                              :rules="requiredRule"
                              outlined
                              item-value="id"
                              item-text="name"
                              dense
                              clearable
                              autocomplete="off"
                              persistent-hint
                              hide-details="auto"
                              hint="Select a project you want to allocate against"
                              :disabled="projects && projects.length === 0"
                              :loading="$apollo.loading"
                              @change="getProjectCategories"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        !editMode &&
                        projects &&
                        projects.length > 0 &&
                        projectID
                      "
                    >
                      <v-col cols="12" class="py-0">
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <label>Project Item</label>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="item.projectLine"
                              :items="projectCategories"
                              :rules="[
                                (v) => !!v || 'Field is required',
                                (v) => !!v.id || 'Field is required',
                              ]"
                              outlined
                              return-object
                              item-text="title"
                              dense
                              clearable
                              persistent-hint
                              hide-details="auto"
                              hint="Select a project item to allocate this purchase order against"
                              :disabled="!projectID"
                              :loading="$apollo.loading"
                            >
                              <template v-slot:selection="data">{{
                                data.item.title
                              }}</template>
                              <template v-slot:item="data">
                                <template
                                  v-if="[typeof data.item !== 'object']"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-row no-gutters>
                                        <v-col>{{ data.item.title }}</v-col>
                                        <v-col
                                          v-if="
                                            currentUser &&
                                            currentUser.userRole !== 'user'
                                          "
                                        >
                                          <v-row no-gutters justify="end">
                                            <div>
                                              <span class="overline"
                                                >Balance</span
                                              >
                                              {{
                                                (data.item.balanceAmount / 100)
                                                  | currency(
                                                    data.item.currency.symbol,
                                                    2,
                                                    {
                                                      spaceBetweenAmountAndSymbol: true,
                                                    }
                                                  )
                                              }}
                                            </div>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content
                                    v-text="data.item"
                                  ></v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        item &&
                        ((item.projectLine && item.projectLine.id) ||
                          item.budgetLineID)
                      "
                    >
                      <v-col cols="3" class="py-0">
                        <v-row>
                          <v-col cols="12">
                            <label>Currency</label>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-select
                              v-model="item.pricing.currency"
                              :items="currencies"
                              :rules="requiredRule"
                              :disabled="editMode"
                              outlined
                              autocomplete
                              return-object
                              item-text="isoName"
                              hide-details="auto"
                              dense
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6" class="py-0">
                        <v-row>
                          <v-col cols="12">
                            <label>Price</label>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-currency-field
                              v-model="item.pricing.displayAmount"
                              autocomplete="off"
                              outlined
                              :rules="requiredRule"
                              hide-details="auto"
                              dense
                            ></v-currency-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="3" class="py-0">
                        <v-row>
                          <v-col cols="12">
                            <label>Payment Terms</label>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-select
                              v-model="item.paymentTermID"
                              :items="paymentTerms"
                              :rules="requiredRule"
                              outlined
                              autocomplete
                              item-value="id"
                              item-text="name"
                              hide-details="auto"
                              dense
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            rounded
            :disabled="processing"
            @click="close(false)"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            rounded
            :loading="processing"
            :disabled="
              !(item.projectLine && item.projectLine.id) && !item.budgetLineID
            "
            @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import PURCHASE_ORDERS from '@/graphql/PurchaseOrders.gql'
import SupplierEdit from '@/modules/finance/components/core/SupplierEdit.vue'
import CREATE_PURCHASE_ORDER from '@/graphql/PurchaseOrderCreate.gql'
import UPDATE_PURCHASE_ORDER from '@/graphql/PurchaseOrderUpdate.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import DEPARTMENT_LIST from '@/graphql/Departments.gql'
import SUPPLIER_LIST from '@/graphql/Suppliers.gql'
import BUDGET_CATEGORIES_BY_DEPARTMENT_YEAR_LIST from '@/graphql/BudgetCategoriesByDepartmentYear.gql'
import PROJECTS_BY_DEPARTMENT_SERVICE_DATE from '@/graphql/ProjectsByDepartmentSeviceDate.gql'
import PROJECT_CATEGORIES from '@/graphql/ProjectCategories.gql'
import PAYMENT_TERMS from '@/graphql/PaymentTerms.gql'
import CREATE_SUPPLIER from '@/graphql/SupplierCreate.gql'

export default {
  name: 'PurchaseOrderEdit',
  components: { SupplierEdit },
  props: {
    editedItem: { type: Object, default: null },
    financialYears: { type: Array, default: () => [] },
    editMode: Boolean,
    open: Boolean,
  },
  data: () => ({
    searchSupplier: null,
    suppliers: { items: [] },

    serviceDate: false,
    valid: true,
    edit: true,
    supplierDialog: false,
    errorMsg: null,
    processing: false,
    disabled: false,
    allocation: 'budget',
    supplier: null,
    stateList: ['approved', 'rejected', 'review', 'draft'],
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    // item: {
    //   title: '',
    //   description: '',
    //   supplier: null,
    //   pricing: {},
    //   priceLine: null,
    //   status: 'draft',
    //   serviceDate: new Date().toISOString(),
    //   invoiceDate: new Date().toISOString()
    // },
    requiredRule: [
      (v) => !!v || 'Field is required',
      (v) => !/^\s+$/.test(v) || 'Field is required',
    ],
    requiredSelectRule: [(v) => !!v || 'Field is required'],
    dateRule: [(v) => !!v || 'Date is required'],
    budgetCategories: [],
    projects: [],
    projectID: null,
    projectCategories: [],
    defaultSupplierItem: {
      id: null,
      supplierCode: null,
      name: null,
      email: null,
      phone: null,
      paymentTerm: null,
      website: null,
      address: {
        id: '',
        email: '',
        phone: '',
        line1: '',
        line2: '',
        line3: '',
        city: '',
        postCode: '',
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null,
      status: 'active',
    },
    editedSupplierItem: {
      id: null,
      supplierCode: null,
      name: null,
      email: null,
      phone: null,
      paymentTerm: null,
      website: null,
      address: {
        id: '',
        email: '',
        phone: '',
        line1: '',
        line2: '',
        line3: '',
        city: '',
        postCode: '',
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null,
      status: 'active',
    },
  }),
  apollo: {
    currencies: CURRENCY_LIST,
    departments: DEPARTMENT_LIST,
    suppliers: {
      query: SUPPLIER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchSupplier,
            page: 1,
            pageSize: 10,
          },
        }
      },
      fetchPolicy: 'network-only',
      debounce: 100,
      // result(result) {
      //   this.page = result.data.suppliers.page
      //   this.itemsPerPage = result.data.suppliers.pageSize
      //   this.itemCount = result.data.suppliers.total
      // }
    },
    paymentTerms: PAYMENT_TERMS,
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      },
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      },
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    unFormattedInvoiceDate() {
      return this.item.serviceDate
    },
    formTitle() {
      return this.editMode ? 'Edit Purchase Order' : 'New Purchase Order'
    },

    formattedInvoiceDate() {
      return this.item.serviceDate
        ? this.$moment(this.item.serviceDate).format('LL')
        : ''
    },
  },
  watch: {
    allocation(val) {
      switch (val) {
        case 'project':
          this.item.budgetLineID = null
          break
        case 'budget':
          this.item.projectLine = null
          break
        default:
          this.item.projectLine = null
          this.item.budgetLineID = null
          break
      }
    },
    open(val) {
      if (val) {
        this.item = this._.cloneDeep(this.editedItem)

        this.setDefaults(val)
      }
    },
    formattedInvoiceDate(date) {
      let dateObj = this.$moment(date, 'LL')

      if (this.item.departmentID) {
        // Get list of budgetCategories
        var year = this._.find(
          this.financialYears,
          (item) =>
            dateObj.isSameOrAfter(this.$moment(item.start).format('LL')) &&
            dateObj.isSameOrBefore(this.$moment(item.end).format('LL'))
        )

        if (year) {
          if (this.allocation === 'budget') {
            this.getBudgetCategories(this.item.departmentID, year.id)
          } else if (this.allocation === 'project') {
            this.getProjects(this.item.departmentID, this.item.serviceDate)
          }
        }
      }
    },
  },
  methods: {
    setDefaults(val) {
      if (this.editMode) {
        if (this.item.projectLine && this.item.projectLine.id) {
          this.allocation = 'project'
        }
      }

      if (!this.editMode) {
        if (!this.item.pricing && !this.item.pricing.id) {
          let defaultCurrency = this._.find(
            this.currencies,
            (item) => item.defaultCurrency
          )

          this.item.pricing = {
            displayAmount: 0,
            currency: defaultCurrency,
            baseCurrency: defaultCurrency,
            exchangeRate: defaultCurrency.exchangeRate,
          }
        }

        if (this.item.supplier && this.item.supplier.id) {
          this.searchSupplier = this.item.supplier.name
        }

        // Set default payment term
        if (val && !this.item.paymentTermID && this.paymentTerms) {
          var defaultTerm = this._.find(
            this.paymentTerms,
            (item) => item.defaultPaymentTerm
          )
          this.item.paymentTermID = defaultTerm.id
        }
      }

      if (val && this.unFormattedInvoiceDate && this.item.departmentID) {
        // Get list of budgetCategories
        var year = this._.find(
          this.financialYears,
          (item) =>
            this.$moment(this.unFormattedInvoiceDate).isSameOrAfter(
              this.$moment(item.start)
            ) &&
            this.$moment(this.unFormattedInvoiceDate).isSameOrBefore(
              this.$moment(item.end)
            )
        )
        if (year) {
          if (this.allocation === 'budget') {
            this.getBudgetCategories(this.item.departmentID, year.id)
          } else if (this.allocation === 'project') {
            this.getProjects(this.item.departmentID, this.item.serviceDate)
          }
        }
      }
    },
    clearLines(departmentID) {
      this.item.budgetLine = {}
      this.item.budgetLineID = ''
      this.item.projectLine = {}
      this.projectID = null
      this.item.projectLineID = ''
      this.budgetCategories = []
      this.projects = []

      if (this.unFormattedInvoiceDate) {
        // Get list of budgetCategories
        var year = this._.find(
          this.financialYears,
          (item) =>
            this.$moment(this.unFormattedInvoiceDate).isSameOrAfter(
              this.$moment(item.start)
            ) &&
            this.$moment(this.unFormattedInvoiceDate).isSameOrBefore(
              this.$moment(item.end)
            )
        )
        if (year) {
          if (this.allocation === 'budget') {
            this.getBudgetCategories(departmentID, year.id)
          } else if (this.allocation === 'project') {
            this.getProjects(departmentID, this.item.serviceDate)
          }
        }
      }
    },
    getBudgetCategories(departmentID, financialYearID) {
      // this.item.budgetID = null
      // this.item.budgetLineID = null
      this.$apollo
        .query({
          query: BUDGET_CATEGORIES_BY_DEPARTMENT_YEAR_LIST,
          variables: {
            departmentID: departmentID,
            financialYearID: financialYearID,
          },
        })
        .then((res) => {
          var that = this
          if (res.data.budgetCategoriesByDepartmentYear.length > 0) {
            this.budgetCategories = []

            this._.forEach(
              res.data.budgetCategoriesByDepartmentYear,
              function (item, idx) {
                if (item.lineItems && item.lineItems.length > 0) {
                  let header = {
                    header: item.name,
                  }
                  let divider = {
                    divider: true,
                  }

                  if (idx > 0) {
                    that.budgetCategories.push(divider)
                  }

                  if (item.lineItems && item.lineItems.length > 0) {
                    that.budgetCategories.push(header, ...item.lineItems)
                  }
                }
              }
            )
          } else {
            this.budgetCategories = []
          }
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'A general server error occured. Please try again later',
          })

          return this.close(false)
        })
    },
    getProjects(departmentID, serviceDate) {
      this.$apollo
        .query({
          query: PROJECTS_BY_DEPARTMENT_SERVICE_DATE,
          variables: {
            departmentID: departmentID,
            serviceDate: this.$moment(serviceDate).utc(),
          },
        })
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.projectsByDepartmentSeviceDate.length > 0
          ) {
            this.projects = res.data.projectsByDepartmentSeviceDate
          } else {
            this.projects = []
          }
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'A general server error occured. Please try again later',
          })

          return this.close(false)
        })
    },
    getProjectCategories() {
      var that = this
      this.projectCategories = []
      if (this.projectID) {
        this.$apollo
          .query({
            query: PROJECT_CATEGORIES,
            variables: {
              projectID: that.projectID,
            },
          })
          .then((res) => {
            if (res.data.projectCategories.length > 0) {
              this.projectCategories = []

              this._.forEach(res.data.projectCategories, function (item, idx) {
                if (item.lineItems && item.lineItems.length > 0) {
                  let header = {
                    header: item.name,
                  }
                  let divider = {
                    divider: true,
                  }

                  if (idx > 0) {
                    that.projectCategories.push(divider)
                  }

                  that.projectCategories.push(header, ...item.lineItems)
                }
              })
            } else {
              this.projectCategories = []
            }
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'A general server error occured. Please try again later',
            })

            return this.close(false)
          })
      } else {
        this.projectCategories = []
        this.projectID = null
      }
    },
    openSupplierDialog() {
      if (this.searchSupplier) {
        this.editedSupplierItem.name = this.searchSupplier
      }

      this.supplierDialog = true

      this.searchSupplier = null
    },
    closeSupplierDialog(success, supplier) {
      if (success && supplier) {
        // set the supplier to the purchase order item
        this.suppliers.items.push(supplier)
        this.item.supplier = supplier
        this.searchSupplier = supplier.name

        this.setDefaults(true)
      }
      this.supplierDialog = false

      this.editedSupplierItem = this._.cloneDeep(this.defaultSupplierItem)
    },
    close(success, id) {
      if (this.$refs.purchaseOrderForm) {
        this.$refs.purchaseOrderForm.resetValidation()
      }
      this.processing = false
      this.$emit('close-edit-purchase-order-dialog', success, id)
    },
    save() {
      this.errorMsg = null

      if (this.$refs.purchaseOrderForm.validate()) {
        if (this.editMode) {
          // Update Item
          this.processing = true

          let data = {
            id: this.item.id,
            title: this.item.title,
            description: this.item.description,
            departmentID: this.item.departmentID,
            paymentTermID: this.item.paymentTermID,
            budgetLineID: this.item.budgetLineID || null,
            projectLineID: this.item.projectLine
              ? this.item.projectLine.id
              : null,
            pricing: {
              displayAmount: this.item.pricing.displayAmount
                ? Math.round(this.item.pricing.displayAmount * 100)
                : 0,
              currencyID: this.item.pricing.currency.id,
              baseCurrencyID: this.item.pricing.baseCurrency.id,
              exchangeRate: this.item.pricing.currency.exchangeRate,
            },
            supplierID: this.item.supplier.id,
            serviceDate: this.$moment(this.item.serviceDate).utc(),
          }

          this.$apollo
            .mutate({
              mutation: UPDATE_PURCHASE_ORDER,
              variables: {
                input: data,
              },
            })
            .then((result) => {
              this.close(true, result.data.updatePurchaseOrder.id)
            })
            .catch(() => {
              this.processing = false
              this.errorMsg = 'Somthing went wrong please try again later'
            })
        } else {
          this.processing = true
          let baseCurrency = null

          if (this.allocation === 'project') {
            baseCurrency = this._.find(
              this.currencies,
              (item) => item.id === this.item.projectLine.baseCurrency.id
            )
          } else {
            let budgetLine = this._.find(
              this.budgetCategories,
              (item) => item.id === this.item.budgetLineID
            )

            baseCurrency = budgetLine.baseCurrency
          }

          // New Item

          let data = {
            title: this.item.title,
            description: this.item.description,
            departmentID: this.item.departmentID,
            paymentTermID: this.item.paymentTermID,
            budgetLineID: this.item.budgetLineID || null,
            projectLineID: this.item.projectLine
              ? this.item.projectLine.id
              : null,
            pricing: {
              displayAmount: this.item.pricing.displayAmount
                ? Math.round(this.item.pricing.displayAmount * 100)
                : 0,
              currencyID: this.item.pricing.currency.id,
              baseCurrencyID: baseCurrency.id,
              exchangeRate: this.item.pricing.currency.exchangeRate,
            },
            supplierID: this.item.supplier.id,
            serviceDate: this.$moment(this.item.serviceDate).utc(),
          }

          this.$apollo
            .mutate({
              mutation: CREATE_PURCHASE_ORDER,
              variables: {
                input: data,
              },
              // update: (store, { data: { createPurchaseOrder } }) => {
              //   // Read the data from our cache for this query.
              //   const data = store.readQuery({
              //     query: PURCHASE_ORDERS
              //   })
              //   // Add our requirement from the mutation to the end
              //   data.purchaseOrders.unshift(createPurchaseOrder)
              //   // Write our data back to the cache.
              //   store.writeQuery({ query: PURCHASE_ORDERS, data })
              // }
            })
            .then((result) => {
              this.close(true, result.data.createPurchaseOrder.id)
            })
            .catch(() => {
              this.processing = false
              this.errorMsg = 'Somthing went wrong please try again later'
            })
        }
      }
    },
    saveSupplier(data) {
      this.$apollo
        .mutate({
          mutation: CREATE_SUPPLIER,
          variables: {
            input: data,
          },
          // update: (store, { data: { createSupplier } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: SUPPLIER_LIST
          //   })
          //   // Add our requirement from the mutation to the end
          //   data.suppliers.unshift(createSupplier)
          //   // Write our data back to the cache.
          //   store.writeQuery({ query: SUPPLIER_LIST, data })
          // }
        })
        .then((result) => {
          this.closeSupplierDialog(true, result.data.createSupplier)
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'A general server error occured. Please try again later',
          })
        })
    },
  },
}
</script>

<style lang="scss">
#edit-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  // height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
