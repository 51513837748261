var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"plain-table",attrs:{"headers":_vm.headers,"items":_vm.projects,"loading":_vm.loading,"hide-default-footer":!_vm.projects || _vm.projects.length === 0,"footer-props":null,"mobile-breakpoint":null,"items-per-page":-1},on:{"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"primary--text text-truncate body-1",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('v-chip',{attrs:{"color":"info lighten-2 font-weight-bold upperCaseSpacing","x-small":""}},[_vm._v(_vm._s(item.department.name))]),_c('div',{staticClass:"grey--text text--darken-1 body-2 text-truncate mt-1"},[_vm._v(" "+_vm._s(item.owner.firstName + ' ' + item.owner.lastName)+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.stateColor(item.status),"x-small":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.forecastAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm._f("currency")((item.forecastAmount / 100),item.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+" ")])]}},{key:"item.pendingAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm._f("currency")((item.pendingAmount / 100),item.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+" ")])]}},{key:"item.actualAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm._f("currency")((item.actualAmount / 100),item.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+" ")])]}},{key:"item.balanceAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate info--text"},[_vm._v(" "+_vm._s(_vm._f("currency")((item.balanceAmount / 100),item.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }