<template>
  <v-row justify="center" class="supplier-list">
    <supplier-edit
      :open="dialog"
      :edited-item="editedItem"
      :edit-mode="edit"
      @close-edit-supplier-dialog="close"
    ></supplier-edit>
    <v-col cols="12" class="py-0">
      <v-data-table
        :headers="headers"
        :items="suppliers"
        :loading="loading"
        :options.sync="listOptions"
        :server-items-length="itemCount"
        :hide-default-header="!suppliers || suppliers.length === 0"
        :hide-default-footer="
          !suppliers || itemCount < listOptions.itemsPerPage + 1
        "
        :footer-props="footerOptions"
        :mobile-breakpoint="null"
        class="plain-table"
        @click:row="navToDetail"
      >
        <template v-slot:item.name="{ item }">
          <div class="py-2">
            <!-- <v-badge :color="stateColor(item.status)" dot left inline> -->
            <div
              style="white-space: nowrap"
              class="primary--text text-truncate body-1 pl-2"
            >
              {{ item.name }}
            </div>
            <!-- </v-badge> -->
          </div>
        </template>

        <template v-slot:item.updatedAt="{ item }">{{
          item.updatedAt | moment('from', 'now')
        }}</template>
        <template v-slot:item.address="{ item }">
          <div class="text-truncate">
            <span v-if="item.address && item.address.city">{{
              item.address.city
            }}</span
            ><span
              v-if="item.address && item.address.city && item.address.country"
              >,&nbsp;</span
            ><span v-if="item.address && item.address.country"
              >{{ getFullCountryName(item.address.country) }}
            </span>
          </div>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <div class="text-truncate">
            {{ item.updatedAt | moment('from', 'now') }}
          </div></template
        >

        <template v-slot:item.status="{}">
          &nbsp;
        </template>
        <template v-slot:no-data>
          <message-box
            icon="mdi-emoticon-neutral-outline"
            title="No Results"
            caption="Try adjusting your filters to get some results"
            icon-color="info"
            title-color="info--text"
            caption-color="grey--text darken-2 font-weight-medium"
          ></message-box>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import SupplierEdit from '@/modules/finance/components/core/SupplierEdit.vue'
import country from 'country-list'

export default {
  name: 'SupplierListItems',
  components: { SupplierEdit, MessageBox },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    itemCount: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    listOptions: { page: 1, itemsPerPage: 15 },
    footerOptions: {
      'items-per-page-options': [10, 15, 25, 50]
    },

    dialog: false,
    edit: false,
    defaultItem: {
      name: '',
      status: 'active'
    },
    editedItem: {
      name: '',
      status: 'active'
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'SupplierCode',
          align: 'left',
          sortable: false,
          value: 'supplierCode'
        },
        {
          text: 'Address',
          align: 'left',
          sortable: false,
          value: 'address'
        },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          value: 'email'
        },
        {
          text: 'Phone',
          align: 'left',
          sortable: false,
          value: 'phone'
        },
        {
          text: 'Website',
          align: 'left',
          sortable: false,
          value: 'website'
        },
        {
          text: 'Last Update',
          align: 'left',
          value: 'updatedAt',
          sortable: false
        }
        // { text: 'Created', align: 'left', value: 'createdAt', sortable: true },
        // {
        //   text: '',
        //   align: 'left',
        //   value: 'status',
        //   // sortable: true,
        //   filter: this.stateFilterValue
        // }
        // { text: '', align: 'right', value: 'action', sortable: false }
      ]
    },
    suppliers() {
      return this.data
    }
  },
  watch: {
    listOptions(e) {
      return this.$emit('set-options', e)
    }
  },
  methods: {
    getFullCountryName(code) {
      return country.getName(code)
    },
    navToDetail(item) {
      this.$router.push({
        name: 'SupplierDetail',
        params: { supplierID: item.id }
      })
    },
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })
      }
    },
    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },
    stateColor: item => {
      if (item === 'suspended') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'active') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    }
    // navToDetail (item) {
    //   this.$router.push({
    //     name: 'SupplierDetail',
    //     params: { supplierID: item.id }
    //   })
    // }
  }
}
</script>

<style lang="scss">
.plain-table .v-input__slot {
  background: transparent !important;
}

.plain-table.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content) {
  cursor: pointer !important;
}
</style>
