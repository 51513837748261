<template>
  <v-row>
    <!-- Details -->
    <v-col v-if="supplier" cols="12" md="6" lg="6" xl="6">
      <v-card flat class="mb-3">
        <v-card-title class="mb-3"
          ><span>Details</span><v-spacer></v-spacer
          ><v-btn
            v-if="
              editMode &&
                ((supplier && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            text
            small
            rounded
            :disabled="processing"
            class="mr-2"
            @click="cancelEdit(false)"
          >
            cancel
          </v-btn>
          <v-btn
            v-if="
              editMode &&
                ((supplier && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="primary"
            rounded
            small
            :loading="processing"
            @click="saveSupplier"
          >
            save
          </v-btn>
          <v-btn
            v-if="
              !editMode &&
                ((supplier && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="grey"
            outlined
            rounded
            small
            :disabled="processing"
            @click="editSupplier"
          >
            edit
          </v-btn></v-card-title
        >
        <v-card-text>
          <div v-if="errorMsg !== ''" class="mb-3">
            <v-alert type="error" class="mb-0">
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="supplierForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12" md="6">
                <div v-if="!editMode" class="grey--text">
                  <label>Name</label>
                </div>
                <div v-if="!editMode" class="font-weight-medium">
                  {{ item.name }}
                </div>
                <v-text-field
                  v-else
                  v-model="editedItem.name"
                  autocomplete="off"
                  label="Name"
                  hide-details="auto"
                  outlined
                  :rules="requiredRule"
                  dense
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <div v-if="!editMode" class="grey--text">
                  <label>Supplier Code</label>
                </div>
                <div v-if="!editMode" class="font-weight-medium">
                  {{ item.supplierCode }}
                </div>
                <v-text-field
                  v-else
                  v-model="editedItem.supplierCode"
                  autocomplete="off"
                  label="Supplier Code"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-row>
                  <v-col>
                    <div v-if="!editMode" class="grey--text">
                      <label>Company Registration</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      {{ item.companyRegistration }}
                    </div>
                    <v-text-field
                      v-else
                      v-model="editedItem.companyRegistration"
                      autocomplete="off"
                      label="Company Registration"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <div v-if="!editMode" class="grey--text">
                      <label>VAT ID</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      {{ item.vatRegistration }}
                    </div>
                    <v-text-field
                      v-else
                      v-model="editedItem.vatRegistration"
                      autocomplete="off"
                      label="VAT ID"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-row>
                  <v-col cols="12" md="6">
                    <div v-if="!editMode" class="grey--text">
                      <label>Payment Term</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      <span
                        v-if="
                          editedItem &&
                            editedItem.paymentTerm &&
                            editedItem.paymentTerm.id
                        "
                        >{{ item.paymentTerm.name }}</span
                      >
                    </div>
                    <v-select
                      v-else
                      v-model="editedItem.paymentTerm"
                      :items="paymentTerms"
                      :rules="requiredSelectRule"
                      outlined
                      autocomplete
                      return-object
                      item-text="name"
                      hide-details
                      dense
                      label="Payment Term"
                    ></v-select>
                  </v-col>

                  <v-col>
                    <div v-if="!editMode" class="grey--text">
                      <label>Currency</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      <span v-show="item && item.currency">{{
                        item.currency.name
                      }}</span>
                    </div>
                    <v-select
                      v-else
                      v-model="editedItem.currency"
                      :items="currencies"
                      :rules="requiredSelectRule"
                      outlined
                      autocomplete
                      return-object
                      item-text="name"
                      hide-details
                      dense
                      label="Currency"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-row>
                  <v-col cols="12" md="6">
                    <div v-if="!editMode" class="grey--text">
                      <label>Bank Account</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      <span v-show="item && item.bankAccount">{{
                        item.bankAccount
                      }}</span>
                    </div>
                    <v-text-field
                      v-else
                      v-model="editedItem.bankAccount"
                      autocomplete="off"
                      label="Bank Account"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <div v-if="!editMode" class="grey--text">
                      <label>Swift / BIC</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      <span v-show="item && item.swift">{{ item.swift }}</span>
                    </div>
                    <v-text-field
                      v-else
                      v-model="editedItem.swift"
                      autocomplete="off"
                      label="Swift / BIC"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col>
                <div v-if="!editMode" class="grey--text">
                  <label>Email</label>
                </div>
                <div v-if="!editMode" class="font-weight-medium">
                  {{ item.email }}
                </div>
                <v-text-field
                  v-else
                  v-model="editedItem.email"
                  autocomplete="off"
                  label="Email"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <div v-if="!editMode" class="grey--text">
                  <label>Phone</label>
                </div>
                <div v-if="!editMode" class="font-weight-medium">
                  {{ item.phone }}
                </div>
                <v-text-field
                  v-else
                  v-model="editedItem.phone"
                  autocomplete="off"
                  label="Phone"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="12" md="6">
                <div v-if="!editMode" class="grey--text">
                  <label>Website</label>
                </div>
                <div v-if="!editMode" class="font-weight-medium">
                  {{ item.website }}
                </div>
                <v-text-field
                  v-else
                  v-model="editedItem.website"
                  autocomplete="off"
                  label="Website"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <div class="grey--text">
              <label>Address</label>
            </div>
            <v-row v-if="!editMode" class="pb-3">
              <v-col>
                <div
                  v-if="item.address && item.address.line1"
                  class="font-weight-medium"
                >
                  {{ item.address.line1 }}
                </div>
                <div
                  v-if="item.address && item.address.line2"
                  class="font-weight-medium"
                >
                  {{ item.address.line2 }}
                </div>
                <div
                  v-if="item.address && item.address.line3"
                  class="font-weight-medium"
                >
                  {{ item.address.line3 }}
                </div>
                <div
                  v-if="item.address && item.address.postCode"
                  class="font-weight-medium"
                >
                  {{ item.address.postCode }}
                </div>
                <div
                  v-if="item.address && item.address.city"
                  class="font-weight-medium"
                >
                  {{ item.address.city }}
                </div>
                <div
                  v-if="item.address && item.address.country"
                  class="font-weight-medium"
                >
                  {{ getFullCountryName(item.address.country) }}
                </div>
              </v-col>
            </v-row>
            <v-row v-else no-gutters class="pb-3">
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.address.line1"
                      autocomplete="off"
                      label="Line 1"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.address.line2"
                      autocomplete="off"
                      label="Line 2"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.address.line3"
                      autocomplete="off"
                      label="Line 3"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.address.postCode"
                      autocomplete="off"
                      label="Postcode"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.address.city"
                      autocomplete="off"
                      label="City"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="editedItem.address.country"
                      autocomplete="off"
                      :items="countryList"
                      label="Country"
                      hide-details="auto"
                      outlined
                      dense
                      item-text="name"
                      item-value="code"
                      auto-select-first
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import UPDATE_SUPPLIER from '@/graphql/SupplierUpdate.gql'
import country from 'country-list'
// import CREATE_SUPPLIER_PAYMENT_DETAIL from '@/graphql/SupplierPaymentDetailCreate.gql'
// import UPDATE_SUPPLIER_PAYMENT_DETAIL from '@/graphql/SupplierPaymentDetailUpdate.gql'
// import REMOVE_SUPPLIER_PAYMENT_DETAIL from '@/graphql/SupplierPaymentDetailRemove.gql'

export default {
  name: 'SupplierDetail',
  components: {},
  props: {
    supplier: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    paymentTerms: { type: Array, default: () => [] },
    authLevel: { type: Number, default: () => 0 },
    currencies: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [
      v => !!v || 'Field is required',
      v => !!v.id || 'Field is required'
    ],
    editDialog: false,
    processing: false,
    editMode: false,
    errorMsg: '',
    editTerm: {
      percentage: 100,
      days: 30,
      description: null
    },
    defaultItem: {
      id: null,
      supplierCode: null,
      name: null,
      email: null,
      phone: null,
      paymentTerm: null,
      bankAccount: null,
      swift: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null,
      status: null
    },
    editedItem: {
      id: null,
      supplierCode: null,
      name: null,
      email: null,
      phone: null,
      paymentTerm: null,
      bankAccount: null,
      swift: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null,
      status: null
    }
  }),
  computed: {
    item() {
      this.setEditedItem()
      return this._.cloneDeep(this.supplier)
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    }
  },
  methods: {
    getFullCountryName(code) {
      return country.getName(code)
    },
    setEditedItem() {
      this.editedItem = this._.cloneDeep(this.supplier)
      if (!this.supplier.address) {
        this.editedItem.address = this._.cloneDeep(this.defaultItem.address)
      }
    },
    // Supplier methods
    cancelEdit(success) {
      if (!success) {
        this.$refs.supplierForm.resetValidation()
        this.setEditedItem()
      }

      // this.editDialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = ''
        if (this.editMode === true) {
          msg = 'Supplier Updated'
        } else {
          msg = 'Supplier Added'
        }

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    },

    editSupplier() {
      this.editMode = true
    },

    saveSupplier() {
      this.errorMsg = ''

      if (this.$refs.supplierForm.validate()) {
        var data = {
          id: this.editedItem.id,
          supplierCode: this.editedItem.supplierCode,
          name: this.editedItem.name,
          email: this.editedItem.email,
          phone: this.editedItem.phone,
          website: this.editedItem.website,
          companyRegistration: this.editedItem.companyRegistration,
          vatRegistration: this.editedItem.vatRegistration,
          bankAccount: this.editedItem.bankAccount,
          swift: this.editedItem.swift,
          status: this.editedItem.status,
          address: {
            id:
              this.editedItem.address && this.editedItem.address.id
                ? this.editedItem.address.id
                : null,
            line1:
              this.editedItem.address && this.editedItem.address.line1
                ? this.editedItem.address.line1
                : null,
            line2:
              this.editedItem.address && this.editedItem.address.line2
                ? this.editedItem.address.line2
                : null,
            line3:
              this.editedItem.address && this.editedItem.address.line3
                ? this.editedItem.address.line3
                : null,
            city:
              this.editedItem.address && this.editedItem.address.city
                ? this.editedItem.address.city
                : null,
            postCode:
              this.editedItem.address && this.editedItem.address.postCode
                ? this.editedItem.address.postCode
                : null,
            country:
              this.editedItem.address && this.editedItem.address.country
                ? this.editedItem.address.country
                : null
          },
          currencyID: this.editedItem.currency
            ? this.editedItem.currency.id
            : null,
          paymentTermID: this.editedItem.paymentTerm
            ? this.editedItem.paymentTerm.id
            : null
        }

        this.processing = true

        // Save Item
        this.$apollo
          .mutate({
            mutation: UPDATE_SUPPLIER,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.cancelEdit(true)
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.team-selection >>> .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.size-delimiter {
  &:last-child {
    span {
      visibility: hidden;
    }
  }
}
</style>
