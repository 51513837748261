var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"supplier-list",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"plain-table",attrs:{"headers":_vm.headers,"items":_vm.purchaseOrders,"loading":_vm.loading,"hide-default-footer":!_vm.purchaseOrders || _vm.purchaseOrders.length === 0,"footer-props":null,"mobile-breakpoint":null,"items-per-page":-1},on:{"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.poNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.poNumber))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2 pl-3"},[_c('div',{staticClass:"text-truncate body-1 primary--text"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"text-truncate body-2 grey--text text--darken-1 mt-1"},[_vm._v(" "+_vm._s(item.supplier.name)+" ")])])]}},{key:"item.pricing.forecastAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm._f("currency")((item.pricing.forecastAmount / 100),item.pricing.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+" ")])]}},{key:"item.pricing.actualAmount",fn:function(ref){
var item = ref.item;
return [(item.status === 'approved' || item.status === 'closed')?_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm._f("currency")((item.pricing.actualAmount / 100),item.pricing.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+" ")]):_vm._e()]}},{key:"item.pricing.balanceAmount",fn:function(ref){
var item = ref.item;
return [(item.status === 'approved' || item.status === 'closed')?_c('div',{staticClass:"text-truncate info--text"},[_vm._v(" "+_vm._s(_vm._f("currency")((item.pricing.balanceAmount / 100),item.pricing.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.stateColor(item.status),"x-small":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.serviceDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.serviceDate,'DD/MM/YYYY'))+" ")]}},{key:"item.invoiceDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.invoiceDate,'DD/MM/YYYY'))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }