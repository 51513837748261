<template>
  <div class="page-wrap">
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-row>
          <!-- <v-col
            v-if="currentUser && currentUser.userRole !== 'user'"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
          >
            <v-card :loading="$apollo.queries.myOpenReviews.loading" outlined>
              <v-card-title class="subtitle-1 text-uppercase"
                >My Reviews</v-card-title
              >
              <v-data-table
                :headers="reviewHeaders"
                :items="myOpenReviews"
                :hide-default-footer="myOpenReviews.length < 11"
                :items-per-page="10"
                :class="[
                  myOpenReviews && myOpenReviews.length > 0 ? 'plain-table' : ''
                ]"
                @click:row="navToReview"
              >
                <template v-slot:item.status="{ item }">
                  <v-chip :color="stateColor(item.status)" small label>{{
                    item.status === 'open' ? 'review' : item.status
                  }}</v-chip>
                </template>
                <template v-slot:item.departmentName="{ item }">
                  <v-chip color="primary" small label outlined class="mr-3">{{
                    item.departmentName
                  }}</v-chip>
                </template>
                <template v-slot:no-data
                  ><span
                    >Great, you've worked through all your reviews!</span
                  ></template
                >
              </v-data-table>
            </v-card>
          </v-col> -->
          <v-col
            v-if="
              currentUser &&
                (currentUser.userRole === 'manager' ||
                  currentUser.userRole === 'seniormanager' ||
                  currentUser.userRole === 'director' ||
                  currentUser.isSuperAdmin)
            "
            cols="12"
            md="6"
            lg="6"
            xl="4"
          >
            <v-card :loading="$apollo.queries.budgets.loading" flat>
              <v-card-title>
                <h6 class="text-uppercase grey--text text--darken-1">
                  Latest Budgets
                </h6>
              </v-card-title>
              <v-simple-table class="plain-table" dense>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="item in budgets.items"
                      :key="item.id"
                      @click="navToBudgetDetail(item.id)"
                    >
                      <td class="truncate-text py-2">
                        <v-badge
                          :color="budgetStateColor(item.status)"
                          dot
                          left
                          inline
                        >
                          <div class="pl-2">
                            <div
                              class="body-2 primary--text font-weight-medium"
                            >
                              {{ item.title }}
                            </div>
                            <div class="mt-1 grey--text text--darken-1">
                              <v-row>
                                <v-col class="py-0"
                                  ><span>{{
                                    item.owner.firstName +
                                      ' ' +
                                      item.owner.lastName
                                  }}</span></v-col
                                >
                              </v-row>
                            </div>
                          </div>
                        </v-badge>
                      </td>
                      <td>
                        <v-row justify="end" class="pr-3">
                          <v-chip
                            x-small
                            color="info lighten-2 font-weight-bold upperCaseSpacing"
                            >{{ item.department.name }}</v-chip
                          >
                          <!-- <v-chip
                            :color="budgetStateColor(item.status)"
                            x-small
                            class="ml-2 mr-2 text-uppercase"
                            >{{ item.status }}</v-chip
                          > -->
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" lg="6" xl="4">
            <v-card :loading="$apollo.queries.projects.loading" flat>
              <v-card-title>
                <h6 class="text-uppercase grey--text text--darken-1">
                  Latest Projects
                </h6>
              </v-card-title>
              <v-simple-table class="plain-table" dense>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="item in projects.items"
                      :key="item.id"
                      @click="navToProjectDetail(item.id)"
                    >
                      <td class="truncate-text py-2">
                        <v-badge
                          :color="budgetStateColor(item.status)"
                          dot
                          left
                          inline
                        >
                          <div class="pl-2">
                            <div
                              class="primary--text body-2 font-weight-medium"
                            >
                              {{ item.name }}
                            </div>
                            <div class="mt-1 grey--text text--darken-1">
                              <v-row>
                                <v-col class="py-0"
                                  ><span>{{
                                    item.owner.firstName +
                                      ' ' +
                                      item.owner.lastName
                                  }}</span></v-col
                                >
                              </v-row>
                            </div>
                          </div>
                        </v-badge>
                      </td>
                      <td>
                        <v-row justify="end" class="pr-3">
                          <v-chip
                            x-small
                            color="info lighten-2 font-weight-bold upperCaseSpacing"
                            >{{ item.department.name }}</v-chip
                          >
                          <!-- <v-chip
                            :color="budgetStateColor(item.status)"
                            x-small
                            class="ml-2 mr-2 text-uppercase"
                            >{{ item.status }}</v-chip
                          > -->
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import BUDGETS from '@/graphql/Budgets.gql'
import PROJECTS from '@/graphql/Projects.gql'
// import MY_OPEN_REVIEWS from '@/graphql/MyOpenReviews.gql'

export default {
  name: 'FinanceDashboard',
  components: {},
  data: () => ({
    budgets: [],
    projects: [],
    myOpenReviews: [],
    reviewHeaders: [
      { text: 'Type', value: 'auditType', sortable: true },
      { text: 'Name', value: 'reviewAsetName', sortable: false },
      { text: 'Department', value: 'departmentName', sortable: false },
      { text: 'Status', value: 'status', sortable: false }
    ]
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    userScopes() {
      return this.$store.getters.userScopes
    }
  },
  apollo: {
    // myOpenReviews: MY_OPEN_REVIEWS,
    budgets: {
      query: BUDGETS,
      variables() {
        return {
          filters: {
            page: 1,
            pageSize: 5
          }
        }
      },
      skip() {
        return (
          this.currentUser &&
          (this.currentUser.userRole === 'user' ||
            this.currentUser.userRole === 'projectmanager')
        )
      }
    },
    projects: {
      query: PROJECTS,
      variables() {
        return {
          filters: {
            page: 1,
            pageSize: 5
          }
        }
      }
    }
  },
  methods: {
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    },
    budgetStateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review' || item === 'reviewadjustment') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    },
    navToReview(item) {
      if (item.auditType === 'PROJECT') {
        this.$router.push({
          name: 'ProjectDetail',
          params: { projectID: item.reviewAssetID }
        })
      } else if (item.auditType === 'BUDGET') {
        this.$router.push({
          name: 'BudgetDetail',
          params: { budgetID: item.reviewAssetID }
        })
      } else if (item.auditType === 'PURCHASEORDER') {
        this.$router.push({
          name: 'PurchaseOrderDetail',
          params: { purchaseOrderID: item.reviewAssetID }
        })
      }
    },
    navToProjectDetail(id) {
      this.$router.push({
        name: 'ProjectDetail',
        params: { projectID: id }
      })
    },
    navToBudgetDetail(id) {
      this.$router.push({
        name: 'BudgetDetail',
        params: { budgetID: id }
      })
    },
    activeScope(scope) {
      var idx = this._.findIndex(this.userScopes, function(item) {
        return item.name === scope && item.accessLevel > 0
      })
      if (idx > -1) {
        return true
      }

      return false
    }
    // editAuth (item) {
    //   this.$emit('edit-auth-template', item)
    // }
  }
}
</script>

<style lang="scss">
.plain-table.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content) {
  cursor: pointer !important;
}
</style>
