<template>
  <v-row justify="center" class="supplier-list">
    <v-col cols="12" class="py-0">
      <v-data-table
        :headers="headers"
        :items="purchaseOrders"
        :loading="loading"
        :options.sync="listOptions"
        :server-items-length="itemCount"
        :hide-default-header="!purchaseOrders || purchaseOrders.length === 0"
        :hide-default-footer="
          !purchaseOrders || itemCount < listOptions.itemsPerPage + 1
        "
        :footer-props="footerOptions"
        :mobile-breakpoint="null"
        class="plain-table"
        @click:row="navToDetail"
      >
        <template v-slot:item.poNumber="{ item }">
          <div class="text-truncate">{{ item.poNumber }}</div>
        </template>
        <template v-slot:item.title="{ item }">
          <v-badge :color="stateColor(item.status)" dot left inline>
            <div class="py-2 pl-3">
              <div class="text-truncate body-1 primary--text">
                {{ item.title }}
              </div>
              <div class="text-truncate body-2 grey--text text--darken-1 mt-1">
                {{ item.supplier.name }}
              </div>
            </div>
          </v-badge>
        </template>
        <!-- <template v-slot:item.supplier.name="{ item }">
          <div class="text-truncate primary--text">
            {{ item.supplier.name }}
          </div>
        </template> -->
        <template v-slot:item.department.name="{ item }">
          <div class="py-2">
            <v-chip
              color="info lighten-2 font-weight-bold upperCaseSpacing"
              x-small
              >{{ item.department.name }}</v-chip
            >
            <div class="grey--text text--darken-1 body-2 text-truncate mt-1">
              {{ item.owner.firstName + ' ' + item.owner.lastName }}
            </div>
          </div>
        </template>

        <template v-slot:item.pricing.forecastAmount="{ item }">
          <div class="text-truncate">
            {{
              (item.pricing.forecastAmount / 100)
                | currency(item.pricing.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>

        <template v-slot:item.pricing.actualAmount="{ item }">
          <div
            v-if="item.status === 'approved' || item.status === 'closed'"
            class="text-truncate"
          >
            {{
              (item.pricing.actualAmount / 100)
                | currency(item.pricing.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>

        <template v-slot:item.pricing.balanceAmount="{ item }">
          <div
            v-if="item.status === 'approved' || item.status === 'closed'"
            class="text-truncate info--text"
          >
            {{
              (item.pricing.balanceAmount / 100)
                | currency(item.pricing.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>
        <template v-slot:item.status="{}">
          &nbsp;
        </template>
        <template v-slot:item.serviceDate="{ item }"
          >{{ item.serviceDate | moment('DD/MM/YYYY') }}
        </template>
        <template v-slot:item.invoiceDate="{ item }"
          >{{ item.invoiceDate | moment('DD/MM/YYYY') }}
        </template>
        <template v-slot:no-data>
          <message-box
            icon="mdi-emoticon-neutral-outline"
            title="No Results"
            caption="Try adjusting your filters to get some results"
            icon-color="info"
            title-color="info--text"
            caption-color="grey--text darken-2 font-weight-medium"
          ></message-box>
        </template>
        <!-- <template v-slot:loading>
          <message-box
            icon="mdi-reload"
            title="Loading..."
            caption="Sit back while we load your data"
            icon-color="info"
            title-color="info--text"
            caption-color="grey--text darken-2 font-weight-medium"
          ></message-box>
        </template> -->
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'

export default {
  name: 'PurchaseOrderListItems',
  components: { MessageBox },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    itemCount: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    listOptions: { page: 1, itemsPerPage: 15 },
    footerOptions: {
      'items-per-page-options': [10, 15, 25, 50]
    },
    dialog: false,
    edit: false,
    defaultItem: {
      name: '',
      status: 'active'
    },
    editedItem: {
      name: '',
      status: 'active'
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Title',
          align: 'left',
          sortable: false,
          value: 'title'
        },
        {
          text: 'PO Number',
          align: 'left',
          sortable: false,
          value: 'poNumber'
        },
        {
          text: 'Department',
          align: 'left',
          value: 'department.name',
          sortable: false
        },
        {
          text: 'Requested',
          align: 'left',
          value: 'pricing.forecastAmount',
          sortable: false
        },
        {
          text: 'Paid',
          align: 'left',
          value: 'pricing.actualAmount',
          sortable: false
        },
        {
          text: 'Balance',
          align: 'left',
          value: 'pricing.balanceAmount',
          sortable: false
        },
        {
          text: 'Service Date',
          align: 'left',
          value: 'serviceDate',
          sortable: false
        },
        {
          text: 'Payment Date',
          align: 'left',
          value: 'invoiceDate',
          sortable: false
        },
        {
          text: '',
          align: 'left',
          value: 'status',
          sortable: false
        }
      ]
    },
    purchaseOrders() {
      return this.data
    }
  },
  watch: {
    listOptions(e) {
      // if (this.projects.totalCount) {
      //   return this.$emit('fetch-more-projects', e)
      // }
      return this.$emit('set-options', e)
    }
  },
  methods: {
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    },
    navToDetail(item) {
      this.$router.push({
        name: 'PurchaseOrderDetail',
        params: { purchaseOrderID: item.id }
      })
    }
  }
}
</script>

<style lang="scss">
.plain-table .v-input__slot {
  background: transparent !important;
}

.plain-table.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content) {
  cursor: pointer !important;
}
</style>
