<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header pt-0 px-1">
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <project-edit
            :open="dialog"
            :edited-item="editedItem"
            :edit-mode="edit"
            @close-edit-project-dialog="close"
          ></project-edit>
          <v-row no-gutters>
            <v-col cols="4" md="3" lg="2">
              <v-row no-gutters>
                <v-btn color="primary" rounded @click="newItem">
                  add project
                </v-btn>
              </v-row>
            </v-col>

            <v-col
              xs="4"
              sm="3"
              md="3"
              lg="3"
              xl="2"
              class="d-none d-xs-flex d-sm-flex"
            >
              <v-select
                v-model="stateFilterValue"
                :items="stateList"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                placeholder="Status"
              ></v-select>
            </v-col>
            <v-col
              v-if="
                currentUser &&
                  (currentUser.isSuperAdmin ||
                    currentUser.userRole === 'director' ||
                    currentUser.userRole === 'seniormanager' ||
                    currentUser.userRole === 'projectmanager')
              "
              xs="4"
              sm="3"
              md="3"
              lg="3"
              xl="2"
              class="d-none d-xs-flex d-sm-flex"
            >
              <v-select
                v-model="departmentID"
                :items="departments"
                hide-details
                outlined
                flat
                dense
                clearable
                item-value="id"
                item-text="name"
                background-color="#fff"
                placeholder="Department"
                class="ml-3"
              ></v-select>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <!-- <project-list-items
            :data="projects"
            :loading="$apollo.queries.projects.loading"
            :state-filter-value="stateFilter"
            :first="first"
            @fetch-more-projects="fetchMore"
          ></project-list-items> -->
          <v-row justify="center" class="project-list">
            <v-col cols="12" class="py-0">
              <v-card flat>
                <v-card-title class="pt-0">
                  <v-text-field
                    v-model="search"
                    hide-details
                    flat
                    clearable
                    background-color="#fff"
                    placeholder="Search..."
                    prepend-inner-icon="mdi-magnify"
                    autocomplete="off"
                  ></v-text-field>
                </v-card-title>
                <project-list-items
                  :data="projects.items"
                  :loading="$apollo.queries.projects.loading"
                  :item-count="itemCount"
                  @set-options="setOptions"
                ></project-list-items>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectListItems from '@/modules/finance/components/core/ProjectListItems.vue'
import ProjectEdit from '@/modules/finance/components/core/ProjectEdit.vue'
import PROJECTS from '@/graphql/Projects.gql'
import DEPARTMENTS from '@/graphql/Departments.gql'

export default {
  name: 'ProjectList',
  components: {
    ProjectListItems,
    ProjectEdit
  },
  data() {
    return {
      first: 5,
      page: 1,

      search: null,
      projects: { items: [] },

      // Filters
      options: { page: 1, itemsPerPage: 15 },
      itemCount: 0,
      stateFilterValue: null,
      stateList: ['draft', 'approved', 'rejected', 'review', 'closed'],
      yearID: null,
      departmentID: null,

      defaultItem: {
        name: '',
        description: '',
        departmentID: '',
        budgetID: '',
        budgetLineID: '',
        startDate: this.$moment().format('YYYY-MM-DD'),
        endDate: this.$moment()
          .add(1, 'days')
          .format('YYYY-MM-DD')
      },
      editedItem: {
        name: '',
        description: '',
        departmentID: '',
        budgetID: '',
        budgetLineID: '',
        startDate: this.$moment().format('YYYY-MM-DD'),
        endDate: this.$moment()
          .add(1, 'days')
          .format('YYYY-MM-DD')
      },
      edit: false,
      dialog: false
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  apollo: {
    projects: {
      query: PROJECTS,
      variables() {
        return {
          filters: {
            search: this.search,
            yearID: this.yearID,
            departmentID: this.departmentID,
            status: this.stateFilterValue,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      result(result) {
        this.page = result.data.projects.page
        this.itemsPerPage = result.data.projects.pageSize
        this.itemCount = result.data.projects.total
      }
    },
    departments: DEPARTMENTS
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    setOptions(e) {
      this.options = e
    },
    close(success, id) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })

        this.$router.push({
          name: 'ProjectDetail',
          params: { projectID: id }
        })
      }
    },
    newItem() {
      this.edit = false
      this.defaultItem.departmentID = this.currentUser.department.id
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    fetchMore() {
      // this.first = item.itemsPerPage
      var after = null
      if (this.projects && this.projects.pageInfo) {
        after = this.projects.pageInfo.endCursor
      }
      this.$apollo.queries.projects.fetchMore({
        variables: {
          first: this.first,
          after: after
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newProjects = fetchMoreResult.projects.projects
          const newEdges = fetchMoreResult.projects.edges
          const hasNextPage = fetchMoreResult.projects.pageInfo.hasNextPage
          const totalCount = fetchMoreResult.projects.totalCount
          const pageInfo = fetchMoreResult.projects.pageInfo

          // this.showMoreEnabled = hasNextPage

          return {
            projects: {
              __typename: previousResult.projects.__typename,
              // Merging the projects list
              projects: [...previousResult.projects.projects, ...newProjects],
              edges: [...previousResult.projects.edges, ...newEdges],
              // projects: [...newProjects],
              // edges: [...newEdges],
              totalCount: totalCount,
              pageInfo: pageInfo,
              hasNextPage
            }
          }
        }
      })
    },
    stateFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.stateFilterValue) {
        return true
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.stateFilterValue
    }
  }
}
</script>

<style></style>
